import React, { useState } from 'react';
import axios from 'axios';
import './Chatbot.css';
import chatboticon from "../landing/images/img1.jpg";
 
const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [helpOption, setHelpOption] = useState('');
  const [location, setLocation] = useState('');
  const [contactMethod, setContactMethod] = useState('');
 
  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePhoneNumberChange = (e) => setPhoneNumber(e.target.value);
  const handleHelpOptionChange = (e) => setHelpOption(e.target.value);
  const handleLocationChange = (e) => setLocation(e.target.value);
  const handleContactMethodChange = (e) => setContactMethod(e.target.value);
 
  const isNameValid = name.trim().length > 0;
  const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const isPhoneNumberValid = /^[0-9]{10}$/.test(phoneNumber);
  const isHelpOptionSelected = helpOption.trim().length > 0;
  const isLocationSelected = location.trim().length > 0;
  const isContactMethodSelected = contactMethod.trim().length > 0;
 
  const handleNext = () => {
    if (step === 1) setStep(2);
    else if (step === 2 && isNameValid) setStep(3);
    else if (step === 3 && isEmailValid) setStep(4);
    else if (step === 4 && isPhoneNumberValid) setStep(5);
    else if (step === 5 && isHelpOptionSelected) setStep(6);
    else if (step === 6 && isLocationSelected) setStep(7);
  };
 
  const handleSubmit = async () => {
    if (isContactMethodSelected) {
      const chatbotData = {
        Name: name,
        Email_ID: email,
        Phone_Number: phoneNumber,
        Need_Help_With: helpOption,
        Location: location,
        Contact_Preference: contactMethod,
      };
     
      try {
        const response = await axios.post('https://api.clasptax.com/chatbotdata', chatbotData);
        if (response.status === 200) {
          setStep(8); // Show the thank you message
        } else {
          // Handle unexpected responses
          console.error('Unexpected response:', response);
          alert('Something went wrong. Please try again.');
        }
      } catch (error) {
        console.error('Error submitting data:', error);
        alert('There was an error submitting your information. Please try again later.');
      }
    }
  };
 
  const handleClose = () => {
    setIsOpen(false);
    setStep(1);
    setName('');
    setEmail('');
    setPhoneNumber('');
    setHelpOption('');
    setLocation('');
    setContactMethod('');
  };
 
  return (
    <>
      {!isOpen && (
        <button className="chatbot-icon" onClick={() => setIsOpen(true)}>
          <img src={chatboticon} alt="Chatbot Icon" className="whatsapp-icon" />
        </button>
      )}
 
      {isOpen && (
        <div className="chatbot-dialog">
          <div className="chatbot-dialog-header">
            <span>Chatbot</span>
            <button className="chatbot-close-button" onClick={handleClose}>X</button>
          </div>
          <div className="chatbot-dialog-body">
            {step === 1 && (
              <div>
                <p>Hi there. I'm here to get answers to your questions. Clasp Tax Inc. is here to help. Schedule a free strategy call now and discover how we can make a difference.</p>
                <button className="chatbot-nav-button" onClick={handleNext}>Yes, Let's Start</button>
              </div>
            )}
            {step === 2 && (
              <div>
                <p>Clasp Tax Inc. is located in New York, and is an expert in areas of US Taxation, Accounting, Bookkeeping, Tax Consulting, Payroll, and Other Business Services. So you are in safe hands with us! First, what’s your name?</p>
                <input type="text" value={name} onChange={handleNameChange} placeholder="Enter your name" />
                <button className="chatbot-nav-button" onClick={handleNext} disabled={!isNameValid}>Next</button>
              </div>
            )}
            {step === 3 && (
              <div>
                <p>Can I get your email?</p>
                <input type="email" value={email} onChange={handleEmailChange} placeholder="Enter your email" />
                <button className="chatbot-nav-button" onClick={handleNext} disabled={!isEmailValid}>Next</button>
              </div>
            )}
            {step === 4 && (
              <div>
                <p>Can I get your phone number?</p>
                <input type="tel" value={phoneNumber} onChange={handlePhoneNumberChange} placeholder="Enter your phone number" />
                <button className="chatbot-nav-button" onClick={handleNext} disabled={!isPhoneNumberValid}>Next</button>
              </div>
            )}
            {step === 5 && (
              <div>
                <p>Great, thanks! What do you need help with?</p>
                <select value={helpOption} onChange={handleHelpOptionChange}>
                  <option value="">Select an option</option>
                  <option value="Set up an Appointment">Set up an Appointment</option>
                  <option value="Business Consulting">Business Consulting</option>
                  <option value="Tax Preparation & Planning">Tax Preparation & Planning</option>
                  <option value="Accounting & Bookkeeping">Accounting & Bookkeeping</option>
                  <option value="Payroll Services">Payroll Services</option>
                </select>
                <button className="chatbot-nav-button" onClick={handleNext} disabled={!isHelpOptionSelected}>Next</button>
              </div>
            )}
            {step === 6 && (
              <div>
                <p>Sure, we can help you with that. Where are you from?</p>
                <input type="text" value={location} onChange={handleLocationChange} placeholder="Enter your location" />
                <button className="chatbot-nav-button" onClick={handleNext} disabled={!isLocationSelected}>Next</button>
              </div>
            )}
            {step === 7 && (
              <div>
                <p>We can't wait to get in touch. How would you like to be contacted?</p>
                <select value={contactMethod} onChange={handleContactMethodChange}>
                  <option value="">Select a method</option>
                  <option value="Email">Email</option>
                  <option value="Phone">Phone</option>
                </select>
                <button className="chatbot-contact-details-button" onClick={handleSubmit} disabled={!isContactMethodSelected}>Submit</button>
              </div>
            )}
            {step === 8 && (
              <div>
                <p>Thank you for getting in touch! One of our experts will get in touch with you shortly.</p>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
 
export default Chatbot;