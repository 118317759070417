import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import{jwtDecode} from "jwt-decode";
import axios from "axios";
import Axios from "axios";
import './referandearn.css';
import  Clientsidebar  from '../components/Clientsidebar';

 const Referandearn = () => {
  const [username, setUsername] = useState('');
  const [referralName, setReferralName] = useState('');
  const [referralEmail, setReferralEmail] = useState('');
  const [referralPhone, setReferralPhone] = useState('');
  const [referrals, setReferrals] = useState([]);
  const [referMessage, setReferMessage] = useState('');
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  // useEffect(() => {
  //   const token = Cookies.get("token");
  // console.log(token);
  //   if (!token) {
  //     // No token, redirect to home
  //     // navigate("/");

  //   } else {
  //     try {
  //       const decoded = jwtDecode(token);
  //       setUsername(decoded.username);
  //       setRole(decoded.role);
   
  //       // If the decoded role is not Client, redirect
  //       if (decoded.role !== "client") {
  //         // navigate("/");

  //       }
  //     } catch (error) {
  //       console.error("Invalid token");
  //       // navigate("/");
  //     }
  //   }
  // }, [navigate]);

  // useEffect(() => {
  //   const token = Cookies.get("token");
  //   if (token) {
  //   const fetchUserData = async () => {
  //     try {
  //       const token = Cookies.get("token");
  //       const response = await axios.get("https://api.clasptax.com/client", {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       });
  //       console.log(response.data);
  //     } catch (error) {
  //       console.error("Error:", error);
  //       if (error.response && error.response.status === 401) {
  //         // navigate("/");
  //       }
  //     }
  //   };

  //   fetchUserData();
  // }
  // }, [navigate]);

  useEffect(() => {
    const fetchReferrals = async () => {
      try {
        const response = await Axios.post('https://api.clasptax.com/fetchReferrals', {
          username: username,
        });

        if (response.data.success) {
          setReferrals(response.data.referrals);
        } else {
          console.error('Error fetching referrals:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching referrals:', error);
      }
    };

    fetchReferrals();
  }, [username]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      referralName.trim() === '' ||
      referralEmail.trim() === '' ||
      referralPhone.trim() === '' ||
      referralPhone.length < 10
    ) {
      alert('Please fill in all fields correctly.');
      return;
    }

    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(referralEmail)) {
      alert('Please enter a valid email address.');
      return;
    }

    const newReferral = {
      name: referralName,
      email: referralEmail,
      phone: referralPhone,
    };

    try {
      const response = await Axios.post('https://api.clasptax.com/submitReferral', {
        username: username,
        referralName: newReferral.name,
        referralEmail: newReferral.email,
        referralPhone: newReferral.phone,
      });

      if (response.data.success) {
        setReferrals([...referrals, newReferral]);
        setReferMessage(`You referred ${referralName}`);
      } else {
        console.error('Error submitting referral:', response.data.message);
      }
    } catch (error) {
      console.error('Error submitting referral:', error);
    }

    setReferralName('');
    setReferralEmail('');
    setReferralPhone('');
  };

  return (
    <div>
      <Clientsidebar />
      <div className='dashboardalignn'>
        <div>
          <div className='ml-5'>
            <h1 className='h1'>Referral Form</h1>

            <form className='formmmm' onSubmit={handleSubmit}>
              <label className='labellll' htmlFor='referralName'>
                Referral Name:
              </label>
              <input
                type='text'
                id='referralName'
                name='referralName'
                value={referralName}
                onChange={(e) => setReferralName(e.target.value)}
                required
              />

              <label className='labellll' htmlFor='referralEmail'>
                Referral Email:
              </label>
              <input
                type='email'
                id='referralEmail'
                name='referralEmail'
                value={referralEmail}
                onChange={(e) => setReferralEmail(e.target.value)}
                required
              />

              <label className='labellll' htmlFor='referralPhone'>
                Referral Phone Number:
              </label>
              <input
                type='tel'
                id='referralPhone'
                name='referralPhone'
                value={referralPhone}
                onChange={(e) => setReferralPhone(e.target.value)}
                required
              />

              <button className='buttonnnn' type='submit'>
                Submit Referral
              </button>
            </form>
            {referMessage && <h5 style={{ color: 'green' }}>{referMessage}..</h5>}
          </div>

          <div className='ml-5 mr-5'>
            <h2 className='h2'>Your Referrals</h2>
            <table className='tableeee'>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                </tr>
              </thead>
              <tbody>
                {referrals.map((referral, index) => (
                  <tr key={index}>
                    <td>{referral.name}</td>
                    <td>{referral.email}</td>
                    <td>{referral.phone}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Referandearn