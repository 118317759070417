// import React, { useEffect,useContext,useState, useRef  } from 'react';
// import Axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import  Clientsidebar  from '../components/Clientsidebar';
// import './documents.css';
// import { saveAs } from 'file-saver';

// import Cookies from "js-cookie";
// import{jwtDecode} from "jwt-decode";
// import axios from "axios";


// export const Documents = () => {
//     const navigate = useNavigate();
   
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [selectedDocumentType, setSelectedDocumentType] = useState('');
//     const [selectedComment, setSelectedComment] = useState('');
//     const [username, setUsername] = useState('');
//     const [clientDocuments, setClientDocuments] = useState([]);
//     const fileInputRef = useRef(null);
//     const [role, setRole] = useState("");
  
//     // useEffect(() => {
//     //   Axios.get('https://api.clasptax.com/home', { withCredentials: true })
//     //     .then((response) => {
//     //       if (!response.data.loggedIn) {
//     //         navigate('/login');
//     //       } 
//     //     })
//     //     .catch((error) => {
//     //       console.error('Error checking authentication:', error);
//     //       navigate('/login');
//     //     });
//     // }, [navigate]);
//     // useEffect(() => {
//     //     const token = Cookies.get("token");
//     //   console.log(token);
//     //     if (!token) {
//     //       // No token, redirect to home
//     //       // navigate("/");
    
//     //     } else {
//     //       try {
//     //         const decoded = jwtDecode(token);
//     //         setUsername(decoded.username);
//     //         setRole(decoded.role);
       
//     //         // If the decoded role is not Client, redirect
//     //         if (decoded.role !== "client") {
//     //           // navigate("/");
    
//     //         }
//     //       } catch (error) {
//     //         console.error("Invalid token");
//     //         // navigate("/");
//     //       }
//     //     }
//     //   }, [navigate]);
    
//       // useEffect(() => {
//       //   const token = Cookies.get("token");
//       //   if (token) {
//       //   const fetchUserData = async () => {
//       //     try {
//       //       const token = Cookies.get("token");
//       //       const response = await axios.get("https://api.clasptax.com/client", {
//       //         headers: {
//       //           Authorization: `Bearer ${token}`,
//       //         },
//       //       });
//       //       console.log(response.data);
//       //     } catch (error) {
//       //       console.error("Error:", error);
//       //       if (error.response && error.response.status === 401) {
//       //         // navigate("/");
//       //       }
//       //     }
//       //   };
    
//       //   fetchUserData();
//       // }
//       // }, [navigate]);
    
//     useEffect(() => {
//       // Fetch client documents when the component mounts
//       Axios.get('https://api.clasptax.com/clientDocuments', {
//         params: { username }, // Pass the username as a query parameter
//         withCredentials: true,
//       })
//         .then((response) => {
//           if (response.data.success) {
//             setClientDocuments(response.data.clientDocuments);
//           } else {
//             console.error('Error fetching client documents:', response.data.message);
//           }
//         })
//         .catch((error) => {
//           console.error('Error fetching client documents:', error);
//         });
//     }, [username]);



//     const handleFileChange = (e) => {
//       setSelectedFile(e.target.files[0]);
//     };

//     const handleDocumentTypeChange = (e) => {
//       setSelectedDocumentType(e.target.value);
//     };

//     const handleCommentChange = (e) => {
//       setSelectedComment(e.target.value);
//     };


//     const handleSubmit = async (e) => {
//       e.preventDefault();
    
//       // Check if required fields are filled
//       if (!selectedDocumentType || !selectedFile ) {
//         alert('Please fill all required fields');
//         return;
//       }
    
//       try {
//         const formData = new FormData();
//         formData.append('username', username);
//         formData.append('document_type', selectedDocumentType);
//         formData.append('file', selectedFile);
//         formData.append('add_comment', selectedComment);
    
//         const response = await Axios.post('https://api.clasptax.com/submitRefund', formData, {
//           withCredentials: true,
//           headers: {
//             'Content-Type': 'multipart/form-data',
//           },
//         });
    
//         if (response.data.success) {
//           alert('Document submitted successfully');
//           window.location.reload(); 

//           // Fetch updated client documents after successful submission
//           Axios.get('https://api.clasptax.com/clientDocuments', {
//             params: { username },
//             withCredentials: true,
//           })
//             .then((response) => {
//               if (response.data.success) {
//                 setClientDocuments(response.data.clientDocuments);
//               } else {
//                 console.error('Error fetching client documents:', response.data.message);
//               }
//             })
//             .catch((error) => {
//               console.error('Error fetching client documents:', error);
//             });
    
//           // Clear form inputs
//           setSelectedFile(null);
//           setSelectedDocumentType('');
//           setSelectedComment('');
//         } else {
//           alert('Error submitting document');
//         }
//       } catch (error) {
//         console.error('Error during document submission:', error);
//       }
//     };
    
    
//     const handleCloseModal = () => {
//       // Clear input values when closing the modal
//       setSelectedFile(null);
//       setSelectedDocumentType('');
//       setSelectedComment('');
//     };
  



//   const handleDownload = () => {
//     // Download the specified PDF file based on the selected year
   
//   };

//   function formatDateTime(dateTime) {
//     const options = {
//       year: 'numeric',
//       month: 'long',
//       day: 'numeric',
//       hour: '2-digit',
//       minute: '2-digit',
//       second: '2-digit',
//       hour12: false,
//     };
  
//     return new Date(dateTime).toLocaleString('en-US', options);
//   }


//   const handleDownloaddoc = (filePath) => {
//     // Replace backslashes with forward slashes in the filePath
//     const formattedFilePath = filePath.replace(/\\/g, '/');
  
//     // Construct the download URL based on the formatted filePath
//     const downloadUrl = `https://api.clasptax.com/${formattedFilePath}`;
  
//     // Create a temporary link
//     const link = document.createElement('a');
//     link.href = downloadUrl;
//     link.target = '_blank';
    
//     link.download = formattedFilePath.split('/').pop(); // Set the file name
  
//     // Append the link to the body
//     document.body.appendChild(link);
  
//     // Programmatically click on the link to trigger the download
//     link.click();
  
//     // Remove the link from the body
//     document.body.removeChild(link);
//   };
  
  
  
 
  
  

//   return (
    

//     <div >
//       <Clientsidebar/>
//     <div className='dashboardalignn '>
//       <div className='m-5'>
 
//       <div className='row justify-content-between'>
//         <div>
//             <h1>Documents</h1>
//         </div>
 
//         <div>
//           <div>
//             <button class="cssbuttons-io-button uploadbtn" type="button" data-toggle="modal" data-target="#exampleModal">
//             <i class="material-symbols-outlined" style={{color:"black"}}>upload</i>
//               <p className='buttonnnnnn pt-3'>Upload Document</p>
//             </button>
//         </div>
//         <div>
       
//         <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" >
//           <div class="modal-dialog">
//             <div class="modal-content">
//               <div class="modal-header">
//                 <h5 class="modal-title" id="exampleModalLabel">Document</h5>
//                 <button type="button" class="close" data-dismiss="modal" aria-label="Close">
//                   <span aria-hidden="true">&times;</span>
//                 </button>
//               </div>
//               <div class="modal-body">
//                 <div class="form-group">
//                     <label for="dropdownInput">Document Type:</label>
//                     <select class="form-control" id="dropdownInput" name="dropdownInput" onChange={handleDocumentTypeChange} value={selectedDocumentType}>
//                       <option value="" disabled selected>Select Document Type</option>
//                       <optgroup label="Income" className='red-label'>
//                         <option value="W2_Form">W2 Form</option>
//                         <option value="Int_Interest_1099">Interest 1099</option>
//                         <option value="R_Retirement_Distributions_1099">Retirement Distributions 1099</option>
//                         <option value="Div_Dividend_1099">Dividend 1099</option>
//                         <option value="G_Government_Payments_1099">Government Payments 1099</option>
//                         <option value="B_Brokerage_Statement_1099">Brokerage Statement 1099</option>
//                         <option value="MISC_Miscellaneous_Income_1099">Miscellaneous Income 1099</option>
//                       </optgroup>
//                       <optgroup label="Expenses" class="red-label">
//                         <option value="T_Tution_Statement_1098">Tuition Statement 1098</option>
//                         <option value="Mortgage_Interest_Statement_1098">Mortgage Interest Statement 1098</option>
//                         <option value="E_Student_Loan_Interest_1098">Student Loan Interest 1098</option>
//                         <option value="Foreign_Home_Loan_Interest_Certificate_From_Bank">Foreign Home Loan Interest Certificate From Bank</option>
//                       </optgroup>
//                       <optgroup label="Others" class="red-label">
//                         <option value="Tax_Organizer">Tax Organizer</option>
//                         <option value="Other_Document">Other Document</option>
//                       </optgroup>
//                     </select>
//                 </div>
//                 <div class="form-group">
//                     <label for="fileInput">Upload File:</label>
//                     <input
//                       ref={fileInputRef}
//                       type="file"
//                       class="form-control-file"
//                       id="fileInput"
//                       name="fileInput"
//                       onChange={handleFileChange}
//                     />      
//                      </div>
//                 <div class="form-group">
//                     <label for="commentInput">Add Comment:</label>
//                     <textarea class="form-control" id="commentInput" name="commentInput" rows="3" onChange={handleCommentChange}  value={selectedComment}></textarea>
//                 </div>
//             </div>
//               <div class="modal-footer">
//                 <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={handleCloseModal}>Close</button>
//                 <button
//                     type="button"
//                     class="btn btn-primary"
//                     data-dismiss="modal"
//                     onClick={(e) => {
//                       handleSubmit(e);
//                     }}
//                   >
//                     Save changes
//                   </button>
 
//               </div>
//             </div>
//           </div>
//         </div>
//         </div>
//         </div>
//       </div>
       
//                 <div>
//                     <ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
//                     <li class="nav-item" role="presentation">
//                         <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Client Uploaded Documents</a>
//                     </li>
//                     <li class="nav-item" role="presentation">
//                         <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Tax Experts Uploaded Documents</a>
//                     </li>
//                     <li class="nav-item" role="presentation">
//                         <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Download</a>
//                     </li>
//                     </ul>
//                     <div class="tab-content" id="myTabContent">
//                     <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
//                     <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                   
//                         <table className="tablee">
//                             <thead>
//                             <tr>
//                                 <th>Document Type</th>
//                                 <th>Uploaded On</th>
//                                 <th>Comments</th>
//                                 <th>Download</th>
//                             </tr>
//                             </thead>
//                             <tbody>
//                           {clientDocuments.map((document, index) => (
//                             <tr key={index}>
//                               <td>{document.type}</td>
//                               <td>{document.uploadedOn ? formatDateTime(document.uploadedOn) : 'N/A'}</td>
//                               <td>{document.comments}</td>
//                               <td>
//                                 <button
//                                   className="btn btn-primary"
//                                   title="Download PDF"
                                 
//                                   onClick={() => {
//                                               console.log('Clicked on download for document:', document);
//                                               handleDownloaddoc(document.filePath.trim());
//                                             }}    
//                                  >
//                                   Download
//                                 </button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//                         </table>
 
                     
                       
//                         </div></div>
//                     <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
//                       <h4 className='mt-3 ml-3'>No Records Found !</h4>
//                     </div>
//                     <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
//                     <div class="col-12 max-w-xl">
//                 <ul class="list-group">
//                     <li class="d-flex align-items-center justify-content-between list-group-item">
//                         <p class="my-0 font-weight-normal">
//                             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-paperclip mr-1 light-gray"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
//                             Clasp Tax - Tax Notes - TY 
//                         </p>
 
//                         <button
//                             className="btn btn-primary"
//                             onClick={handleDownload}
//                             title="Download PDF"
//                           >
//                             Download
//                         </button>
 
//                     </li>
//                 </ul>
//             </div>
//                     </div>
//                 </div>
//         </div>
//     </div>
//     </div>
//     </div>
   
//   )
// }
 


import React, { useEffect,useContext,useState, useRef  } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import  Clientsidebar  from '../components/Clientsidebar';
import './documents.css';
import { saveAs } from 'file-saver';

import Cookies from "js-cookie";
import{jwtDecode} from "jwt-decode";



export const Documents = () => {
    const navigate = useNavigate();
   
    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [selectedComment, setSelectedComment] = useState('');
    const [username, setUsername] = useState('');
    const [clientDocuments, setClientDocuments] = useState([]);
    const fileInputRef = useRef(null);
    const [role, setRole] = useState("");
    
  
    // useEffect(() => {
    //   Axios.get('https://api.clasptax.com/home', { withCredentials: true })
    //     .then((response) => {
    //       if (!response.data.loggedIn) {
    //         navigate('/login');
    //       } 
    //     })
    //     .catch((error) => {
    //       console.error('Error checking authentication:', error);
    //       navigate('/login');
    //     });
    // }, [navigate]);

    const token = Cookies.get("token");
    useEffect(() => {
        
 
        if (!token) {
          // No token, redirect to home
          // navigate("/");
    
        } else {
          try {
            const decoded = jwtDecode(token);
            setUsername(decoded.email);
            setRole(decoded.role);
            
            // If the decoded role is not Client, redirect
            if (decoded.role !== "client") {
              // navigate("/");
    
            }
          } catch (error) {
            console.error("Invalid token");
            // navigate("/");
          }
        }
      }, []);
    
      // useEffect(() => {
      //   const token = Cookies.get("token");
      //   if (token) {
      //   const fetchUserData = async () => {
      //     try {
      //       const token = Cookies.get("token");
      //       const response = await axios.get("https://api.clasptax.com/client", {
      //         headers: {
      //           Authorization: `Bearer ${token}`,
      //         },
      //       });
      //       console.log(response.data);
      //     } catch (error) {
      //       console.error("Error:", error);
      //       if (error.response && error.response.status === 401) {
      //         // navigate("/");
      //       }
      //     }
      //   };
    
      //   fetchUserData();
      // }
      // }, [navigate]);
    
    useEffect(() => {
      // Fetch client documents when the component mounts
      fetchclientdocuments();
    }, [username]);
    

    const fetchclientdocuments = async () =>{
     await Axios.get(`https://api.clasptax.com/clientDocuments/${username}`)
        
    
        .then((response) => {
          if (response.data.success) {
            setClientDocuments(response.data.clientDocuments);
            console.log(response.data.clientDocuments)
          } else {
            console.error('Error fetching client documents:', response.data.message);
          }
        })
        .catch((error) => {
          console.error('Error fetching client documents:', error);
        });
    }


    const handleFileChange = (e) => {
      setSelectedFile(e.target.files[0]);
    };

    const handleDocumentTypeChange = (e) => {
      setSelectedDocumentType(e.target.value);
    };

    const handleCommentChange = (e) => {
      setSelectedComment(e.target.value);
    };


    const handleSubmit = async (e) => {
      e.preventDefault();
    
      // Check if required fields are filled
      if (!selectedDocumentType || !selectedFile ) {
        alert('Please fill all required fields');
        return;
      }
    
      try {
        const formData = new FormData();
        formData.append('username', username);
        formData.append('document_type', selectedDocumentType);
        formData.append('file', selectedFile);
        formData.append('add_comment', selectedComment);
    
        const response = await Axios.post('https://api.clasptax.com/submitRefund', formData, {
          withCredentials: true,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
        if (response.data.success) {
          alert('Document submitted successfully');
          window.location.reload(); 

          // Fetch updated client documents after successful submission
          // Axios.get('https://api.clasptax.com/clientDocuments', {
          //   params: { username },
          //   withCredentials: true,
          // })
          //   .then((response) => {
          //     if (response.data.success) {
          //       setClientDocuments(response.data.clientDocuments);
          //     } else {
          //       console.error('Error fetching client documents:', response.data.message);
          //     }
          //   })
          //   .catch((error) => {
          //     console.error('Error fetching client documents:', error);
          //   });
    
          // Clear form inputs
          setSelectedFile(null);
          setSelectedDocumentType('');
          setSelectedComment('');
        } else {
          alert('Error submitting document');
        }
      } catch (error) {
        console.error('Error during document submission:', error);
      }
    };
    
    
    const handleCloseModal = () => {
      // Clear input values when closing the modal
      setSelectedFile(null);
      setSelectedDocumentType('');
      setSelectedComment('');
    };
  



  // const handleDownload = () => {
  //   // Download the specified PDF file based on the selected year
  //   const taxFile = require(`../pdf/ClaspTax Tax Organizer -${selectedyear}.docx`);
  //   saveAs(taxFile, `ClaspTax_Tax_Organizer_${selectedyear}.docx`);
  // };

  function formatDateTime(dateTime) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
    };
  
    return new Date(dateTime).toLocaleString('en-US', options);
  }


  const handleDownloaddoc = (filePath) => {
    // Replace backslashes with forward slashes in the filePath
    const formattedFilePath = filePath.replace(/\\/g, '/');
  
    // Construct the download URL based on the formatted filePath
    const downloadUrl = `https://api.clasptax.com/${formattedFilePath}`;
  
    // Create a temporary link
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.target = '_blank';
    
    link.download = formattedFilePath.split('/').pop(); // Set the file name
  
    // Append the link to the body
    document.body.appendChild(link);
  
    // Programmatically click on the link to trigger the download
    link.click();
  
    // Remove the link from the body
    document.body.removeChild(link);
  };
  
  
  
  const handleOpenModal = () => setIsModalOpen(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

 

  return (
    

    <div >
      <Clientsidebar/>
    <div className='dashboardalignn '>
      <div className='m-5'>
 
      <div className='row justify-content-between'>
        <div>
            <h1>Documents</h1>
        </div>
        
        <div>
      <div>
        <button className="cssbuttons-io-button uploadbtn" type="button" onClick={handleOpenModal}>
          <i className="material-symbols-outlined" style={{ color: 'black' }}>upload</i>
          Upload Document
        </button>
      </div>

      {isModalOpen && (
        <div className="modal fade show" style={{ display: 'block', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Document</h5>
                <button type="button" className="close" onClick={handleCloseModal} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <label htmlFor="dropdownInput">Document Type:</label>
                  <select className="form-control" id="dropdownInput" name="dropdownInput" onChange={handleDocumentTypeChange} value={selectedDocumentType}>
                    <option value="" disabled>Select Document Type</option>
                    <optgroup label="Income" className='red-label'>
                      <option value="W2_Form">W2 Form</option>
                      <option value="Int_Interest_1099">Interest 1099</option>
                      <option value="R_Retirement_Distributions_1099">Retirement Distributions 1099</option>
                      <option value="Div_Dividend_1099">Dividend 1099</option>
                      <option value="G_Government_Payments_1099">Government Payments 1099</option>
                      <option value="B_Brokerage_Statement_1099">Brokerage Statement 1099</option>
                      <option value="MISC_Miscellaneous_Income_1099">Miscellaneous Income 1099</option>
                    </optgroup>
                    <optgroup label="Expenses" className="red-label">
                      <option value="T_Tution_Statement_1098">Tuition Statement 1098</option>
                      <option value="Mortgage_Interest_Statement_1098">Mortgage Interest Statement 1098</option>
                      <option value="E_Student_Loan_Interest_1098">Student Loan Interest 1098</option>
                      <option value="Foreign_Home_Loan_Interest_Certificate_From_Bank">Foreign Home Loan Interest Certificate From Bank</option>
                    </optgroup>
                    <optgroup label="Others" className="red-label">
                      <option value="Tax_Organizer">Tax Organizer</option>
                      <option value="Other_Document">Other Document</option>
                    </optgroup>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="fileInput">Upload File:</label>
                  <input
                    ref={fileInputRef}
                    type="file"
                    className="form-control-file"
                    id="fileInput"
                    name="fileInput"
                    onChange={handleFileChange}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="commentInput">Add Comment:</label>
                  <textarea className="form-control" id="commentInput" name="commentInput" rows="3" onChange={handleCommentChange} value={selectedComment}></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={handleCloseModal}>Close</button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={(e) => handleSubmit(e)}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </div>
       
                <div>
                    <ul class="nav nav-tabs mt-3" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Client Uploaded Documents</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Tax Experts Uploaded Documents</a>
                    </li>
                    <li class="nav-item" role="presentation">
                        <a class="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Download</a>
                    </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                   
                        <table className="tablee">
                            <thead>
                            <tr>
                                <th>Document Type</th>
                                <th>Uploaded On</th>
                                <th>Comments</th>
                                <th>Download</th>
                            </tr>
                            </thead>
                            <tbody>
                          {clientDocuments.map((document, index) => (
                            <tr key={index}>
                              <td>{document.type}</td>
                              <td>{document.uploadedOn ? formatDateTime(document.uploadedOn) : 'N/A'}</td>
                              <td>{document.comments}</td>
                              <td>
                                <button
                                  className="btn btn-primary"
                                  title="Download PDF"
                                 
                                  onClick={() => {
                                              console.log('Clicked on download for document:', document);
                                              handleDownloaddoc(document.filePath.trim());
                                            }}    
                                 >
                                  Download
                                </button>
                  </td>
                </tr>
              ))}
            </tbody>
                        </table>
 
                     
                       
                        </div></div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                      <h4 className='mt-3 ml-3'>No Records Found !</h4>
                    </div>
                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                    <div class="col-12 max-w-xl">
                <ul class="list-group">
                    <li class="d-flex align-items-center justify-content-between list-group-item">
                        {/* <p class="my-0 font-weight-normal">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-paperclip mr-1 light-gray"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
                            Clasp Tax - Tax Notes - TY {selectedyear}
                        </p> */}
 
                        {/* <button
                            className="btn btn-primary"
                            onClick={handleDownload}
                            title="Download PDF"
                          >
                            Download
                        </button> */}
 
                    </li>
                </ul>
            </div>
                    </div>
                </div>
        </div>
    </div>
    </div>
    </div>
   
  )
}
 
