import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './contactusform.css';
import Nav from "../components/Nav";
import { Footer } from '../components/footer';
import Chatbot from '../../components/Chatbot';
import { Upperbox } from '../components/Upperbox';
import WhatsAppChatButton from '../../components/Whatsapp';

export const Contactusform = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    number: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.number || !formData.email) {
      alert('Please fill in all required fields.');
      return;
    }

    axios.post('https://api.clasptax.com/contactusformdata', formData)
      .then((response) => {
        alert(response.data.message);
        navigate('/Signup');
      })
      .catch((error) => {
        console.error('Error submitting contact form:', error);
        alert('Error submitting contact form. Please try again.');
      });
  };

  return (
    <div className="contact-form-container">
      <Upperbox />
      <Nav />
      <div className='backgroundImageee'>
        <p className="sefile-text">Contact Us</p>
        <div className="line-animation"></div>
      </div>
      <div className="contact-page">
        <h1>Get in Touch With Us</h1>
        <div className="contact-containerrr">
          {/* Right Section: Enquiry Form */}
          <div className="contact-right">
            <h2>Enquiry Now</h2>
            <form className="enquiry-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="name">Name <span className="required">*</span></label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter Your Name"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="number">Mobile Number <span className="required">*</span></label>
                <input
                  type="text"
                  id="number"
                  name="number"
                  value={formData.number}
                  onChange={handleChange}
                  placeholder="Enter Mobile Number"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email Address <span className="required">*</span></label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your Email Address"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Enter Subject"
                />
              </div>
              <div className="form-group">
                <label htmlFor="message">Message to Us</label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message to Us"
                ></textarea>
              </div>
              <button type="submit" className="submit-btn">Send Enquiry</button>
            </form>
          </div>
          {/* Left Section: Reach Us and Business Hours */}
          <div className="contact-left">
            <h2>Reach Us</h2>
            <div className="contact-info">
              <p><i className="fas fa-phone-alt"></i> +91 331 481 6003</p>
              <p><i className="fas fa-envelope"></i> info@clasptax.com</p>
              <p><i className="fas fa-map-marker-alt"></i> 81 Thorn Lane, Newark, DE 19711</p>
            </div>
            <h3>Business Hours</h3>
            <p className="appointment-required">(Appointment Required)*</p>
            <div className="business-hours">
              <p><i className="fas fa-calendar-alt"></i> Monday to Friday<br />8:00am – 8:00pm</p>
              <p><i className="fas fa-calendar-alt"></i> Saturday & Sunday<br />11:00am – 4:00pm</p>
            </div>
            <div className="google-map">
              <iframe
                src="https://maps.google.com/maps?q=81%20Thorn%20lane,%20Newark%20DE&t=&z=13&ie=UTF8&iwloc=&output=embed"
                width="100%"
                height="250"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                title="Google Map"
              ></iframe>
            </div>
            <div className="social-media-section">
              <div className="social-media">
                <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Chatbot/>

    <WhatsAppChatButton/>
      <Footer />
    </div>
  );
};
