import React from 'react';
import './refundstatus.css';
import Nav from "../components/Nav";
import { Footer } from '../components/footer';
import { Link } from 'react-router-dom';

import Chatbot from '../../components/Chatbot';
import { Upperbox} from '../components/Upperbox';
import WhatsAppChatButton from '../../components/Whatsapp';
import Cosection from '../components/Cosections';
import Scrolltop from '../../components/Scrolltop';
export const Refundstatus = () => {
  
  const refundStatuses = [
    { id: 1, title: 'Refund Processed', description: 'Your refund has been successfully processed.' },
    { id: 2, title: 'Refund Pending', description: 'Your refund request is being reviewed.' },
    { id: 3, title: 'Refund Denied', description: 'Your refund request has been denied.' },
    { id: 3, title: 'Refund Denied', description: 'Your refund request has been denied.' },
  ];

  return (
    <div>
    <Upperbox/>
    <Nav/>
    <div className='img-iii'>
        <p className="sefile-textt">Refund Status</p>
        <div className="line-animation"></div>
      </div>

    {/* <div className="refund-status-container">
   
      {refundStatuses.map((status) => (
        <div key={status.id} className="refund-card">
          <h3>{status.title}</h3>
          <p>{status.description}</p>
        </div>
      ))}
     
    </div> */}
    <div className="refund-table-wrapper">
        <table className="refund-table">
          <thead>
            <tr>
              <th>Refunds</th>
              <th>Links</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Federal Refund Status</td>
              <td>
                <Link to="https://sa.www4.irs.gov/irfof/lang/en/irfofgetstatus.jsp" target='_blank'>
                  Check Here
                </Link>
              </td>
            </tr>
            <tr>
              <td>State Refund Status</td>
              <td>
                <Link to="/staterefundstatus">
                  Check Here
                </Link>
              </td>
            </tr>
            <tr>
              <td>Pay Federal Taxes Online</td>
              <td>
                <Link to="https://www.irs.gov/payments" target='_blank'>
                  Check Here
                </Link>
              </td>
            </tr>
            <tr>
              <td>Pay State Taxes Online</td>
              <td>
                <Link to="https://www.officialpayments.com/index.jsp" target='_blank'>
                  Check Here
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
       <Cosection/>
       <Chatbot/>
    <Scrolltop/>
    <WhatsAppChatButton/>
    <Footer/>
    </div>
  );
};


