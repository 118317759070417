import React, { useState } from 'react';
import './Planningservices.css'; // Add CSS file for styling

const Planningservices = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const accordionData = [
    { title: 'What documents do I need for tax filing?', content: 'You will need income statements (such as W-2, 1099), tax deductions information, and other supporting documents. Clasp Tax Inc. provides a detailed checklist to help you get organized.' },
    { title: 'Can Clasp Tax Inc. help me reduce my tax liability?', content: 'Yes, our expert team ensures that you maximize all available deductions and credits, reducing your tax liability as much as possible.' },
    { title: 'How does Clasp Tax Inc. handle business taxes?', content: 'Clasp Tax Inc. specializes in business tax services, providing tailored advice for small and medium-sized enterprises. We help ensure compliance while optimizing your tax strategy.' },
    { title: 'What happens if I miss the tax filing deadline?', content: 'If you miss the deadline, you may incur penalties or interest. However, Clasp Tax Inc. can assist in filing for extensions or helping minimize penalties in the case of late filing.' },
    { title: 'How secure is my personal information with Clasp Tax Inc.?', content: 'We prioritize the security of your data with industry-leading encryption and secure handling practices to ensure your information remains safe and confidential.' },
  ];

  return (
    <div className="faq-container">
      <h2 className='fre-qq'>Frequently Asked Questions</h2>
      <div className="faq-accordion">
        {accordionData.map((item, index) => (
          <div className="faq-item" key={index}>
            <button
              onClick={() => toggleAccordion(index)}
              aria-expanded={activeIndex === index}
            >
              <span className="faq-title">{item.title}</span>
              <span className="faq-icon" aria-hidden="true"></span>
            </button>
            <div className={`faq-content ${activeIndex === index ? 'open' : ''}`}>
              <p>{item.content}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Planningservices;
