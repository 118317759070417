// import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Adminsidebar from '../components/Adminsidebar';
// import './leads.css';
// import 'jspdf-autotable';
// import * as XLSX from 'xlsx';
// import { FaEdit, FaTrashAlt, FaDownload, FaEye, FaUpload } from 'react-icons/fa';
// import axios from 'axios';


// const Leads = () => {
//     const navigate = useNavigate();

//     const [documents, setDocuments] = useState([]);
//     const [filters, setFilters] = useState({
//         dateFrom: '',
//         dateTo: '',
//         clientName: '',
//         status: ''
//     });
//     const [itemsPerPage, setItemsPerPage] = useState(20);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [selectedRows, setSelectedRows] = useState([]);
//     const [file, setFile] = useState(null);
//     const [employeeData, setEmployeeData] = useState([]);
//     const [dropdownVisible, setDropdownVisible] = useState(false);
//     const [selectedClients, setSelectedClients] = useState([]);
// const [selectedEmployees, setSelectedEmployees] = useState([]);
// const [clientEmployeeMap, setClientEmployeeMap] = useState({});
//     useEffect(() => {
//         fetchDocuments(); // Fetch documents when the component mounts
//     }, []);

//     const fetchDocuments = async () => {
//         try {
//           const response = await axios.get('https://api.clasptax.com/leads');
//           console.log('Fetched documents:', response.data);
//           setDocuments(response.data);
//         } catch (error) {
//           console.error('Error fetching documents:', error);
//         }
//       };
      

//     const formatDateTime = (dateTime) => new Date(dateTime).toLocaleString();

//     const handleFilterChange = (e) => {
//         setFilters({ ...filters, [e.target.name]: e.target.value });
//     };

//     const filterDocuments = () => {
//         return documents.filter((doc) => {
//             const { dateFrom, dateTo, clientName, status } = filters;
//             const documentDate = new Date(doc.created_at);
//             const fromDate = dateFrom ? new Date(dateFrom) : new Date(0);
//             const toDate = dateTo ? new Date(dateTo) : new Date();
//             return (
//                 (dateFrom === '' || documentDate >= fromDate) &&
//                 (dateTo === '' || documentDate <= toDate) &&
//                 (clientName === '' || doc.clientname.toLowerCase().includes(clientName.toLowerCase())) &&
//                 (status === '' || doc.status === status)
//             );
//         });
//     };

//     const handleSelectAll = () => {
//         const allIds = filterDocuments().map(doc => doc.id);
//         setSelectedRows(selectedRows.length === allIds.length ? [] : allIds);
//     };

//     const handleSelectRoww = (id) => {
//         setSelectedRows((prevSelectedRows) =>
//             prevSelectedRows.includes(id) ? prevSelectedRows.filter(rowId => rowId !== id) : [...prevSelectedRows, id]
//         );
//     };

// // Handles selecting clients
// const handleSelectRow= (clientId) => {
//     if (selectedClients.includes(clientId)) {
//         setSelectedClients(selectedClients.filter(id => id !== clientId));
//     } else {
//         setSelectedClients([...selectedClients, clientId]);
//     }
// };

// // Handles selecting employees from the dropdown
// const handleCheckboxChange = (employeeUsername) => {
//     setSelectedEmployees((prevSelectedEmployees) =>
//         prevSelectedEmployees.includes(employeeUsername)
//             ? prevSelectedEmployees.filter(username => username !== employeeUsername)
//             : [...prevSelectedEmployees, employeeUsername]
//     );
// };

// // Assign selected employees to selected clients
// const handleOkClick = () => {
//     if (selectedClients.length === 0 || selectedEmployees.length === 0) {
//         alert('Please select at least one client and one employee.');
//         return;
//     }

//     // Map clients to employees (for simplicity, assign the first selected employee to all selected clients)
//     const newClientEmployeeMap = { ...clientEmployeeMap };
//     selectedClients.forEach(clientId => {
//         newClientEmployeeMap[clientId] = selectedEmployees[0]; // Assign the first selected employee
//     });

//     setClientEmployeeMap(newClientEmployeeMap);
//     setDropdownVisible(false); // Close the dropdown after assignment
// };

// // Modify the table data to reflect the new assignments
// const getAssignedEmployee = (clientId) => {
//     return clientEmployeeMap[clientId] || 'Unassigned';
// };

// // Handle Assign To button click to show the dropdown
// // Toggle dropdown visibility
// const handleAssignTo = () => {
//     setDropdownVisible((prevVisible) => !prevVisible);
// };

// // Handle checkbox change
// const handleCheckboxChangee= (username) => {
//     setSelectedEmployees((prevSelected) => {
//         if (prevSelected.includes(username)) {
//             return prevSelected.filter(emp => emp !== username);
//         } else {
//             return [...prevSelected, username];
//         }
//     });
// };

//     // const handleAssignTo = () => {
//     //     if (selectedRows.length > 0) {
//     //         const selectedDocs = documents.filter(doc => selectedRows.includes(doc.id));
//     //         console.log('Assigning to:', selectedDocs);
//     //     }
//     // };

//     // Download table as Excel file
// const downloadTableAsExcel = () => {
//     const ws = XLSX.utils.json_to_sheet(
//         filterDocuments().map(doc => ({
//             'Client Name': doc.clientname,
//             'Email': doc.email,
//             'Contact': doc.contact,
//             'Status': doc.status,
//             'Comments': doc.add_comment,
//             'Assigned To': doc.assigned_to,
//             'Created At': formatDateTime(doc.created_at),
//             'Assigned Date': formatDateTime(doc.assigned_date),
//             'Employee Name': doc.employee_name,
//         }))
//     );
//     const wb = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Leads');
//     XLSX.writeFile(wb, 'assignleads.xlsx');
// };


//    // Handle file change (if needed)
// const handleFileChange = (e) => {
//     setFile(e.target.files[0]);
// };

//     const handleFileUpload = async () => {
//         if (file) {
//             const formData = new FormData();
//             formData.append('file', file);

//             try {
//                 const response = await axios.post('https://api.clasptax.com/upload', formData, {
//                     headers: {
//                         'Content-Type': 'multipart/form-data',
//                     },
//                 });
//                 alert(response.data);  // Show success message
//                 fetchDocuments();  // Reload the table after upload
//             } catch (error) {
//                 console.error('Error uploading file:', error);
//             }
//         }
//     };

//     const handleDeleteDoc = async (clientid) => {
//         if (window.confirm('Are you sure you want to delete this document?')) {
//           try {
//             await axios.delete(`https://api.clasptax.com/leads/${clientid}`);
//             alert('Document deleted successfully');
//             fetchDocuments(); // Reload the table after deletion
//           } catch (error) {
//             console.error('Error deleting document:', error);
//           }
//         }
//       };

      
      
//     const handleViewDoc = (clientid) => {
//         navigate('/viewdoc', { state: { docId: clientid } });
//     };

//     const handleEditDoc = (clientid) => {
//         navigate('/editdoc', { state: { docId: clientid } });
//     };

//   // Fetch employee data from the backend
//   useEffect(() => {
//     const fetchEmployeeData = async () => {
//         try {
//             const response = await axios.get('https://api.clasptax.com/api/getemployeess');
//             setEmployeeData(response.data);  // Assuming response.data is an array of employee objects
//         } catch (error) {
//             console.error('Error fetching employee data:', error);
//         }
//     };
//     fetchEmployeeData();
// }, []);



//     const currentData = filterDocuments().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
//     const totalPages = Math.ceil(filterDocuments().length / itemsPerPage);

//     const handlePageChange = (direction) => {
//         setCurrentPage((prevPage) =>
//             direction === 'next' ? Math.min(prevPage + 1, totalPages) : Math.max(prevPage - 1, 1)
//         );
//     };

//     return (
//         <div>
//             <Adminsidebar />
//             <div className="container" style={{ marginLeft: "300px", width: "700px" }}>
//                 <div className="main-content">
//                     <div className="mt-4">
//                         <label className="btn btn-success">
//                             <FaUpload /> Upload
//                             <input
//                                 type="file"
//                                 className="d-none"
//                                 onChange={handleFileChange}
//                                 accept=".xlsx, .xls"
//                             />
//                         </label>
//                         {file && <button className="btn btn-info" onClick={handleFileUpload}><FaUpload /> Upload File</button>}
//                         <div className="row mb-3">
//                             <div className="col-sm-3">
//                                 <input
//                                     type="date"
//                                     className="form-control form-control-sm"
//                                     name="dateFrom"
//                                     value={filters.dateFrom}
//                                     onChange={handleFilterChange}
//                                 />
//                             </div>
//                             <div className="col-sm-3">
//                                 <input
//                                     type="date"
//                                     className="form-control form-control-sm"
//                                     name="dateTo"
//                                     value={filters.dateTo}
//                                     onChange={handleFilterChange}
//                                 />
//                             </div>
//                             <div className="col-sm-3">
//                                 <input
//                                     type="text"
//                                     className="form-control form-control-sm"
//                                     name="clientName"
//                                     placeholder="Client Name"
//                                     value={filters.clientName}
//                                     onChange={handleFilterChange}
//                                 />
//                             </div>
//                             <div className="col-sm-3">
//                                 <select
//                                     className="form-control form-control-sm"
//                                     name="status"
//                                     value={filters.status}
//                                     onChange={handleFilterChange}
//                                 >
//                                     <option value="">All Statuses</option>
//                                     <option value="completed">Completed</option>
//                                     <option value="pending">Pending</option>
//                                     <option value="declined">Declined</option>
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="table-actions mb-3">
//                         <button className="btn btn-primary" onClick={handleAssignTo} disabled={employeeData.length === 0}>
//                 Assign To
//             </button>
//                             <button className="btn btn-secondary" onClick={downloadTableAsExcel}>Export Excel</button>
//                             {selectedRows.length > 0 && <span>{`${selectedRows.length} item(s) selected`}</span>}
//                         </div>
//                         <div className="table-container">
//     <table className="table">
//         <thead>
//             <tr>
//                 <th>
//                     <input
//                         type="checkbox"
//                         checked={selectedRows.length === filterDocuments().length}
//                         onChange={handleSelectAll}
//                     />
//                 </th>
//                 <th>Client Name</th>
//                 <th>Email</th>
//                 <th>Contact</th>
//                 <th>Status</th>
//                 <th>Assigned To</th>
//                 <th>Created At</th>
//                 <th>Assigned Date</th>
//                 <th>Comments</th>
//                 <th>Actions</th>
//             </tr>
//         </thead>
//         <tbody>
//             {currentData.map((doc) => (
//                 <tr key={doc.id}>
//                     <td>
//                         <input
//                             type="checkbox"
//                             checked={selectedRows.includes(doc.id)}
//                             onChange={() => handleSelectRoww(doc.id)}
//                         />
//                     </td>
//                     <td>{doc.clientname}</td>
//                     <td>{doc.email}</td>
//                     <td>{doc.contact}</td>
//                     <td>{doc.status}</td>
//                     <td>{getAssignedEmployee(doc.id)}</td> {/* Updated to display the assigned employee */}
//                     <td>{formatDateTime(doc.created_at)}</td>
//                     <td>{formatDateTime(doc.assigned_date)}</td>
//                     <td>{doc.add_comment}</td>
//                     <td>
//                         <button className="btn btn-info btn-sm" onClick={() => handleViewDoc(doc.clientid)}><FaEye /></button>
//                         <button className="btn btn-warning btn-sm mx-1" onClick={() => handleEditDoc(doc.clientid)}><FaEdit /></button>
//                         <button className="btn btn-danger btn-sm" onClick={() => handleDeleteDoc(doc.clientid)}><FaTrashAlt /></button>
//                     </td>
//                 </tr>
//             ))}
//         </tbody>
//     </table>
// </div>

//                         <div className="pagination mt-3">
//                             <button className="btn btn-secondary" onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>Previous</button>
//                             <span className="mx-2">Page {currentPage} of {totalPages}</span>
//                             <button className="btn btn-secondary" onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>Next</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             {dropdownVisible && (
//     <div className="dropdown">
//         <div className="dropdown-header">
//             <span className="close-icon" onClick={() => setDropdownVisible(false)}>
//                 &times;
//             </span>
//         </div>
        
//         <ul>
//             {employeeData.map((employee, index) => (
//                 <li key={index}>
//                     <label>
//                         <input 
//                             type="checkbox" 
//                             value={employee.username} 
//                             checked={selectedEmployees.includes(employee.username)}
//                             onChange={() => handleCheckboxChangee(employee.username)}
//                         />
//                         {employee.username}
//                     </label>
//                 </li>
//             ))}
//         </ul>

//         <button onClick={handleOkClick} className="dropdown-ok-button">
//             Okay
//         </button>
//     </div>
// )}



//         </div>
//     );
// };

// export default Leads;


import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Adminsidebar from '../components/Adminsidebar';
import './leads.css';
import 'jspdf-autotable';
import * as xlsx from "xlsx";
import { FaEdit, FaTrashAlt, FaDownload, FaEye, FaUpload } from 'react-icons/fa';
import axios from 'axios';
 
 
const Leads = () => {
const navigate = useNavigate();
 
    const [documents, setDocuments] = useState([]);
    const [filters, setFilters] = useState({
        dateFrom: '',
        dateTo: '',
        clientName: '',
        status: ''
    });
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
 
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState("");
    useEffect(() => {
        fetchDocuments(); // Fetch documents when the component mounts
    }, []);
 
    const fetchDocuments = async () => {
        try {
            const response = await axios.get('https://api.clasptax.com/leads');
            setDocuments(response.data);
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };
 
 
    const formatDateTime = (dateTime) => new Date(dateTime).toLocaleString();
 
    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };
 
    const filterDocuments = () => {
        return documents.filter((doc) => {
            const { dateFrom, dateTo, clientName, status } = filters;
            const documentDate = new Date(doc.created_at);
            const fromDate = dateFrom ? new Date(dateFrom) : new Date(0);
            const toDate = dateTo ? new Date(dateTo) : new Date();
            return (
                (dateFrom === '' || documentDate >= fromDate) &&
                (dateTo === '' || documentDate <= toDate) &&
                (clientName === '' || doc.clientname.toLowerCase().includes(clientName.toLowerCase())) &&
                (status === '' || doc.status === status)
            );
        });
    };
 
 
const  handleAssignEmployee = async () =>{
    try {
   const response = await axios.put("https://api.clasptax.com/updateleads",{selectedRows1,selectedEmployees});
   if(response.data.success){
    alert("The values updated successfully")
   }
 
    }
    catch(error){
        console.log(error)
    }
 
}
 
    // Assign selected employees to selected clients
    const handleOkClick = () => {
        if (selectedRows1.length === 0 || selectedEmployees === "") {
            alert('Please select at least one client and one employee.');
            return;
        }
      handleAssignEmployee();
    };
 
    const handleonExport = () => {
        var wb = xlsx.utils.book_new(),
          ws = xlsx.utils.json_to_sheet(documents);
     
        xlsx.utils.book_append_sheet(wb, ws, "MySheet1");
        xlsx.writeFile(wb, "MyExcel.xlsx");
      };
 
    const  handleEmployeeChange =(employee) =>{
        setSelectedEmployees(employee.id)
    }
 
    // upload file in excel
    const fileInputRef = React.useRef(null);
 
    const handleUploadItems = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
 
    const readExcel = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = xlsx.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const excelData = xlsx.utils.sheet_to_json(sheet);
            try {
                await axios.post("https://api.clasptax.com/leads/upload/excel", {
                    data: excelData,
                },
                    // {
                    //     headers: {
                    //         'Content-Type': 'multipart/form-data',
                    //         },
                    // }
                    // {
                    //   headers: {
                    //     Authorization: `Bearer ${token}`
                    //   }
                    // }
                );
 
                // Update state with merged data after successful post
                // setItemData((prevData) => [ ...excelData]);
            } catch (error) {
                console.error("Error uploading Excel data:", error);
                // Handle error appropriately
            }
        };
        reader.readAsArrayBuffer(file);
    };
 
    const [selectedRows1, setSelectedRows1] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
 
    const handleCheckboxChangeid = (id) => {
        const isSelected = selectedRows1.includes(id);
        const updatedSelection = isSelected
            ? selectedRows1.filter((rowId) => rowId !== id)
            : [...selectedRows1, id];
 
        setCheckAll(updatedSelection.length === documents.length);
        setSelectedRows1(updatedSelection);
    };
 
 
    const handleCheckAll = () => {
        if (checkAll) {
            setSelectedRows1([]);
        } else {
            setSelectedRows1(documents.map((record) => record.id));
        }
        setCheckAll(!checkAll);
    };
 
 
    const handleDeleteDoc = async (clientid) => {
        if (window.confirm('Are you sure you want to delete this document?')) {
            try {
                await axios.delete(`https://api.clasptax.com/leads/${clientid}`);
                alert('Document deleted successfully');
                fetchDocuments(); // Reload the table after deletion
            } catch (error) {
                console.error('Error deleting document:', error);
            }
        }
    };
 
 
 
    const handleViewDoc = (clientid) => {
        navigate('/viewdoc', { state: { docId: clientid } });
    };
 
    const handleEditDoc = (clientid) => {
        navigate('/editdoc', { state: { docId: clientid } });
    };
 
    // Fetch employee data from the backend
    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const response = await axios.get('https://api.clasptax.com/api/getemployeess');
                setEmployeeData(response.data);  // Assuming response.data is an array of employee objects
            console.log(response.data);
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };
        fetchEmployeeData();
    }, []);
 
 
 
    const currentData = filterDocuments().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(filterDocuments().length / itemsPerPage);
 
    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) =>
            direction === 'next' ? Math.min(prevPage + 1, totalPages) : Math.max(prevPage - 1, 1)
        );
    };
 
    return (
        <div>
            <Adminsidebar />
            <div className="container" style={{ marginLeft: "300px", width: "700px" }}>
                <div className="main-content">
                    <div className="mt-4">
                   
                        <button onClick={handleUploadItems} type="button" className='btn btn-success'><FaUpload />Upload</button>
 
 
                        <div className="row mb-3">
                            <div className="col-sm-3">
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    name="dateFrom"
                                    value={filters.dateFrom}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="col-sm-3">
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    name="dateTo"
                                    value={filters.dateTo}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="col-sm-3">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="clientName"
                                    placeholder="Client Name"
                                    value={filters.clientName}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="col-sm-3">
                                <select
                                    className="form-control form-control-sm"
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">All Statuses</option>
                                    <option value="completed">Completed</option>
                                    <option value="pending">Pending</option>
                                    <option value="declined">Declined</option>
                                </select>
                            </div>
                        </div>
                        <div className='my-3 d-flex gap-2'>
                            <div className="dropdown">
                            <button
                                className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                                disabled={selectedRows1.length === 0}
                            >
                                Assign To ({selectedRows1.length})
                            </button>
                         
                           
                            <ul className='dropdown-menu'>
                                {employeeData.map((employee, index) => (
                                    <li key={index} className='dropdown-item'>
                                      <div className='d-flex gap-2'>
                                      <div>
                                      <input
                                                type="radio"
                                                value={employee.username}
                                                checked={selectedEmployees === (employee.id)}
                                                onChange={() => handleEmployeeChange(employee)}
                                            />
                                      </div>
                                       <div> {employee.username}</div>
                                      </div>
                                         
                                    </li>
                                ))}
                                   <button onClick={handleOkClick} className="btn btn-primary btn-sm"> Okay</button>
 
                            </ul>
                            </div>
                            <button className="btn btn-secondary" onClick={handleonExport}>Export Excel</button>
                        </div>
 
 
                        <div className="table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={checkAll}
                                                onChange={handleCheckAll}
                                            />
                                            Check All
                                        </th>
                                        <th>Client Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Status</th>
                                        <th>Assigned To</th>
                                        <th>Created At</th>
                                        <th>Assigned Date</th>
                                        <th>Comments</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.map((doc) => (
                                        <tr key={doc.id}>
                                            <td>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={selectedRows1.includes(doc.id)}
                                                    onChange={() => handleCheckboxChangeid(doc.id)}
                                                />
                                            </td>
                                            <td>{doc.client_name}</td>
                                            <td>{doc.email}</td>
                                            <td>{doc.contact}</td>
                                            <td>{doc.status}</td>
                                            <td>
                                            {doc.assigned_to ? doc.assigned_to : "Unassigned"}
                                             
                                                </td>
                                            <td>{formatDateTime(doc.created_at)}</td>
                                            <td>{doc.assigned_date}</td>
                                            <td>{doc.comment}</td>
                                            <td>
                                                <button className="btn btn-info btn-sm" onClick={() => handleViewDoc(doc.id)}><FaEye /></button>
                                                <button className="btn btn-warning btn-sm mx-1" onClick={() => handleEditDoc(doc.id)}><FaEdit /></button>
                                                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteDoc(doc.id)}><FaTrashAlt /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
 
                        <div className="pagination mt-3">
                            <button className="btn btn-secondary" onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>Previous</button>
                            <span className="mx-2">Page {currentPage} of {totalPages}</span>
                            <button className="btn btn-secondary" onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
 
            <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={readExcel}
            />
 
        </div>
    );
};
 
export default Leads;
