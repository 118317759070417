import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './empdashboard.css';
import Empsidebar from '../components/empsidebar';
import ReactApexChart from 'react-apexcharts';
import {
  Card,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
const Employeedashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [selectedMonth, setSelectedMonth] = useState('Jan');
 
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
 
  const [data, setData] = useState({
    cards: [
      { title: 'Total Calls Made', value: '1,500', color: '#d3d3d3' },
      { title: 'Successful Calls', value: '1,000', color: '#d3d3d3' },
      { title: 'Pending Follow-Ups', value: '300', color: '#d3d3d3' },
      { title: 'Total Filings Secured', value: '900', color: '#d3d3d3' },
      { title: 'Rejected Filings', value: '100', color: '#d3d3d3' },
      { title: 'Revenue Generated', value: '$50,000', color: '#d3d3d3' },
      { title: 'New Clients Engaged', value: '50', color: '#d3d3d3' },
      { title: 'Average Call Time', value: '7 mins', color: '#d3d3d3' },
    ],
  });
 
  const [series, setSeries] = useState({
    barChart: [
      {
        name: 'Successful Calls',
        data: [300, 400, 500, 300], // Data for Jan to Apr
      },
    ],
    lineChart: [
      {
        name: 'Filings Secured',
        data: [250, 300, 350, 400], // Data for Jan to Apr
      },
    ],
    dailyChart: {
      Jan: [
        {
          name: 'Target Calls',
          data: [100, 120, 110, 130, 140, 150, 160, 170, 180, 190, 200, 210],
        },
        {
          name: 'Calls Made',
          data: [80, 100, 90, 110, 130, 120, 150, 140, 160, 170, 190, 180],
        },
      ],
      Feb: [
        {
          name: 'Target Calls',
          data: [110, 130, 120, 140, 150, 160, 170, 180, 190, 200, 210, 220],
        },
        {
          name: 'Calls Made',
          data: [90, 110, 100, 120, 140, 130, 160, 150, 170, 180, 200, 190],
        },
      ],
      // Add similar data for Mar and Apr
    },
  });
 
  const [options, setOptions] = useState({
    barChart: {
      chart: { type: 'bar', height: 350 },
      plotOptions: { bar: { borderRadius: 10 } },
      dataLabels: { enabled: false },
      xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr'] },
      fill: { colors: ['#F8BE12'] },
    },
    lineChart: {
      chart: { type: 'line', height: 350 },
      dataLabels: { enabled: false },
      stroke: { curve: 'smooth' },
      xaxis: { categories: ['Jan', 'Feb', 'Mar', 'Apr'] },
      colors: ['#F8BE12'],
    },
    dailyChart: {
      chart: { type: 'bar', height: 350 },
      plotOptions: { bar: { borderRadius: 10 } },
      dataLabels: { enabled: false },
      xaxis: {
        categories: Array.from({ length: 31 }, (_, i) => (i + 1).toString()),
      },
      fill: {
        colors: ['#FF4141', '#0D7C66'],
      },
      legend: {
        markers: {
          fillColors: ['#FF4141', '#0D7C66'],
        },
      },
    },
  });
 
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
 
  useEffect(() => {
    const role = localStorage.getItem('role');
    if (role !== 'employee') {
      navigate('/login');
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.email || decoded.phoneNumber);
      setRole(decoded.role);
      if (decoded.role !== 'employee') {
        navigate('/login');
      }
    }
  }, [navigate]);
 
  console.log(username,"username")
 
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get('token');
        const response = await axios.get('https://api.clasptax.com/employee', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
      } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };
 
    fetchUserData();
  }, [navigate]);
 
  
  return (
    <div>
      <Empsidebar />
      <div>{username}</div>
      <div className="employee-dashboard-container">
        <div className="employee-dashboard-content">
          <div className="employee-cards-container">
            <Grid container spacing={3}>
              {data.cards.map((card, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Card
                    className="employee-dashboard-card"
                    style={{ backgroundColor: card.color }}
                  >
                    <div className="employee-card-content">
                      <Typography variant="h6">{card.title}</Typography>
                      <Typography variant="h4">{card.value}</Typography>
                    </div>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </div>
 
          <div className="employee-charts-container">
            <div className="employee-chart">
              <ReactApexChart
                options={options.barChart}
                series={series.barChart}
                type="bar"
                height={350}
              />
            </div>
            <div className="employee-chart">
              <ReactApexChart
                options={options.lineChart}
                series={series.lineChart}
                type="line"
                height={350}
              />
            </div>
          </div>
 
          <div className="employee-chart">
            <FormControl fullWidth>
              <InputLabel>Month</InputLabel>
              <Select
                value={selectedMonth}
                onChange={handleMonthChange}
                label="Month"
              >
                <MenuItem value="Jan">January</MenuItem>
                <MenuItem value="Feb">February</MenuItem>
                <MenuItem value="Mar">March</MenuItem>
                <MenuItem value="Apr">April</MenuItem>
              </Select>
            </FormControl>
            <ReactApexChart
              options={options.dailyChart}
              series={series.dailyChart[selectedMonth]}
              type="bar"
              height={350}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default Employeedashboard;