import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import './Cosection.css'; // Import the CSS file
import questionn from "../images/img1.jpg";
const Cosection= () => {
  return (
    <div className="contact-container">
    <div>
        <img src={questionn}   alt='' className='img-question'  />
        </div>
      <div className="contact-content">
        <h2>Have any questions?</h2>
        <p className='ppp-question'>Feel free to reach out to us for any inquiries or support!</p>
        <Link to ="/contactusform">
        <button className="contact-btn">Contact Us</button>
        </Link>
      </div>
    </div>
  );
};
 
export default Cosection;
 