import React, { useState } from 'react';
import  Adminsidebar  from '../components/Adminsidebar';
import './empactivity.css';

const Empactivity = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const employees = [
    { id: 1, name: 'John Doe', position: 'Software Engineer', shift: 'Morning', department: 'IT' },
    { id: 2, name: 'Jane Smith', position: 'Project Manager', shift: 'Evening', department: 'Management' },
    { id: 3, name: 'Michael Brown', position: 'Designer', shift: 'Morning', department: 'Design' },
    // Add more dummy employees here
  ];

  const activities = [
    { date: '2024-08-20', loginTime: '09:00 AM', logoutTime: '05:00 PM', totalWorkTime: '8 hours', remarks: 'Completed tasks on time' },
    { date: '2024-08-21', loginTime: '09:15 AM', logoutTime: '05:15 PM', totalWorkTime: '8 hours', remarks: 'Attended team meeting' },
    { date: '2024-08-22', loginTime: '08:50 AM', logoutTime: '05:05 PM', totalWorkTime: '8 hours 15 mins', remarks: 'Worked on urgent project' },
    // Add more dummy activities here
  ];

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    const employee = employees.find(emp => emp.name.toLowerCase().includes(event.target.value.toLowerCase()));
    setSelectedEmployee(employee || null);
  };

  return (
    <div className="emp-activity-container">
      <Adminsidebar />
      <div className="emp-activity-content">
        <h2>Employee Activity</h2>
        <div className="search-container">
          <input
            type="text"
            placeholder="Search by Employee Name"
            value={searchTerm}
            onChange={handleSearch}
          />
        </div>
        {selectedEmployee ? (
          <div className="employee-details">
            <h3>{selectedEmployee.name}</h3>
            <p><strong>Position:</strong> {selectedEmployee.position}</p>
            <p><strong>Department:</strong> {selectedEmployee.department}</p>
            <p><strong>Shift:</strong> {selectedEmployee.shift}</p>

            <h4>Activity Track</h4>
            <table className="activity-table">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Login Time</th>
                  <th>Logout Time</th>
                  <th>Total Work Time</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {activities.map((activity, index) => (
                  <tr key={index}>
                    <td>{activity.date}</td>
                    <td>{activity.loginTime}</td>
                    <td>{activity.logoutTime}</td>
                    <td>{activity.totalWorkTime}</td>
                    <td>{activity.remarks}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No employee selected. Please search for an employee.</p>
        )}
      </div>
    </div>
  );
};

export default Empactivity;
