import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import  Adminsidebar  from '../components/Adminsidebar';
import './documentstimeline.css';

// import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const Documentstimeline = () => {
    const navigate = useNavigate();
   

    const [filters, setFilters] = useState({
        search: '',
        status: 'all',
        dateFrom: '',
        dateTo: '',
        uploadType: 'all',
    });
    const [clientDocuments, setClientDocuments] = useState([]);
    const [currentTablePage, setCurrentTablePage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    useEffect(() => {
        // Dummy data for testing
        const dummyData = [
            { id: 1, client_id: 'C001', client_name: 'John Doe', email: 'john@example.com', contact: '1234567890', alternate_contact: '0987654321', caller_assigned: 'Agent 1', status: 'Completed', document_type: 'PDF', uploaded_date: '2024-08-01T12:00:00Z', comment: 'Sample PDF', upload_type: 'client' },
            { id: 2, client_id: 'C002', client_name: 'Jane Smith', email: 'jane@example.com', contact: '2345678901', alternate_contact: '1098765432', caller_assigned: 'Agent 2', status: 'Pending', document_type: 'Excel', uploaded_date: '2024-08-02T12:00:00Z', comment: 'Sample Excel', upload_type: 'caller' },
            { id: 3, client_id: 'C003', client_name: 'Mike Johnson', email: 'mike@example.com', contact: '3456789012', alternate_contact: '2109876543', caller_assigned: 'Agent 3', status: 'In Progress', document_type: 'Word', uploaded_date: '2024-08-03T12:00:00Z', comment: 'Sample Word', upload_type: 'client' },
            { id: 4, client_id: 'C004', client_name: 'Emily Davis', email: 'emily@example.com', contact: '4567890123', alternate_contact: '3210987654', caller_assigned: 'Agent 4', status: 'Completed', document_type: 'PDF', uploaded_date: '2024-08-04T12:00:00Z', comment: 'Sample PDF', upload_type: 'caller' },
            { id: 5, client_id: 'C005', client_name: 'Sarah Wilson', email: 'sarah@example.com', contact: '5678901234', alternate_contact: '4321098765', caller_assigned: 'Agent 5', status: 'Pending', document_type: 'Excel', uploaded_date: '2024-08-05T12:00:00Z', comment: 'Sample Excel', upload_type: 'client' }
        ];
        setClientDocuments(dummyData);
    }, []);

    const formatDateTime = (dateTime) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        return new Date(dateTime).toLocaleString('en-US', options);
    };

    const filterDocuments = () => {
        const { search, status, dateFrom, dateTo, uploadType } = filters;
        return clientDocuments.filter((doc) => {
            const docDate = new Date(doc.uploaded_date);
            const fromDate = dateFrom ? new Date(dateFrom) : new Date(0);
            const toDate = dateTo ? new Date(dateTo) : new Date();
            const searchTerm = search.toLowerCase();
            return (
                (doc.client_id.toLowerCase().includes(searchTerm) ||
                 doc.client_name.toLowerCase().includes(searchTerm) ||
                 doc.contact.includes(searchTerm)) &&
                (status === 'all' || doc.status === status) &&
                (uploadType === 'all' || doc.upload_type === uploadType) &&
                (dateFrom === '' || docDate >= fromDate) &&
                (dateTo === '' || docDate <= toDate)
            );
        });
    };

    const handlePrevPage = () => {
        if (currentTablePage > 1) {
            setCurrentTablePage(currentTablePage - 1);
        }
    };

    const handleNextPage = () => {
        const totalPages = Math.ceil(filterDocuments().length / itemsPerPage);
        if (currentTablePage < totalPages) {
            setCurrentTablePage(currentTablePage + 1);
        }
    };

    // const downloadTableAsPDF = () => {
    //     const doc = new jsPDF();
    //     doc.autoTable({
    //         head: [['Client ID', 'Client Name', 'Email', 'Contact', 'Alternate Contact', 'Caller Assigned', 'Status', 'Document Type', 'Uploaded Date', 'Comment']],
    //         body: filterDocuments().map(doc => [
    //             doc.client_id,
    //             doc.client_name,
    //             doc.email,
    //             doc.contact,
    //             doc.alternate_contact,
    //             doc.caller_assigned,
    //             doc.status,
    //             doc.document_type,
    //             formatDateTime(doc.uploaded_date),
    //             doc.comment,
    //         ]),
    //     });
    //     doc.save('documents.pdf');
    // };

    const downloadTableAsExcel = () => {
        const ws = XLSX.utils.json_to_sheet(filterDocuments().map(doc => ({
            'Client ID': doc.client_id,
            'Client Name': doc.client_name,
            'Email': doc.email,
            'Contact': doc.contact,
            'Alternate Contact': doc.alternate_contact,
            'Caller Assigned': doc.caller_assigned,
            'Status': doc.status,
            'Document Type': doc.document_type,
            'Uploaded Date': formatDateTime(doc.uploaded_date),
            'Comment': doc.comment,
        })));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Documents');
        XLSX.writeFile(wb, 'documents.xlsx');
    };

    const filteredData = filterDocuments();
    const indexOfLastItem = currentTablePage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    return (
        <div>
            <Adminsidebar />
            <div className="container">
                <div className="main-content">
                    <div className="dashboardalignn">
                        <div className="mt-4">
                            <div className="row mb-3">
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Search"
                                        value={filters.search}
                                        onChange={(e) => setFilters({ ...filters, search: e.target.value })}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        placeholder="From Date"
                                        value={filters.dateFrom}
                                        onChange={(e) => setFilters({ ...filters, dateFrom: e.target.value })}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        placeholder="To Date"
                                        value={filters.dateTo}
                                        onChange={(e) => setFilters({ ...filters, dateTo: e.target.value })}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <select
                                        className="form-control form-control-sm"
                                        value={filters.status}
                                        onChange={(e) => setFilters({ ...filters, status: e.target.value })}
                                    >
                                        <option value="all">All Status</option>
                                        <option value="Completed">Completed</option>
                                        <option value="Pending">Pending</option>
                                        <option value="In Progress">In Progress</option>
                                    </select>
                                </div>
                                <div className="col-sm-3 mt-2">
                                    <select
                                        className="form-control form-control-sm"
                                        value={filters.uploadType}
                                        onChange={(e) => setFilters({ ...filters, uploadType: e.target.value })}
                                    >
                                        <option value="all">All Uploads</option>
                                        <option value="client">Client Uploads</option>
                                        <option value="caller">Caller Uploads</option>
                                    </select>
                                </div>
                            </div>

                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Client ID</th>
                                            <th>Client Name</th>
                                            <th>Email</th>
                                            <th>Contact</th>
                                            <th>Alternate Contact</th>
                                            <th>Caller Assigned</th>
                                            <th>Status</th>
                                            <th>Document Type</th>
                                            <th>Uploaded Date</th>
                                            <th>Comment</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems.map((doc) => (
                                            <tr key={doc.id}>
                                                <td>{doc.client_id}</td>
                                                <td>{doc.client_name}</td>
                                                <td>{doc.email}</td>
                                                <td>{doc.contact}</td>
                                                <td>{doc.alternate_contact}</td>
                                                <td>{doc.caller_assigned}</td>
                                                <td>{doc.status}</td>
                                                <td>{doc.document_type}</td>
                                                <td>{formatDateTime(doc.uploaded_date)}</td>
                                                <td>{doc.comment}</td>
                                                <td>
                                                    <button className="btn btn-warning btn-sm me-2"><FaEdit /></button>
                                                    <button className="btn btn-danger btn-sm"><FaTrashAlt /></button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>

                            <div className="pagination">
                                <button className="btn btn-secondary" onClick={handlePrevPage} disabled={currentTablePage === 1}>Previous</button>
                                <span>Page {currentTablePage} of {totalPages}</span>
                                <button className="btn btn-secondary" onClick={handleNextPage} disabled={currentTablePage === totalPages}>Next</button>
                            </div>

                            <div className="mt-4">
                                {/* <button className="btn btn-primary me-2" onClick={downloadTableAsPDF}>Download PDF</button> */}
                                <button className="btn btn-primary" onClick={downloadTableAsExcel}>Download Excel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Documentstimeline;
