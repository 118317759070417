import React ,{useEffect}from 'react';
import { FaUser, FaBriefcase, FaDollarSign, FaFileAlt, FaChartLine, FaHandshake } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './se.css';
import Cosection from '../components/Cosections';
import Nav from '../components/Nav';
import { Upperbox} from '../components/Upperbox';
import Chatbot from '../../components/Chatbot';
import WhatsAppChatButton from '../../components/Whatsapp';
import { Footer } from '../components/footer';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Scrolltop from '../../components/Scrolltop';
import AOS from 'aos'; // Import AOS for animations
import 'aos/dist/aos.css';
const Se = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with duration of 1000ms
}, []);
    const services = {
        individual: [
            { title: 'Federal & State Tax Filing', icon: <FaUser />, description: 'Your guide through the tax jungle. Accurate filing, expert planning, all tax needs covered. Free yourself, choose Clasp Tax!', link: '/fedrel' },
            { title: 'Amendment', icon: <FaDollarSign />, description: 'Amend your tax return with expert help for accuracy and efficiency. Correct errors, claim deductions, and maximize your refund.', link: '/Amendment' },
            { title: 'ITIN Preparation', icon: <FaFileAlt />, description: 'Streamlined ITIN application process by certified agents. Expert assistance for Form W-7, identity document certification, and IRS submission.', link: '/itinpreparation' },
            { title: 'FBAR & FATCA', icon: <FaChartLine />, description: 'FBAR (FINCEN114) & FATCA filing made easy. Report foreign financial accounts & assets accurately. Avoid penalties.', link: '/fbar' },
            { title: 'W4 Assistance', icon: <FaDollarSign />, description: 'Optimize your W-4 with expert assistance from Clasp Tax. Claim allowances, ensure accuracy, and align withholding with your financial goals.', link: '/w4assistance' },
            { title: 'Tax Extension Filing', icon: <FaUser />, description: 'Request a tax filing extension with Clasp Tax. Get expert assistance, avoid penalties, and ensure timely compliance.', link: '/taxextension' },
            { title: 'Estimate Tax Payments', icon: <FaHandshake />, description: 'Let Clasp Tax handle your estimated taxes to avoid IRS penalties. Our experts ensure accurate filings, saving you time and providing peace of mind.', link: '/estimatetax' },
            { title: 'TAX PLANNING', icon: <FaChartLine />, description: 'Clasp Tax offers expert tax planning by CPAs and EAs to minimize liabilities. Personalized strategies for long-term financial success.', link: '/taxplanning' },
            { title: 'Free Unlimited Tax Representation before IRS', icon: <FaChartLine />, description: 'Clasp Tax assures expert support through IRS audits. Trust our professionals for comprehensive representation and resolution.', link: '/free' },
            { title: 'Filing options', icon: <FaChartLine />, description: 'Your trusted partner for seamless tax filing. Opt for speedy e-filing or traditional paper filing. Contact us for expert assistance today.', link: '/filingoptions' },
            { title: 'Indian Income Tax Filing Services', icon: <FaChartLine />, description: 'Efficient, accurate filing for individuals, ensuring compliance with tax laws and timely submissions.', link: '/indian' },
            { title: 'Indian Tax Advisory and Planning Services', icon: <FaChartLine />, description: 'Strategic tax guidance for individuals, optimizing efficiency through personalized advice on deductions, credits, and savings.', link: '/indianagstreturns' },
        ],
        business: [
            { title: 'Corporate Tax Filing', icon: <FaBriefcase />, description: 'Professional tax filing services for businesses.', link: '/corporate-tax-filing' },
            { title: 'Expense Analysis', icon: <FaDollarSign />, description: 'Expert analysis to streamline business expenses.', link: '/expense-analysis' },
            { title: 'Audit Support', icon: <FaFileAlt />, description: 'Full support in case of tax audits.', link: '/audit-support' },
            { title: 'Financial Forecasting', icon: <FaChartLine />, description: 'Make informed decisions with expert forecasting.', link: '/financial-forecasting' },
            { title: 'Partnership Filing', icon: <FaHandshake />, description: 'File taxes seamlessly for business partnerships.', link: '/partnership-filing' },
        ],
    };
 
    const ServiceCard = ({ title, description, icon, link }) => {
        return (
          <Link to={link} className='sefile-2' data-aos="fade-up"> {/* Wrap the card in a Link */}
                <div className="sefile1">
                    <div className="card-content">
                       <div className="icon-iui">{icon}</div>
                        <h2 className="card-icon-texttt">{title}</h2>
                        <p className="per-ppp">{description}</p>
                        {/* <button className="readdd">Read More</button> */}
                        <h6 className='readmore-t'>Readmore <MdKeyboardDoubleArrowRight /></h6>
                    </div>
                </div>
            </Link>
        );
    };
 
    return (
        <div>
        <Upperbox/>
            <Nav />
            <div className="sefile-poster">
                <h1 className="sefile-text"> Individual Services</h1>
                <div className="line-animation"></div>
            </div>
            <div className="sefile-2">
                {services.individual.map((service, index) => (
                    <ServiceCard key={index} title={service.title} description={service.description} icon={service.icon} link={service.link} />
                ))}
            </div>
            <Cosection/>
            <Footer/>
            <Chatbot/>
            <Scrolltop/>
<WhatsAppChatButton/>
        </div>
    );
};
 
export default Se;
 