// ContactUs.js
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import{jwtDecode} from "jwt-decode";
import axios from "axios";
import './helpcenter.css'; // Import the stylesheet
import  Clientsidebar  from '../components/Clientsidebar';
const Helpcenter = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");

  // useEffect(() => {
  //   Axios.get('https://api.clasptax.com/home', { withCredentials: true })
  //     .then((response) => {
  //       if (!response.data.loggedIn) {
  //         navigate('/login');
  //       } 
  //     })
  //     .catch((error) => {
  //       console.error('Error checking authentication:', error);
  //       navigate('/login');
  //     });
  // }, [navigate]);
  useEffect(() => {
      const token = Cookies.get("token");
    console.log(token);
      if (!token) {
        // No token, redirect to home
        // navigate("/");
  
      } else {
        try {
          const decoded = jwtDecode(token);
          setUsername(decoded.username);
          setRole(decoded.role);
     
          // If the decoded role is not Client, redirect
          if (decoded.role !== "client") {
            // navigate("/");
  
          }
        } catch (error) {
          console.error("Invalid token");
          // navigate("/");
        }
      }
    }, [navigate]);
  
    useEffect(() => {
      const token = Cookies.get("token");
      if (token) {
      const fetchUserData = async () => {
        try {
          const token = Cookies.get("token");
          const response = await axios.get("https://api.clasptax.com/client", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response.data);
        } catch (error) {
          console.error("Error:", error);
          if (error.response && error.response.status === 401) {
            // navigate("/");
          }
        }
      };
  
      fetchUserData();
    }
    }, [navigate]);


  return (
    <div>
    <Clientsidebar/>
    <div className='asdfgh'>
    <div className="contact-us-container">
      <div className="contact-info">
        <h2>Contact Us</h2>
        <p>Email: info@clasptax.com</p>
        <p>Phone: +1(972)-220-9783</p>
      </div>
      <div className="reach-out">
        <p>Call or email us, we will reach out soon!</p>
      </div>
    </div>
    </div>
   
    </div>
  );
};

export default Helpcenter;