import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './empsidebar.css';
import logoo from '../images/img123.png';
import Cookies from 'js-cookie';

import  Axios  from 'axios';
 
const Empsidebar = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [username, setUsername] = useState('');
  const [logoutStatus, setLogoutStatus] = useState('');
  const navigate = useNavigate();
  const token = Cookies.get("token")
  const role =  localStorage.getItem("role")
  const location = useLocation();
 

  const handleLogout = () => {
    Axios.post('https://api.clasptax.com/logout', {}, { withCredentials: true })
    .then((response) => {
        if (response.data.success) {
            setLogoutStatus('Logged out successfully');
            Cookies.remove("token")
            localStorage.removeItem('role')
            navigate('/login');  
        } else {
            setLogoutStatus('Logout failed');
        }
    })
    .catch((error) => {
        console.error('Logout failed:', error);
    });
};
 
  const handleToggle = () => {
    if (window.innerWidth < 992) {
      setIsToggled(!isToggled);
    }
  };
 
  return (
    <div id="wrapper" className={isToggled ? 'toggled' : ''}>
      {/* Sidebar */}
      <aside id="emp-sidebar-wrapper">
        <div className="emp-sidebar-brand row">
          <img src={logoo} alt='logo' className='emp-logooo mr-2'/>
          <h2>Clasptax</h2>
        </div>
        <ul className="emp-sidebar-nav">
          <li className={location.pathname === '/dashboard' ? 'active' : ''}>
            <Link to="/employee">
              <i className="material-symbols-outlined">dashboard</i>Dashboard
            </Link>
          </li>
          <li className={location.pathname === '/analytics' ? 'active' : ''}>
            <Link to="/empanalytics">
              <i className="material-symbols-outlined">dashboard</i>Analytics
            </Link>
          </li>
         
        
       
          <li className={location.pathname === '/empshifts' ? 'active' : ''}>
            <Link to="/empshifts">
              <i className="material-symbols-outlined">group_add</i>My Shifts
            </Link>
          </li>
         
          <li className={location.pathname === '/invoice' ? 'active' : ''}>
            <Link to="/empProfile">
              <i className="material-symbols-outlined">receipt</i> My Profile
            </Link>
          </li>


          <li className={location.pathname === '/empLead' ? 'active' : ''}>
            <Link to="/empLeads">
              <i className="material-symbols-outlined">dashboard</i>Leads
            </Link>
          </li>

          <li className={location.pathname === '/empClient' ? 'active' : ''}>
            <Link to="/empClients">
              <i className="material-symbols-outlined">dashboard</i>Clients
            </Link>
          </li>
         
          <li className={location.pathname === '/empdocuments' ? 'active' : ''}>
            <Link to="/empdocuments">
              <i className="material-symbols-outlined">dashboard</i>Documents
            </Link>
          </li>

          <li className='emp-d-md-none'>
            <Link to="/referandearn" onClick={handleLogout}>
              <i className="fa-solid fa-right-from-bracket"></i>Logout
            </Link>
          </li>
        </ul>
      </aside>
 
      {/* Navbar */}
      <div id="emp-navbar-wrapper">
        <nav className="emp-navbar navbar-inverse">
          <div className="emp-container-fluid">
            <div className="emp-navbar-header">
              <div className='row'>
                <div>
                  <a href="#" className="navbar-brand emp-d-md-none" id="emp-sidebar-toggle" onClick={handleToggle}>
                    <i className="fa fa-bars" style={{ color: 'black' }}></i>
                  </a>
                </div>
             
              </div>
            </div>
            <div className='row'>
              <div className={isToggled ? 'emp-d-none' : ''}>
                <div className="emp-paste-button">
                  <button className="emp-button">Welcome {username} &nbsp; ▼</button>
                  <div className="emp-dropdown-content">
                    <Link to='/settings'>
                      <a id="emp-top" href="#">Settings</a>
                    </Link>
                    <Link to='/helpcenter'>
                      <a id="emp-middle" href="#">Help Center</a>
                    </Link>
                  </div>
                </div>
              </div>
              <button className="emp-Btnn emp-d-none emp-d-md-block" onClick={handleLogout}>
                <div className="emp-sign">
                  <svg viewBox="0 0 512 512">
                    <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                  </svg>
                </div>
                <div className="emp-text">Logout</div>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
 
export default Empsidebar;
 