import React, { useEffect } from 'react';
import { Contactusform } from './contactusform';
import './price.css';
import Nav from "../components/Nav";
import { Footer } from '../components/footer';
import Chatbot from '../../components/Chatbot';
import { Upperbox} from '../components/Upperbox';
import WhatsAppChatButton from '../../components/Whatsapp';
import Cosection from '../components/Cosections';
import Scrolltop from '../../components/Scrolltop';
export const Price = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  const services = [
    { category: 'Individuals', items: [
      { service: 'Federal - (1040)', price: '$100' },
      { service: 'Federal - (1040-NR)', price: '$120' },
      { service: 'Each State Return', price: '$50' },
      { service: 'City Return & County Returns', price: '$40' },
      { service: 'FBAR / FATCA', price: '$80' },
      { service: 'Schedule C, E & 1099MISC', price: '$60' },
      { service: 'ITIN Application', price: '$75' },
      { service: 'Amended Return Charges', price: '$90' }
    ]},
    { category: 'Businesses', items: [
      { service: 'Business Incorporation', price: '$300' },
      { service: 'MM LLC / Partnership Return (1065)', price: '$200' },
      { service: 'S Corporation (1120-S)', price: '$250' },
      { service: 'Corporation (1120)', price: '$220' }
    ]},
    { category: 'Special Returns', items: [
      { service: 'Estate and Trusts (1041)', price: '$400' },
      { service: 'Estate Tax Return (706)', price: '$500' },
      { service: 'Gift Tax Return (709)', price: '$350' }
    ]},
    { category: 'Bookkeeping & Payroll', items: [
      { service: 'Bookkeeping & Accounting', price: '$300' },
      { service: 'Full-Time Bookkeeping', price: '$450' },
      { service: 'Half-Time Bookkeeping', price: '$250' },
      { service: 'Federal Unemployment Tax Return (940)', price: '$150' },
      { service: 'Quarterly Federal Tax Return (941)', price: '$100' }
    ]},
    { category: 'Free Services', items: [
      { service: 'Tax Representation for 8 Years', price: 'Free' },
      { service: 'ITIN Application Assistance', price: 'Free' },
      { service: 'W-4 Assistance', price: 'Free' },
      { service: 'Entity Selection Assistance', price: 'Free' },
      { service: 'Bookkeeping Setup Assistance', price: 'Free' },
      { service: 'Payroll Setup Assistance', price: 'Free' },
      { service: 'Extension Filing', price: 'Free' },
      { service: 'Tax Saving Strategy Assistance', price: 'Free' }
    ]}
  ];
 
  return (
    <div>
     <Upperbox/>
    <Nav/>
    <div className='backgroundImageStyle'>
        <p className="sefile-text">Price</p>
        <div className="line-animation"></div>
      </div>
    <div className="price-container">
      <h5 className="price-title">Our Services & Pricing</h5>
      <div className="price-table-wrapper">
        {services.map((serviceCategory, index) => (
          <table key={index} className="price-table">
            <thead>
              <tr>
                <th>{serviceCategory.category}</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {serviceCategory.items.map((item, idx) => (
                <tr key={idx}>
                  <td>{item.service}</td>
                  <td className="price">{item.price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
   
    </div>
    <Cosection/>
    <Chatbot/>
    <Scrolltop/>
    <WhatsAppChatButton/>
    <Footer/>
    </div>
  );
};
 