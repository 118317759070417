import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import{jwtDecode} from "jwt-decode";
import axios from "axios";
import './clientinvoice.css'
import  Clientsidebar  from '../components/Clientsidebar';

 const Clientinvoice = () => {
  const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [role, setRole] = useState("");
  
  useEffect(() => {
    const token = Cookies.get("token");
  console.log(token);
    if (!token) {
      // No token, redirect to home
      // navigate("/");

    } else {
      try {
        const decoded = jwtDecode(token);
        setUsername(decoded.username);
        setRole(decoded.role);
   
        // If the decoded role is not Client, redirect
        if (decoded.role !== "client") {
          // navigate("/");

        }
      } catch (error) {
        console.error("Invalid token");
        // navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get("token");
        const response = await axios.get("https://api.clasptax.com/client", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
        if (error.response && error.response.status === 401) {
          // navigate("/");
        }
      }
    };

    fetchUserData();
  }
  }, [navigate]);

  return (
   <div >
    <Clientsidebar />
   </div>
        
  )
}
export default Clientinvoice;