import React, { useState } from 'react';
import axios from 'axios';
import './forgetpassword.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

export const Forgotpassword = () => {
  const [emailList, setEmailList] = useState('');
  const [isSendingMail, setIsSendingMail] = useState(false);
  const navigate = useNavigate();

  const handleSendEmails = async () => {
    try {
      setIsSendingMail(true); // Set the flag to true when sending starts

      await axios.post('https://api.clasptax.com/send-emails', {
        emailList: emailList
      }).then((response) => {
        console.log(response);
        alert('Mail successfully');
        navigate('/login');
      });
    } catch (error) {
      console.error('Error sending emails:', error);
      alert('Error sending emails. Check the console for more details.');
      console.log('Error details:', error.response);
    } finally {
      setIsSendingMail(false); // Set the flag to false when sending completes (success or failure)
    }
  };

  return (
    <div>
      <div className="containeerrrr">
        <Link to="/login">
          <p className="goback">
            <i className="fa-solid fa-arrow-left mr-2" style={{ color: 'rgb(6,124,255)' }}></i>Go Back
          </p>
        </Link>
        <div className="row">
          <div>
            <div className="panel panel-default">
              <div className="panel-body">
                <div className="text-center">
                  <h2 className="text-center">Forgot Password?</h2>
                  <p>Enter email here password will send to your mail</p>
                  <div className="panel-body">
                    <div id="register-form" role="form" autoComplete="off" className="form" method="post">
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            id="email"
                            name="email"
                            placeholder="email address"
                            className="form-control"
                            value={emailList}
                            onChange={(e) => setEmailList(e.target.value)}
                            rows="4"
                            cols="50"
                            type="email"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <button className="btn btn-lg btn-primary btn-block" onClick={handleSendEmails} disabled={isSendingMail}>
                          {isSendingMail ? 'Sending Mail...' : 'Send'}
                        </button>
                      </div>
                      <input type="hidden" className="hide" name="token" id="token" value="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
