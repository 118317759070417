import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import Adminsidebar from '../components/Adminsidebar';
import Cookies from 'js-cookie';
import './adminprofile.css';
import {jwtDecode} from 'jwt-decode'; // Corrected the import syntax
import axios from 'axios';
 


const AdminProfile = () => {
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    phoneNumber: '',
    role: '',
  });
  
  const navigate = useNavigate();
  const [role, setRole] = useState('');
  const [data, setData] = useState(null);
  const [username, setUsername] = useState('');

  // Check if the user is authenticated and has the correct role
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    }
  }, []);
 
  // Decode token and set user information
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.email || decoded.phoneNumber);
      setRole(decoded.role);
      if (decoded.role !== 'admin') {
        navigate('/login');
      }
    }
  }, []);
 
  // Fetch admin details based on username
  useEffect(() => {
    axios
      .get(`https://api.clasptax.com/get/admindetails/${username}`)
      .then((response) => {
        setData(response.data);
        if (response.data && response.data.length > 0) {
          // Set form data with the fetched values
          setFormData({
            userName: response.data[0].userName || '',
            email: response.data[0].email || '',
            phoneNumber: response.data[0].phoneNumber || '',
            role: response.data[0].role || '',
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, [username]);
 
  // Displaying the form fields based on fetched data
  return (
    <div>
      <Adminsidebar />
      <div className="admin-profile-container" style={{ marginLeft: "300px" }}>
        <h2>Admin Profile Details</h2>
        <form className="admin-profile-form">
          <div className="form-group">
            <label>User Name:</label>
            <input
              type="text"
              value={formData.userName}
              readOnly
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={formData.email}
              readOnly
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Phone Number:</label>
            <input
              type="text"
              value={formData.phoneNumber}
              readOnly
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>Role:</label>
            <input
              type="text"
              value={formData.role}
              readOnly
              className="form-control"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminProfile;
