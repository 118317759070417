import React, { useState, useEffect,useContext } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import  Clientsidebar  from '../components/Clientsidebar';
import './taxorganizer.css';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';


 


export const Taxorganizer = () => {
    const navigate = useNavigate();
 

  
    // useEffect(() => {
    //   Axios.get('https://api.clasptax.com/home', { withCredentials: true })
    //     .then((response) => {
    //       if (!response.data.loggedIn) {
    //         navigate('/login');
    //       } 
    //     })
    //     .catch((error) => {
    //       console.error('Error checking authentication:', error);
    //       navigate('/login');
    //     });
    // }, [navigate]);

    

    const handleDownload = () => {
      // Download the specified PDF file based on the selected year
    
    };

  

  return (
   

      <div >
    <Clientsidebar/>

      <div className='dashboardalignn '>
        <div class="content pl-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-12">
                <div class="page-title-box">
                    <h4 class="page-title">
                        Tax Organizer
                    </h4>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 max-w-xl">
                <ul class="list-group">
                    <li class="d-flex align-items-center justify-content-between list-group-item">
                        <p class="my-0 font-weight-normal">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-paperclip mr-1 light-gray"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>
                            Clasp Tax - Tax Notes - TY
                        </p>

                        <button
                            className="btn btn-primary"
                            onClick={handleDownload}
                            title="Download PDF"
                          >
                            Download
                        </button>

                    </li>
                </ul>
            </div>
        </div>
    </div>
        </div>
    </div>
    </div>
   
  )
}
