import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './admindashboard.css';
import  Adminsidebar  from '../components/Adminsidebar';
import Cookies from "js-cookie";
import axios from "axios";
import{jwtDecode} from "jwt-decode";
import ReactApexChart from 'react-apexcharts';
import { Card, Typography, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const Admindashboard = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('team');
    const [selectedMonth, setSelectedMonth] = useState('Jan');

    const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const [data, setData] = useState({
        cards: [
            { title: 'Total Tax Filings', value: '1,200', color: "#d3d3d3" },
            { title: 'Pending Tax Returns', value: '350', color: "#d3d3d3" },
            { title: 'Approved Tax Returns', value: '850', color: "#d3d3d3" },
            { title: 'Rejected Tax Returns', value: '50', color:"#d3d3d3" },
            { title: 'Total Revenue', value: '$120,000', color: "#d3d3d3" },
            { title: 'Monthly Revenue', value: '$15,000', color: "#d3d3d3" },
            { title: 'New Clients', value: '75', color: "#d3d3d3" },
            { title: 'Returning Clients', value: '200', color: "#d3d3d3"  },
            { title: 'Open Audits', value: '10', color: "#d3d3d3"  },
            { title: 'Resolved Audits', value: '5', color: "#d3d3d3"  },
            { title: 'Late Filings', value: '25', color: "#d3d3d3" },
            { title: 'Customer Satisfaction', value: '98%', color: "#d3d3d3" }
        ],
        team: [
            { title: 'Total Employees', value: '150' },
            { title: 'Active Projects', value: '45' },
            { title: 'New Clients', value: '20' }
        ],
        leads: [
            { title: 'Leads Generated', value: '200' },
            { title: 'Leads Converted', value: '80' },
            { title: 'Lead Conversion Rate', value: '40%' }
        ],
        referrals: [
            { title: 'Referrals Received', value: '30' },
            { title: 'Successful Referrals', value: '15' },
            { title: 'Referral Conversion Rate', value: '50%' }
        ]
    });

    const [series, setSeries] = useState({
        barChart: [
            {
                name: "Revenue",
                data: [5000, 7000, 8000, 6000] // Data for Jan to Apr
            }
        ],
        lineChart: [
            {
                name: "Clients",
                data: [2000, 4000, 3500, 3000] // Data for Jan to Apr
            }
        ],
        dailyChart: {
            Jan: [
                { name: 'Target', data: [1000, 1200, 1100, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100] },
                { name: 'Achieved', data: [500, 600, 550, 700, 650, 700, 750, 800, 850, 900, 950, 1000] }
            ],
            Feb: [
                { name: 'Target', data: [1100, 1300, 1200, 1400, 1500, 1600, 1700, 1800, 1900, 2000, 2100, 2200] },
                { name: 'Achieved', data: [600, 700, 650, 750, 700, 750, 800, 850, 900, 950, 1000, 1050] }
            ],
            // Add similar data for Mar and Apr
        }
    });

    const [options, setOptions] = useState({
        barChart: {
            chart: { type: 'bar', height: 350 },
            plotOptions: { bar: { borderRadius: 10 } },
            dataLabels: { enabled: false },
            xaxis: { categories: ["Jan", "Feb", "Mar", "Apr"] },
            fill: { colors: ["#F8BE12"] } 
        },
        lineChart: {
            chart: { type: 'line', height: 350 },
            dataLabels: { enabled: false },
            stroke: { curve: 'smooth' },
            xaxis: { categories: ["Jan", "Feb", "Mar", "Apr"] },
            colors: ["#F8BE12"]
        },
        dailyChart: {
            chart: { type: 'bar', height: 350 },
            plotOptions: { bar: { borderRadius: 10 } },
            dataLabels: { enabled: false },
            xaxis: { categories: Array.from({ length: 31 }, (_, i) => (i + 1).toString()) },
            fill: { 
               colors: ["#FF4141", "#0D7C66"]   // Update the colors here
            },
            legend: {
                markers: {
                    fillColors: ["#FF4141", "#0D7C66"] // Update the colors in the legend
                }
            }
        }
    });

    const [username, setUsername] = useState('');
    const [role, setRole] = useState('');
   
    useEffect(() => {
      const role = localStorage.getItem('role');
      if (role !== 'admin') {
        navigate('/login');
      }
    }, [navigate]);
   
    useEffect(() => {
      const token = Cookies.get('token');
      if (!token) {
        navigate('/login');
      } else {
        const decoded = jwtDecode(token);
        setUsername(decoded.email || decoded.phoneNumber);
        setRole(decoded.role);
        if (decoded.role !== 'admin') {
          navigate('/login');
        }
      }
    }, [navigate]);
   
    console.log(username,"username")
    useEffect(() => {
      const token = Cookies.get("token");
      if (token) {
      const fetchUserData = async () => {
        try {
          const token = Cookies.get("token");
          const response = await axios.get("https://api.clasptax.com/admin", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          console.log(response.data);
        } catch (error) {
          console.error("Error:", error);
          if (error.response && error.response.status === 401) {
            // navigate("/");
          }
        }
      };
  
      fetchUserData();
    }
    }, [navigate]);
  

    return (
        <div>
            <Adminsidebar />
            <div className="dashboard-container">
                <div className="dashboard-content">
                    <div className="cards-container">
                        <Grid container spacing={3}>
                            {data.cards.map((card, index) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                    <Card className="dashboard-card" style={{ backgroundColor: card.color }}>
                                        <div className="card-content">
                                            <Typography variant="h6">{card.title}</Typography>
                                            <Typography variant="h4">{card.value}</Typography>
                                        </div>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </div>

                    <div className="charts-container">
                        <div className="chart">
                            <ReactApexChart options={options.barChart} series={series.barChart} type="bar" height={350} />
                        </div>
                        <div className="chart">
                            <ReactApexChart options={options.lineChart} series={series.lineChart} type="line" height={350} />
                        </div>
                    </div>

                    <div className="chart">
                        <FormControl fullWidth>
                            <InputLabel>Month</InputLabel>
                            <Select
                                value={selectedMonth}
                                onChange={handleMonthChange}
                                label="Month"
                            >
                                <MenuItem value="Jan">January</MenuItem>
                                <MenuItem value="Feb">February</MenuItem>
                                <MenuItem value="Mar">March</MenuItem>
                                <MenuItem value="Apr">April</MenuItem>
                            </Select>
                        </FormControl>
                        <ReactApexChart options={options.dailyChart} series={series.dailyChart[selectedMonth]} type="bar" height={350} />
                        
                    </div>

                
                </div>
            </div>
        </div>
    );
};

export default Admindashboard;