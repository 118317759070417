 
import React, { useState, useEffect,useContext } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './clientsidebar.css';
import { Link } from 'react-router-dom';
import logoo from '../images/img123.png';
import { Taxorganizer } from '../pages/taxorganizer';

 
 const Clientsidebar = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [logoutStatus, setLogoutStatus] = useState('');
  const [username, setUsername] = useState('');
  const navigate = useNavigate();
 
 
 
  // useEffect(() => {
  //   Axios.get('https://api.clasptax.com/home', { withCredentials: true })
  //     .then((response) => {
  //       if (response.data.loggedIn) {
  //         setUsername(response.data.user.username);
  //       } else {
  //         navigate('/login');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error checking authentication:', error);
  //       navigate('/login');
  //     });
     
  // }, [navigate]);
 
 
 
  const handleLogout = () => {
    Axios.post('https://api.clasptax.com/logout', {}, { withCredentials: true })
    .then((response) => {
        if (response.data.success) {
            setLogoutStatus('Logged out successfully');
            navigate('/login');  
        } else {
            setLogoutStatus('Logout failed');
        }
    })
    .catch((error) => {
        console.error('Logout failed:', error);
    });
};
 
  const handleToggle = () => {
    // Check if the screen width is less than 992px (mobile view)
    if (window.innerWidth < 992) {
      setIsToggled(!isToggled);
    }
  };
 
  return (
    <div id="wrapper" className={isToggled ? 'toggled' : ''}>
      {/* Sidebar */}
      <aside id="sd-sidebar-wrapper">
        <div className="sd-sidebar-brand row">
          <img src={logoo} alt='logo' className='sd-logooo mr-2'/>
          <h2>Clasptax</h2>
        </div>
        <ul className="sd-sidebar-nav">
          <li>
            <Link to="/client">
              <i className="material-symbols-outlined">dashboard</i>Dashboard
            </Link>
          </li>
          <li>
            <Link to="/taxorganizer">
              <i className="material-symbols-outlined">description</i>Tax Organizer
            </Link>
          </li>
          <li>
            <Link to="/documents">
              <i className="material-symbols-outlined">file_copy</i>Documents
            </Link>
          </li>
          <li>
            <Link to="/referandearn">
              <i className="material-symbols-outlined">group_add</i>Refer & Earns
            </Link>
          </li>

          <li>
            <Link to="/clientprofile">
              <i className="material-symbols-outlined">person</i>My Profile
            </Link>
          </li>
          <li className='d-md-none'>
            <Link to="/referandearn"  onClick={handleLogout}>
              <i className="fa-solid fa-right-from-bracket "></i>Logout
            </Link>
          </li>
        </ul>
      </aside>
 
      {/* Navbar */}
      <div id="sd-navbar-wrapper">
        <nav className="sd-navbar navbar-inverse">
          <div className="sd-container-fluid">
            <div className="sd-navbar-header">
              <div className='row'>
                <div>
                  <a href="#" className="sd-navbar-brand d-md-none" id="sidebar-toggle" onClick={handleToggle}>
                    <i className="fa fa-bars" style={{color:'black'}}></i>
                  </a>
                </div>
                
              </div>
            </div>
            <div className='row'>
            <div className={isToggled ? 'd-none' : ''}>
            <div className="sd-paste-button ">
                <button className="sd-button">Welcome {username} &nbsp; ▼</button>
                <div className="sd-dropdown-content">
                <Link to='/settings'>
                  <a id="top" href="#">
                    Settings
                  </a>
                  </Link>
                  <Link to='/helpcenter'>
                  <a id="middle" href="#">
                    Help Center
                  </a>
                  </Link>
                </div>
              </div>
            </div>
             
 
              <button className="sd-Btnn d-none d-md-block" onClick={handleLogout}>
                <div className="sd-sign">
                  <svg viewBox="0 0 512 512">
                    <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                  </svg>
                </div>
                <div className="sd-text">Logout</div>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default Clientsidebar;