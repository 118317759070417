import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './adminsidebar.css';
import logoo from '../images/img123.png';

import  Axios  from 'axios';
 
const Adminsidebar = () => {
  const [isToggled, setIsToggled] = useState(false);
  const [username, setUsername] = useState('');
  const [logoutStatus, setLogoutStatus] = useState(''); 
  const navigate = useNavigate();
  
  const location = useLocation();
 
  
  const handleLogout = () => {
    Axios.post('https://api.clasptax.com/logout', {}, { withCredentials: true })
    .then((response) => {
        if (response.data.success) {
            setLogoutStatus('Logged out successfully');
            navigate('/login');  
        } else {
            setLogoutStatus('Logout failed');
        }
    })
    .catch((error) => {
        console.error('Logout failed:', error);
    });
};
 
  const handleToggle = () => {
    if (window.innerWidth < 992) {
      setIsToggled(!isToggled);
    }
  };
 
  return (
    <div id="wrapper" className={isToggled ? 'as-toggled' : ''}>
      {/* Sidebar */}
      <aside id="as-sidebar-wrapper">
        <div className="as-sidebar-brand row">
          <img src={logoo} alt='logo' className='as-logooo mr-2'/>
          <h2>Clasptax</h2>
        </div>
        <ul className="as-sidebar-nav">
          <li className={location.pathname === '/dashboard' ? 'active' : ''}>
            <Link to="/admin">
              <i className="material-symbols-outlined">dashboard</i>Dashboard
            </Link>
          </li>
          <li className={location.pathname === '/analytics' ? 'active' : ''}>
            <Link to="/adminanalytics">
              <i className="material-symbols-outlined">insights</i>Analytics
            </Link>
          </li>
          <li className={location.pathname === '/clientmanagement' ? 'active' : ''}>
            <Link to="/clients">
              <i className="material-symbols-outlined">people</i>Clients
            </Link>
          </li>
          <li className={location.pathname === '/assignleads' ? 'active' : ''}>
            <Link to="/leads">
              <i className="material-symbols-outlined">person_add</i>Leads
            </Link>
          </li>
          <li className={location.pathname === '/leadsbycaller' ? 'active' : ''}>
            <Link to="/callers">
              <i className="material-symbols-outlined">call</i>Callers
            </Link>
          </li>
          <li className={location.pathname === '/documents' ? 'active' : ''}>
            <Link to="/documentstimeline">
              <i className="material-symbols-outlined">folder</i>Documents
            </Link>
          </li>
          <li className={location.pathname === '/referandearn' ? 'active' : ''}>
            <Link to="/employeinformation">
              <i className="material-symbols-outlined">group</i>Employee's Info
            </Link>
          </li>
          <li className={location.pathname === '/referandearn' ? 'active' : ''}>
            <Link to="/empactivity">
              <i className="material-symbols-outlined">work</i>Emp Activity
            </Link>
          </li>
          <li className={location.pathname === '/invoice' ? 'active' : ''}>
            <Link to="/invoice">
              <i className="material-symbols-outlined">receipt_long</i>Invoice
            </Link>
          </li>
          <li className={location.pathname === '/profile' ? 'active' : ''}>
            <Link to="/adminprofile">
              <i className="material-symbols-outlined">person</i>My Profile
            </Link>
          </li>
       
          <li className='d-md-none'>
            <Link to="/referandearn" onClick={handleLogout}>
              <i className="material-symbols-outlined">logout</i>Logout
            </Link>
          </li>
     
        </ul>
      </aside>
 
      {/* Navbar */}
      <div id="as-navbar-wrapper">
        <nav className="as-navbar as-navbar-inverse">
          <div className="as-container-fluid">
            <div className="as-navbar-header">
              <div className='row'>
                <div>
                  <a href="#" className="as-navbar-brand d-md-none" id="sidebar-toggle" onClick={handleToggle}>
                    <i className="fa fa-bars" style={{ color: 'black' }}></i>
                  </a>
                </div>
               
              </div>
            </div>
            <div className='row'>
              <div className={isToggled ? 'd-none' : ''}>
                <div className="as-paste-button">
                  <button className="as-button">Welcome {username} &nbsp; ▼</button>
                  <div className="as-dropdown-content">
                    <Link to='/settings'>
                      <a id="top" href="#">Settings</a>
                    </Link>
                    <Link to='/helpcenter'>
                      <a id="middle" href="#">Help Center</a>
                    </Link>
                  </div>
                </div>
              </div>
 
              <button className="as-Btnn d-none d-md-block" onClick={handleLogout}>
                <div className="as-sign">
                  <svg viewBox="0 0 512 512">
                    <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                  </svg>
                </div>
                <div className="as-text">Logout</div>
              </button>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
 
export default Adminsidebar;