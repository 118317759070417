import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import './Testimonial.css'; // Import custom CSS

const Testimonial = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleIndicatorClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <section className="testimonial">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-none d-lg-block">
            <ol className="carousel-indicators tabs">
              <li onClick={() => handleIndicatorClick(0)} className={activeIndex === 0 ? "active" : ""}>
                <figure>
                  <img src="https://livedemo00.template-help.com/wt_62267_v8/prod-20823-one-service/images/testimonials-01-179x179.png" className="img-fluid" alt="Client 1" />
                </figure>
              </li>
              <li onClick={() => handleIndicatorClick(1)} className={activeIndex === 1 ? "active" : ""}>
                <figure>
                  <img src="https://livedemo00.template-help.com/wt_62267_v8/prod-20823-one-service/images/testimonials-02-306x306.png" className="img-fluid" alt="Client 2" />
                </figure>
              </li>
              <li onClick={() => handleIndicatorClick(2)} className={activeIndex === 2 ? "active" : ""}>
                <figure>
                  <img src="https://livedemo00.template-help.com/wt_62267_v8/prod-20823-one-service/images/testimonials-03-179x179.png" className="img-fluid" alt="Client 3" />
                </figure>
              </li>
            </ol>
          </div>
          <div className="col-lg-6 d-flex justify-content-center align-items-center">
            <div id="carouselExampleIndicators" data-interval="false" className="carousel slide" data-ride="carousel">
              <h3>WHAT OUR CLIENTS SAY</h3>
              <h1>TESTIMONIALS</h1>
              <div className="carousel-inner">
                <div className={`carousel-item ${activeIndex === 0 ? "active" : ""}`}>
                  <div className="quote-wrapper">
                    <p>"Clasp Tax Inc. made my tax filing process so simple and stress-free. They really understand the intricacies of tax regulations."</p>
                    <h3>John Doe</h3>
                  </div>
                </div>
                <div className={`carousel-item ${activeIndex === 1 ? "active" : ""}`}>
                  <div className="quote-wrapper">
                    <p>"I've never felt so confident with my tax returns. Clasp Tax Inc. offers top-notch services!"</p>
                    <h3>Jane Smith</h3>
                  </div>
                </div>
                <div className={`carousel-item ${activeIndex === 2 ? "active" : ""}`}>
                  <div className="quote-wrapper">
                    <p>"The team at Clasp Tax Inc. provided me with excellent advice and saved me a lot of money on my taxes!"</p>
                    <h3>Robert Johnson</h3>
                  </div>
                </div>
              </div>
              <ol className="carousel-indicators indicators">
                <li onClick={() => handleIndicatorClick(0)} className={activeIndex === 0 ? "active" : ""}></li>
                <li onClick={() => handleIndicatorClick(1)} className={activeIndex === 1 ? "active" : ""}></li>
                <li onClick={() => handleIndicatorClick(2)} className={activeIndex === 2 ? "active" : ""}></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
