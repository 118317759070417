import React, { useState } from "react";
import Axios from 'axios';
import { useNavigate, Link } from "react-router-dom";
import gif from '../images/img6 (1).gif';
import { FiArrowLeft } from "react-icons/fi"; // New back arrow icon
import Cookies from 'js-cookie'; // For handling cookies
import './loginn.css';
import AlertMessage from '../../components/AlertMessage'; // AlertMessage component
 
export const Loginn = () => {
    const [identifier, setIdentifier] = useState(''); // Changed from loginusername
    const [loginpassword, setLoginPassword] = useState(''); // Keeping loginpassword
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertSeverity, setAlertSeverity] = useState('error');
    const [alertMessage, setAlertMessage] = useState('');
    const [loginstatus, setLoginStatus] = useState('');
    const navigate = useNavigate();
 
    const handleAlertClose = () => {
        setAlertOpen(false);
    };
 
    const handleLogin = async (e) => {
        e.preventDefault(); // Prevent default form submission
        if (!identifier || !loginpassword) {
            setAlertSeverity('warning');
            setAlertMessage('Please fill in all fields.');
            setAlertOpen(true);
            return;
        }
        setLoading(true);
        try {
            const response = await Axios.post('https://api.clasptax.com/loginn', {
                identifier,
                password: loginpassword
            },);
 
            if (response.data.success) {
                const role = response.data.role;
                setAlertSeverity('success');
                setAlertMessage(`Welcome, ${role}!`);
                setAlertOpen(true);
                localStorage.setItem('role', role.toLowerCase());
 
                setTimeout(() => {
                    Cookies.set('token', response.data.token);
                    navigate(`/${role.toLowerCase()}`, { state: { identifier, role } });
                }, 1000);
            } else {
                setAlertSeverity('error');
                setAlertMessage('Invalid credentials');
                setAlertOpen(true);
            }
        } catch (error) {
            console.error('Error during login:', error);
            setAlertSeverity('error');
            setAlertMessage('An error occurred during login. Please try again later.');
            setAlertOpen(true);
        } finally {
            setLoading(false);
        }
    };
 
    return (
        <div className="login-container">
            <div className="login-card">
                <div className="login-left">
                    <img src={gif} alt="Login Visual" className="login-image" />
                </div>
                <div className="login-right">
                    <div className="login-header">
                        <Link to="/" className="login-back-link">
                            <FiArrowLeft className="login-back-icon" />
                        </Link>
                        <h2>Welcome To ClaspTax</h2>
                        <h1>Login</h1>
                        <h5 className="login-status">{loginstatus}</h5>
                    </div>
                    <form method="post" onSubmit={handleLogin}>
                        <div className="login-input-group">
                            <input
                                type="text"
                                required
                                value={identifier} // Updated field name
                                onChange={(e) => setIdentifier(e.target.value)}
                            />
                            <label>Email Address or Mobile Number</label>
                        </div>
                        <div className="login-input-group">
                            <input
                                type="password"
                                required
                                value={loginpassword}
                                onChange={(e) => setLoginPassword(e.target.value)}
                            />
                            <label>Password</label>
                        </div>
                        <Link to='/forgetpassword' className="login-forgot-password">Forgot password?</Link>
                        <button type="submit" className="login-button">
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                        <div className="login-signup-link">
                            Not a member? <Link to="/Signup">Signup</Link>
                        </div>
                    </form>
                </div>
            </div>
 
            {/* Alert message */}
            <AlertMessage
                open={alertOpen}
                severity={alertSeverity}
                message={alertMessage}
                handleClose={handleAlertClose}
            />
        </div>
    );
};
 