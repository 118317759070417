import React, { useEffect } from 'react';
import { FaUser, FaBriefcase, FaDollarSign, FaFileAlt, FaChartLine, FaHandshake } from 'react-icons/fa';
import './Se1.css';
import { Link } from 'react-router-dom';
import Nav from '../components/Nav';
import { Upperbox } from '../components/Upperbox';
import Chatbot from '../../components/Chatbot';
import WhatsAppChatButton from '../../components/Whatsapp';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { Footer } from '../components/footer';
import AOS from 'aos'; // Import AOS for animations
import 'aos/dist/aos.css';
import Scrolltop from '../../components/Scrolltop';
import Cosection from '../components/Cosections';
const Se1 = () => {
    useEffect(() => {
        AOS.init({ duration: 1000 }); // Initialize AOS with duration of 1000ms
    }, []);

    const services = {
        business: [
            { title: 'Business Incorporation', icon: <FaBriefcase />, description: 'Your gateway to seamless business formation in the USA. Expert guidance, affordable fees, and a streamlined process. Schedule your FREE consultation today!', link: '/business' },
            { title: 'Business Tax Returns', icon: <FaDollarSign />, description: 'Expert tax filing for businesses and individuals. Comprehensive services, personalized approach, and commitment to compliance.', link: '/businessreturns' },
            { title: 'Payroll Processing Services', icon: <FaFileAlt />, description: 'Streamline your payroll, ensure tax compliance, and save time. Experienced professionals handle all aspects, allowing you to focus on your business.', link: '/payroll' },
            { title: 'Accounting And Book Keeping Services', icon: <FaChartLine />, description: 'Your complete bookkeeping and accounting partner. Streamline operations, make informed decisions, and succeed with our dedicated financial expertise.', link: '/accounting' },
        ],
    };

    const ServiceCard = ({ title, description, icon, link }) => {
        return (
            <Link to={link} className='sefile-2' data-aos="fade-up"> {/* Wrap the card in a Link */}
                <div className="sefile1">
                    <div className="card-content">
                        <div className="icon-iui">{icon}</div>
                        <h2 className="card-icon-texttt">{title}</h2>
                        <p className="per-ppp">{description}</p>
                        <h6 className='readmore-t'>Read more <MdKeyboardDoubleArrowRight /></h6>
                    </div>
                </div>
            </Link>
        );
    };

    return (
        <div>
            <Upperbox />
            <Nav />
            <div className="sefile-poster">
                <h1 className="sefile-text">Business Services</h1>
                <div className="line-animation"></div>
            </div>
            <div className="sefile-2">
                {services.business.map((service, index) => (
                    <ServiceCard 
                        key={index} 
                        title={service.title} 
                        description={service.description} 
                        icon={service.icon} 
                        link={service.link} // Pass the link prop here
                    />
                ))}
            </div>
            <Cosection/>
            <Footer />
            <Chatbot />
            <Scrolltop/>
            <WhatsAppChatButton />
        </div>
    );
};

export default Se1;
