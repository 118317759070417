import React, { useState }  from 'react';
import './upperbox.css'
import { Link } from 'react-router-dom';

export const Upperbox = () => {
    const [email, setEmail] = useState('info@clasptax.com');

  const handleEmailClick = () => {
    window.location.href = `gmailto:${email}`;
  };
  return (
    <div className='upperbox d-flex  justify-content-between'>
    <div className='d-flex'>
        <p>Call Us +1 (972)-220-9783</p>
        <b style={{marginTop:"10px"}}>|</b>
        <p onClick={handleEmailClick}>{email}</p>
    </div>
    <div className='d-flex'>
    <Link to='/Signup'>
    <p className='d-none d-md-block'> Register</p>
    </Link>
      <b className='d-none d-md-block' style={{marginTop:"10px"}}>|</b>
        <Link to='/login'>
        <p className='d-none d-md-block'>Login</p>
        </Link>   
    </div>
    </div>
  )
}
