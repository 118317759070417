import React, { useEffect, useState } from 'react';
import './empprofile.css';
import Empsidebar from '../components/empsidebar';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
 
const EmpProfile = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState('');
  const [data, setData] = useState(null);
  const [username, setUsername] = useState('');
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    }
  }, []);
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.email || decoded.phoneNumber);
      setRole(decoded.role);
      if (decoded.role !== 'employee') {
        navigate('/login');
      }
    }
  }, []);
 
  useEffect(() => {
    axios
      .get(`https://api.clasptax.com/get/details/${username}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error('Error fetching user data:', error);
      });
  }, [username]);
 
  console.log(username, data, 'Data');
 
  return (
    <div>
      <Empsidebar />
      <div style={{marginLeft:"300px"}}>
      {data?.map((row, index) => (
        <div key={index}>
          Email:<li>{row.email}</li>
          PhoneNumber:<li>{row.phoneNumber}</li>
        </div>
      ))}
        </div>
    </div>
  );
};
 
export default EmpProfile;