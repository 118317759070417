// import React, { useState, useEffect, useContext } from 'react';
// import { useNavigate } from 'react-router-dom';
// import Adminsidebar  from '../components/Adminsidebar';
// import './clients.css';
// import { YearContext } from '../context/usecontext';
// // import { jsPDF } from 'jspdf';
// import 'jspdf-autotable';
// import * as XLSX from 'xlsx';
// import { FaEdit, FaTrashAlt, FaDownload, FaEye } from 'react-icons/fa';

// const Clients = () => {
//     const navigate = useNavigate();
//     const { selectedyear } = useContext(YearContext);

//     const [clientDocuments, setClientDocuments] = useState([]);
//     const [filters, setFilters] = useState({
//         dateFrom: '',
//         dateTo: '',
//         clientName: '',
//         status: ''
//     });
//     const [itemsPerPage, setItemsPerPage] = useState(20);
//     const [currentPage, setCurrentPage] = useState(1);
//     const [selectedRows, setSelectedRows] = useState([]);

//     useEffect(() => {
//         // Dummy data for table
//         const dummyData = [
//             { id: 1, client_id: 'C001', client_name: 'John Doe', email: 'john.doe@example.com', contact: '1234567890', alternate_contact: '0987654321', inleads: 'Yes', assigned_to: 'Jane Smith', assigned_at: '2024-08-01T12:00:00Z', registered_at: '2024-08-01T12:00:00Z', status: 'completed', add_comment: 'Submitted' },
//             { id: 2, client_id: 'C002', client_name: 'Alice Brown', email: 'alice.brown@example.com', contact: '2345678901', alternate_contact: '1234567890', inleads: 'No', assigned_to: 'Bob White', assigned_at: '2024-08-02T12:00:00Z', registered_at: '2024-08-02T12:00:00Z', status: 'pending', add_comment: 'Pending review' },
//             { id: 3, client_id: 'C003', client_name: 'Emily Green', email: 'emily.green@example.com', contact: '3456789012', alternate_contact: '2345678901', inleads: 'Yes', assigned_to: 'Carol Adams', assigned_at: '2024-08-03T12:00:00Z', registered_at: '2024-08-03T12:00:00Z', status: 'declined', add_comment: 'Rejected' },
//             // More dummy data
//         ];
//         setClientDocuments(dummyData);
//     }, []);

//     const formatDateTime = (dateTime) => new Date(dateTime).toLocaleString();

//     const handleFilterChange = (e) => {
//         setFilters({ ...filters, [e.target.name]: e.target.value });
//     };

//     const filterDocuments = () => {
//         return clientDocuments.filter((doc) => {
//             const { dateFrom, dateTo, clientName, status } = filters;
//             const documentDate = new Date(doc.registered_at);
//             const fromDate = dateFrom ? new Date(dateFrom) : new Date(0);
//             const toDate = dateTo ? new Date(dateTo) : new Date();
//             return (
//                 (dateFrom === '' || documentDate >= fromDate) &&
//                 (dateTo === '' || documentDate <= toDate) &&
//                 (clientName === '' || doc.client_name.toLowerCase().includes(clientName.toLowerCase())) &&
//                 (status === '' || doc.status === status)
//             );
//         });
//     };

//     const handleSelectAll = () => {
//         const allIds = filterDocuments().map(doc => doc.id);
//         setSelectedRows(selectedRows.length === allIds.length ? [] : allIds);
//     };

//     const handleSelectRow = (id) => {
//         setSelectedRows((prevSelectedRows) =>
//             prevSelectedRows.includes(id) ? prevSelectedRows.filter(rowId => rowId !== id) : [...prevSelectedRows, id]
//         );
//     };

//     const handleAssignTo = () => {
//         if (selectedRows.length > 0) {
//             const selectedDocs = clientDocuments.filter(doc => selectedRows.includes(doc.id));
//             console.log('Assigning to:', selectedDocs);
//         }
//     };

//     // const downloadTableAsPDF = () => {
//     //     const doc = new jsPDF();
//     //     doc.autoTable({
//     //         head: [['Client ID', 'Client Name', 'Email', 'Contact', 'Status', 'Comments']],
//     //         body: filterDocuments().map(doc => [
//     //             doc.client_id, doc.client_name, doc.email, doc.contact, doc.status, doc.add_comment
//     //         ]),
//     //     });
//     //     doc.save('documents.pdf');
//     // };

//     const downloadTableAsExcel = () => {
//         const ws = XLSX.utils.json_to_sheet(filterDocuments().map(doc => ({
//             'Client ID': doc.client_id,
//             'Client Name': doc.client_name,
//             'Email': doc.email,
//             'Contact': doc.contact,
//             'Status': doc.status,
//             'Comments': doc.add_comment,
//         })));
//         const wb = XLSX.utils.book_new();
//         XLSX.utils.book_append_sheet(wb, ws, 'Documents');
//         XLSX.writeFile(wb, 'documents.xlsx');
//     };

//     const handleView = (id) => {
//         navigate('/taxorganizer', { state: { clientId: id } });
//     };

//     const currentData = filterDocuments().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
//     const totalPages = Math.ceil(filterDocuments().length / itemsPerPage);

//     const handlePageChange = (direction) => {
//         setCurrentPage((prevPage) =>
//             direction === 'next' ? Math.min(prevPage + 1, totalPages) : Math.max(prevPage - 1, 1)
//         );
//     };

//     return (
//         <div>
//             <Adminsidebar />
//             <div className="container" style={{ marginLeft: "300px", width: "700px" }}>
//                 <div className="main-content">
//                     <div className="mt-4">
//                         <div className="row mb-3">
//                             <div className="col-sm-3">
//                                 <input type="date" className="form-control form-control-sm" name="dateFrom" value={filters.dateFrom} onChange={handleFilterChange} />
//                             </div>
//                             <div className="col-sm-3">
//                                 <input type="date" className="form-control form-control-sm" name="dateTo" value={filters.dateTo} onChange={handleFilterChange} />
//                             </div>
//                             <div className="col-sm-3">
//                                 <input type="text" className="form-control form-control-sm" placeholder="Client Name" name="clientName" value={filters.clientName} onChange={handleFilterChange} />
//                             </div>
//                             <div className="col-sm-3">
//                                 <select className="form-control form-control-sm" name="status" value={filters.status} onChange={handleFilterChange}>
//                                     <option value="">All Statuses</option>
//                                     <option value="completed">Completed</option>
//                                     <option value="pending">Pending</option>
//                                     <option value="declined">Declined</option>
//                                 </select>
//                             </div>
//                         </div>
//                         <div className="table-actions mb-3">
//                             <button className="btn btn-primary" onClick={handleAssignTo} disabled={selectedRows.length === 0}>Assign To</button>
//                             {/* <button className="btn btn-secondary" onClick={downloadTableAsPDF}>Export PDF</button> */}
//                             <button className="btn btn-secondary" onClick={downloadTableAsExcel}>Export Excel</button>
//                             {selectedRows.length > 0 && <span>{`${selectedRows.length} selected of ${clientDocuments.length} total rows`}</span>}
//                         </div>
//                         <div className="table-container">
//                             <table className="table">
//                                 <thead>
//                                     <tr>
//                                         <th>
//                                             <input type="checkbox" checked={selectedRows.length === currentData.length} onChange={handleSelectAll} />
//                                         </th>
//                                         <th>Client ID</th>
//                                         <th>Client Name</th>
//                                         <th>Email</th>
//                                         <th>Contact</th>
//                                         <th>Alternate Contact</th>
//                                         <th>In Leads?</th>
//                                         <th>Assigned To</th>
//                                         <th>Assigned At</th>
//                                         <th>Registered At</th>
//                                         <th>Status</th>
//                                         <th>Comments</th>
//                                         <th>Actions</th>
//                                     </tr>
//                                 </thead>
//                                 <tbody>
//                                     {currentData.map((doc) => (
//                                         <tr key={doc.id}>
//                                             <td>
//                                                 <input type="checkbox" checked={selectedRows.includes(doc.id)} onChange={() => handleSelectRow(doc.id)} />
//                                             </td>
//                                             <td>{doc.client_id}</td>
//                                             <td>{doc.client_name}</td>
//                                             <td>{doc.email}</td>
//                                             <td>{doc.contact}</td>
//                                             <td>{doc.alternate_contact}</td>
//                                             <td>{doc.inleads}</td>
//                                             <td>{doc.assigned_to}</td>
//                                             <td>{formatDateTime(doc.assigned_at)}</td>
//                                             <td>{formatDateTime(doc.registered_at)}</td>
//                                             <td>{doc.status}</td>
//                                             <td>{doc.add_comment}</td>
//                                             <td>
//                                                 <button className="btn btn-info btn-sm" onClick={() => handleView(doc.id)}><FaEye /></button>
//                                                 <button className="btn btn-warning btn-sm"><FaEdit /></button>
//                                                 <button className="btn btn-danger btn-sm"><FaTrashAlt /></button>
//                                                 <button className="btn btn-secondary btn-sm"><FaDownload /></button>
//                                             </td>
//                                         </tr>
//                                     ))}
//                                 </tbody>
//                             </table>
//                         </div>
//                         <div className="pagination">
//                             <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>Previous</button>
//                             <span>Page {currentPage} of {totalPages}</span>
//                             <button onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>Next</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     );
// };

// export default Clients;



import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Adminsidebar from '../components/Adminsidebar';
import './clients.css';
// import { YearContext } from '../context/usecontext';
// import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { FaEdit, FaTrashAlt, FaDownload, FaEye } from 'react-icons/fa';
import axios from "axios";
 
 
const Clients = () => {
    const navigate = useNavigate();
    // const { selectedyear } = useContext(YearContext);
 
    const [clientDocuments, setClientDocuments] = useState([]);
    const [filters, setFilters] = useState({
        dateFrom: '',
        dateTo: '',
        clientName: '',
        status: ''
    });
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
 
    const [clientData,setClientData] = useState([])
    const[leadData,setLeadData] = useState([]);
 
    const fetchClientData = async () => {
        try {
            const response = await axios.get("https://api.clasptax.com/get/clientdata")
            if (response.data.success) {
               
                setClientData(response.data.result);
                setLeadData(response.data.leadresult);
                console.log(response.data.result)
               
            }
        } catch (error) {
            console.log(error)
        }
    }
 
    useEffect(() => {
        
        fetchClientData();
 
    }, []);
 
    const getLeadInfo = (email) => {
        const lead = leadData.find(lead => lead.email === email);
        if (lead) {
            return {
                inLeads: "YES",
                // assignedTo: lead.assigned_to,
                assignedAt:lead.assigned_date,
 
            };
        }
        return {
            inLeads: "NO",
            // assignedTo: "Unassigned",
            assignedAt:"Unassigned date",
        };
    };
 
    const [selectedRows1, setSelectedRows1] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
 
    const handleCheckboxChangeid = (id) => {
        const isSelected = selectedRows1.includes(id);
        const updatedSelection = isSelected
            ? selectedRows1.filter((rowId) => rowId !== id)
            : [...selectedRows1, id];
 
        setCheckAll(updatedSelection.length === clientData.length);
        setSelectedRows1(updatedSelection);
    };
 
    const handleCheckAll = () => {
        if (checkAll) {
            setSelectedRows1([]);
        } else {
            setSelectedRows1(clientData.map((record) => record.id));
        }
        setCheckAll(!checkAll);
    };
 
    const [employeeData, setEmployeeData] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState("");
    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const response = await axios.get('https://api.clasptax.com/api/getemployeess');
                setEmployeeData(response.data);  // Assuming response.data is an array of employee objects
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };
        fetchEmployeeData();
    }, []);
 
    const  handleEmployeeChange =(employee) =>{
        setSelectedEmployees(employee.id)
    }
 
    const handleonExport = () => {
        // Create a new worksheet from the client data
        const worksheetData = clientData.map((doc) => {
            const leadInfo = getLeadInfo(doc.email);
            return {
                "Client ID": doc.userID,
                "Client Name": doc.userName,
                "Email": doc.email,
                "Contact": doc.phoneNumber,
                "Alternate Contact": doc.alternateContact,
                "In Leads": leadInfo.inLeads,
                "Assigned To": leadInfo.assignedTo,
                "Assigned At": leadInfo.assignedAt || "-",
                "Registered At": doc.registeredAt,
                "Status": doc.status,
                "Comments": doc.comments
            };
        });
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Client Data");
        XLSX.writeFile(workbook, "ClientData.xlsx");
    };
 
     
 


    const  handleAssignEmployee = async () =>{
        try {
       const response = await axios.put("https://api.clasptax.com/updateclients",{selectedRows1,selectedEmployees});
       if(response.data.success){
        alert("The values updated successfully")
       }
     
        }
        catch(error){
            console.log(error)
        }
     
    }
    // Assign selected employees to selected clients
    const handleOkClick = () => {
        if (selectedRows1.length === 0 || selectedEmployees === "") {
            alert('Please select at least one client and one employee.');
            return;
        }
      handleAssignEmployee();
    };
 
 
 
 
 
 
 
 
    const formatDateTime = (dateTime) => new Date(dateTime).toLocaleString();
 
    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };
 
    const filterDocuments = () => {
        return clientDocuments.filter((doc) => {
            const { dateFrom, dateTo, clientName, status } = filters;
            const documentDate = new Date(doc.registered_at);
            const fromDate = dateFrom ? new Date(dateFrom) : new Date(0);
            const toDate = dateTo ? new Date(dateTo) : new Date();
            return (
                (dateFrom === '' || documentDate >= fromDate) &&
                (dateTo === '' || documentDate <= toDate) &&
                (clientName === '' || doc.client_name.toLowerCase().includes(clientName.toLowerCase())) &&
                (status === '' || doc.status === status)
            );
        });
    };
 
    const handleSelectAll = () => {
        const allIds = filterDocuments().map(doc => doc.id);
        setSelectedRows(selectedRows.length === allIds.length ? [] : allIds);
    };
 
    const handleSelectRow = (id) => {
        setSelectedRows((prevSelectedRows) =>
            prevSelectedRows.includes(id) ? prevSelectedRows.filter(rowId => rowId !== id) : [...prevSelectedRows, id]
        );
    };
 
    const handleAssignTo = () => {
        if (selectedRows.length > 0) {
            const selectedDocs = clientDocuments.filter(doc => selectedRows.includes(doc.id));
            console.log('Assigning to:', selectedDocs);
        }
    };
 
    // const downloadTableAsPDF = () => {
    //     const doc = new jsPDF();
    //     doc.autoTable({
    //         head: [['Client ID', 'Client Name', 'Email', 'Contact', 'Status', 'Comments']],
    //         body: filterDocuments().map(doc => [
    //             doc.client_id, doc.client_name, doc.email, doc.contact, doc.status, doc.add_comment
    //         ]),
    //     });
    //     doc.save('documents.pdf');
    // };
 
    const downloadTableAsExcel = () => {
        const ws = XLSX.utils.json_to_sheet(filterDocuments().map(doc => ({
            'Client ID': doc.client_id,
            'Client Name': doc.client_name,
            'Email': doc.email,
            'Contact': doc.contact,
            'Status': doc.status,
            'Comments': doc.add_comment,
        })));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Documents');
        XLSX.writeFile(wb, 'documents.xlsx');
    };
 
    const handleView = (id) => {
        navigate('/taxorganizer', { state: { clientId: id } });
    };
 
    const currentData = filterDocuments().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(filterDocuments().length / itemsPerPage);
 
    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) =>
            direction === 'next' ? Math.min(prevPage + 1, totalPages) : Math.max(prevPage - 1, 1)
        );
    };
 
    return (
        <div>
            <Adminsidebar />
            <div className="container" style={{ marginLeft: "300px", width: "700px" }}>
                <div className="main-content">
                    <div className="mt-4">
                        <div className="row mb-3">
                            <div className="col-sm-3">
                                <input type="date" className="form-control form-control-sm" name="dateFrom" value={filters.dateFrom} onChange={handleFilterChange} />
                            </div>
                            <div className="col-sm-3">
                                <input type="date" className="form-control form-control-sm" name="dateTo" value={filters.dateTo} onChange={handleFilterChange} />
                            </div>
                            <div className="col-sm-3">
                                <input type="text" className="form-control form-control-sm" placeholder="Client Name" name="clientName" value={filters.clientName} onChange={handleFilterChange} />
                            </div>
                            <div className="col-sm-3">
                                <select className="form-control form-control-sm" name="status" value={filters.status} onChange={handleFilterChange}>
                                    <option value="">All Statuses</option>
                                    <option value="completed">Completed</option>
                                    <option value="pending">Pending</option>
                                    <option value="declined">Declined</option>
                                </select>
                            </div>
                        </div>
                        <div className='my-3 d-flex gap-2'>
                            <div className="dropdown">
                            <button
                                className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                                disabled={selectedRows1.length === 0}
                            >
                                Assign To ({selectedRows1.length})
                            </button>
                         
                           
                            <ul className='dropdown-menu'>
                                {employeeData.map((employee, index) => (
                                    <li key={index} className='dropdown-item'>
                                      <div className='d-flex gap-2'>
                                      <div>
                                      <input
                                                type="radio"
                                                value={employee.username}
                                                checked={selectedEmployees === (employee.id)}
                                                onChange={() => handleEmployeeChange(employee)}
                                            />
                                      </div>
                                       <div> {employee.username}</div>
                                      </div>
                                         
                                    </li>
                                ))}
                                   <button onClick={handleOkClick} className="btn btn-primary btn-sm"> Okay</button>
 
                            </ul>
                            </div>
                            <button className="btn btn-secondary" onClick={handleonExport}>Export Excel</button>
                        </div>
                        <div className="table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                    <th>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={checkAll}
                                                onChange={handleCheckAll}
                                            />
                                            Check All
                                        </th>
                                        <th>Client ID</th>
                                        <th>Client Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Alternate Contact</th>
                                        <th>In Leads?</th>
                                        <th>Assigned To</th>
                                        <th>Assigned At</th>
                                        <th>Registered At</th>
                                        <th>Status</th>
                                        <th>Comments</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientData.map((doc) => {
                                        const leadInfo = getLeadInfo(doc.email);
                                        return(
                                        <tr key={doc.id}>
                                            <td>
                                            <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={selectedRows1.includes(doc.id)}
                                                    onChange={() => handleCheckboxChangeid(doc.id)}
                                                />
 
                                            </td>
                                            <td>{doc.client_id}</td>
                                            <td>{doc.client_name}</td>
                                            <td>{doc.email}</td>
                                            <td>{doc.contact}</td>
                                            <td>{doc.alternateContact}</td>
                                         <td>{leadInfo.inLeads}</td>
                                        <td>{doc.assigned_to}</td>
                                        <td>{leadInfo.assignedAt}</td>
                                        <td>{doc.registeredAt}</td>
                                            <td>{doc.status}</td>
                                          <td>{doc.comments}</td>
                                           
                                            <td>
                                                <button className="btn btn-info btn-sm" onClick={() => handleView(doc.id)}><FaEye /></button>
                                                <button className="btn btn-warning btn-sm"><FaEdit /></button>
                                                <button className="btn btn-danger btn-sm"><FaTrashAlt /></button>
                                                <button className="btn btn-secondary btn-sm"><FaDownload /></button>
                                            </td>
                                        </tr>
                                   ) })}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination">
                            <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>Previous</button>
                            <span>Page {currentPage} of {totalPages}</span>
                            <button onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
 
export default Clients;
