import React from 'react';
import './Whatsapp.css'; // Import your CSS
 
const WhatsAppChatButton = ({ phoneNumber, message }) => {
  const encodedMessage = encodeURIComponent(message);
 
  return (
    <div className="whatsapp-chat-button">
      <a
        href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodedMessage}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
          alt="WhatsApp Chat"
          className="whatsapp-icon"
        />
      </a>
    </div>
  );
};
 
export default WhatsAppChatButton;