import React, { useState, useEffect } from 'react';
import  Adminsidebar from '../components/Adminsidebar';
import { ToggleButtonGroup, ToggleButton } from '@mui/material';
import { Card, Typography, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const Adminanalytics = () => {
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = useState('team');

    const handleTabChange = (event, newTab) => {
        setActiveTab(newTab);
    };

    const [data, setData] = useState({
        team: [
            { title: 'Total Employees', value: '150' },
            { title: 'Active Projects', value: '45' },
            { title: 'New Clients', value: '20' }
        ],
        leads: [
            { title: 'Leads Generated', value: '200' },
            { title: 'Leads Converted', value: '80' },
            { title: 'Lead Conversion Rate', value: '40%' }
        ],
        referrals: [
            { title: 'Referrals Received', value: '30' },
            { title: 'Successful Referrals', value: '15' },
            { title: 'Referral Conversion Rate', value: '50%' }
        ]
    });

    useEffect(() => {
        setTimeout(() => setLoading(false), 1000);
    }, []);

    return (
        <div>
            <Adminsidebar />
            <div className="dashboard-container">
                <div className="dashboard-content">
                    <div className="toggle-buttons">
                        <ToggleButtonGroup
                            value={activeTab}
                            exclusive
                            onChange={handleTabChange}
                            aria-label="toggle button group"
                        >
                            <ToggleButton value="team">Team</ToggleButton>
                            <ToggleButton value="leads">Leads</ToggleButton>
                            <ToggleButton value="referrals">Referrals</ToggleButton>
                        </ToggleButtonGroup>
                    </div>

                    {activeTab === 'team' && (
                        <div className="tables-container">
                            <div className="cards-container">
                                {data.team.map((card, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Card className="dashboard-card" style={{ backgroundColor: "#d3d3d3" }}>
                                            <div className="card-content">
                                                <Typography variant="h6">{card.title}</Typography>
                                                <Typography variant="h4">{card.value}</Typography>
                                            </div>
                                        </Card>
                                    </Grid>
                                ))}
                            </div>
                            <TableContainer component={Paper} className="table-container">
                                <Table>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#FFF9C4" }}>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Position</TableCell>
                                            <TableCell>Department</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>John Doe</TableCell>
                                            <TableCell>Tax Consultant</TableCell>
                                            <TableCell>Taxation</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Jane Smith</TableCell>
                                            <TableCell>Financial Advisor</TableCell>
                                            <TableCell>Finance</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Mike Johnson</TableCell>
                                            <TableCell>Accountant</TableCell>
                                            <TableCell>Accounting</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )}

                    {activeTab === 'leads' && (
                        <div className="tables-container">
                            <div className="cards-container">
                                {data.leads.map((card, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Card className="dashboard-card" style={{ backgroundColor: "#d3d3d3" }}>
                                            <div className="card-content">
                                                <Typography variant="h6">{card.title}</Typography>
                                                <Typography variant="h4">{card.value}</Typography>
                                            </div>
                                        </Card>
                                    </Grid>
                                ))}
                            </div>
                            <TableContainer component={Paper} className="table-container">
                                <Table>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#FFF9C4" }}>
                                            <TableCell>Lead Name</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Contact Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Company A</TableCell>
                                            <TableCell>Converted</TableCell>
                                            <TableCell>01/10/2024</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Company B</TableCell>
                                            <TableCell>Pending</TableCell>
                                            <TableCell>02/15/2024</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Company C</TableCell>
                                            <TableCell>Lost</TableCell>
                                            <TableCell>03/22/2024</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )}

                    {activeTab === 'referrals' && (
                        <div className="tables-container">
                            <div className="cards-container">
                                {data.referrals.map((card, index) => (
                                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                        <Card className="dashboard-card" style={{ backgroundColor: "#d3d3d3" }}>
                                            <div className="card-content">
                                                <Typography variant="h6">{card.title}</Typography>
                                                <Typography variant="h4">{card.value}</Typography>
                                            </div>
                                        </Card>
                                    </Grid>
                                ))}
                            </div>
                            <TableContainer component={Paper} className="table-container">
                                <Table>
                                    <TableHead>
                                        <TableRow style={{ backgroundColor: "#FFF9C4" }}>
                                            <TableCell>Referral Name</TableCell>
                                            <TableCell>Status</TableCell>
                                            <TableCell>Referral Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Client X</TableCell>
                                            <TableCell>Converted</TableCell>
                                            <TableCell>02/05/2024</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Client Y</TableCell>
                                            <TableCell>Pending</TableCell>
                                            <TableCell>03/12/2024</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Client Z</TableCell>
                                            <TableCell>Lost</TableCell>
                                            <TableCell>04/19/2024</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Adminanalytics;