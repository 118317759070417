import React from 'react';
import t from "../images/t.jpg";
import Scrolltop from '../../components/Scrolltop';
import t2 from "../images/t2.jpg";
import "./aboutus.css";
import { Footer } from '../components/footer';
import Chatbot from '../../components/Chatbot';
import { Upperbox} from '../components/Upperbox';
import WhatsAppChatButton from '../../components/Whatsapp';
import Cosection from '../components/Cosections';
import Nav from "../components/Nav";
const Aboutus = () => {
  return (
    <div>
     <Upperbox/>
     <Nav/>
    <div className='background'>
        <p className="sefile-text">ABOUT US</p>
        <div className="line-animation"></div>
      </div>
      <div className='aboutus-container'>
  <div className='aboutus-content'>
    <div className='aboutus-image'>
      <img src={t} alt='About Us' className='img-aboutus' />
    </div>
    <div className='aboutus-text'>
      <h1 className='ab-u'>Our comprehensive tax services include:</h1>
      <p>
      At Clasp Tax LLC, our team of experienced tax professionals is committed to providing comprehensive
and personalized tax services to individuals and businesses. We understand that tax preparation can be a complex and
time-consuming process, which is why we are here to take the burden off you. We will handle all aspects of your tax return,
from gathering your information and documents to filing your return with the IRS.

      </p>
    </div>
  </div>
</div>

<div className='aboutus-containere'>
  <div className='aboutus-content'>
    
    <div className='aboutus-textt'>
      <h1 className='ab-uo'>We also offer a variety of specialized tax services, such as:</h1>
      <p>
      • Tax planning 
• Tax saving Strategy for upcoming years
• Bookkeeping & Accounting for your businesses
• Payroll processing and reports preparation
• International tax services
• Estate and gift tax planning


Our team of tax professionals is up-to-date on the latest tax laws and regulations, so you can be confident that your tax
return is accurate and compliant. We are also committed to providing our clients with the highest level of customer service.
We will work closely with you to understand your individual needs and goals, and we will always be available to answer your questions.

Contact Clasp Tax today to schedule a consultation with one of our experienced tax professionals. We will be happy to
discuss your tax situation and answer any questions you have

      </p>
    </div>
    <div className='aboutus-image'>
      <img src={t2} alt='About Us' className='img-aboutus' />
    </div>
  </div>
</div>

  <Cosection/>
    <Chatbot/>
    <Scrolltop/>
    <WhatsAppChatButton/>
    <Footer/>
    </div>
  )
}

export default Aboutus
