import React, { useState, useEffect } from 'react';
import './invoice.css'; // CSS for styling
import  Adminsidebar  from '../components/Adminsidebar'; // Import Sidebar component
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import axios from 'axios';

export const Invoice = () => {
  const [searchInput, setSearchInput] = useState('');
  const [clientData, setClientData] = useState({});
  const [invoiceData, setInvoiceData] = useState({
      userName: '',
      phoneNumber: '',
      email: '',
      invoiceDate: '',
      services: '',
      ssn: '',
      callerName: '',
      price: '',
      discount: '',
      referral: '',
      totalAmount:'',
      totalBill: ''
      
  });
  const [services, setServices] = useState([]);
  const [service, setService] = useState('');
  const [price, setPrice] = useState('');
  const [state, setState] = useState('');
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState('');
  const [referralBonus, setReferralBonus] = useState('');
  const [totalBill, setTotalBill] = useState(0);
  const [isPreview, setIsPreview] = useState(false);

  // New inputs
  const [invoiceDate, setInvoiceDate] = useState(new Date()); // Initialize with current date
  const [clientName, setClientName] = useState('');
  const [phoneNumber, setphoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [callerName, setCallerName] = useState('');
  const [ssn, setSSN] = useState('');

  const validStateCodes = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID',
    'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS',
    'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK',
    'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV',
    'WI', 'WY'
  ];

  const validateSSN = (ssn) => {
    const ssnRegex = /^[1-8]\d{8}$/;
    return ssnRegex.test(ssn);
  };

  const validateMobileNumber = (number) => {
    const mobileRegex = /^\d{10}$/;
    return mobileRegex.test(number);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const addService = () => {
    const { services: selectedService, price } = invoiceData; // Get service and price from invoiceData
  
    if (!selectedService || !price || price <= 0) {
      alert('Please provide valid service and price greater than 0.');
      return;
    }
  
    if (selectedService === 'State Return' && (!state || !validStateCodes.includes(state))) {
      alert('Please enter a valid state code in capitals (e.g., CA, NY).');
      return;
    }
  
    const newService = { service: selectedService, price: parseInt(price, 10), state };
  
    // Update services list and total amount
    setServices([...services, newService]); // services is the state that holds the list of services
    setTotalAmount(totalAmount + newService.price);
  
    // Clear input fields after adding
    setInvoiceData({
      ...invoiceData,
      services: '', // Reset the service in invoiceData
      price: '' // Reset the price in invoiceData
    });
    setState(''); // Reset the state field if needed
  };
  
  
  const deleteService = (index) => {
    const removedService = services[index];
    setTotalAmount(totalAmount - removedService.price);
    setServices(services.filter((_, i) => i !== index));
  };

  useEffect(() => {
    // Parse values as numbers, defaulting to 0 if not valid
    const parsedDiscount = parseFloat(discount) || 0;
    const parsedReferralBonus = parseFloat(referralBonus) || 0;
  
    // Validate parsed values
    const validDiscount = Math.max(parsedDiscount, 0); // Ensure discount is not negative
    const validReferralBonus = Math.max(parsedReferralBonus, 0); // Ensure referral bonus is not negative
  
    // Calculate total bill: totalAmount - (discount + referral bonus)
    const calculatedTotalBill = totalAmount - (validDiscount + validReferralBonus);
    setTotalBill(calculatedTotalBill);
    
  }, [totalAmount, discount, referralBonus]);
  
  const handlePreview = () => {
    const { userName, phoneNumber, email, ssn } = invoiceData;  // Destructure needed fields from invoiceData
  
    if (!invoiceDate || !userName || !phoneNumber || !email) {
      alert('Please fill in all required fields.');
      return;
    }
  
    if (!validateMobileNumber(phoneNumber)) {
      alert('Please enter a valid 10-digit mobile number.');
      return;
    }
  
    if (!validateEmail(email)) {
      alert('Please enter a valid email address.');
      return;
    }
  
    if (ssn && !validateSSN(ssn)) {
      alert('Please enter a valid SSN (9 digits, not starting with 9).');
      return;
    }
  
    setIsPreview(true);  // If everything is valid, set the preview state
  };
  
  const handleSearch = async () => {
    try {
      const response = await axios.get(`http://localhost:4005/api/searchClient`, {
        params: { input: searchInput }
      });
      if (response.data) {
        setClientData(response.data);
        setInvoiceData(prevState => ({
          ...prevState,
          userName: response.data.userName,
          phoneNumber: response.data.phoneNumber, // Ensure the correct phone number is being set
          email: response.data.email,
        }));
      } else {
        alert('Client not found');
      }
    } catch (error) {
      console.error('Error fetching client details:', error);
      alert('Error fetching client details. Please try again.');
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Merge the totalBill, discount, and referralBonus into invoiceData before submitting
      const finalInvoiceData = {
        ...invoiceData, // keep other invoice fields
        discount: discount, // Assign discount from state
        referral: referralBonus, // Assign referral bonus from state
        totalBill: totalBill, // Assign calculated total bill from state
        invoiceDate: format(invoiceDate, 'yyyy-MM-dd'), // Format the date correctly
        services // Send the services array directly
      };
  
      // Send the final invoice data to backend
      await axios.post('http://localhost:4005/api/saveInvoice', finalInvoiceData);
  
      alert('Invoice saved successfully');
  
      // Reset form state after successful submission
      setInvoiceData({
        userName: '',
        phoneNumber: '',
        email: '',
        invoiceDate: '',
        services: [],
        ssn: '',
        callerName: '',
        discount: '',
        referral: '',
        totalBill: ''
      });
      setTotalAmount(0);
      setDiscount(0);
      setReferralBonus(0);
      setServices([]);
    } catch (error) {
      console.error('Error saving invoice:', error);
      alert('Error saving invoice. Please try again.');
    }
  };
  
  const handleChange = (e) => {
      const { name, value } = e.target;
      setInvoiceData({
          ...invoiceData,
          [name]: value
      });
  };

  // Handler for date change
  const handleDateChange = (date) => {
    setInvoiceDate(date);
  };

  return (
    <div>
      <Adminsidebar/>
      <div className="invoice-container">
        <h1>Create Invoice</h1>
        <div>
                <input
                    type="text"
                    placeholder="Enter email or phone number"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                />
                <button onClick={handleSearch}>Search</button>
            </div>

        <div className="invoice-form">
          {/* Date and Client details section */}
          <div className="client-details">
            <label htmlFor="invoiceDate">Invoice Date:</label>
            <DatePicker
              selected={invoiceDate}
              onChange={handleDateChange}
              dateFormat="MM/dd/yyyy"
              className="date-picker"
            />
            <input
                        type="text"
                        name="userName"
                        placeholder="User Name"
                        value={invoiceData.userName}
                        onChange={handleChange}
                        required
                    />
           <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Phone Number"
                        value={invoiceData.phoneNumber}
                        onChange={handleChange}
                        required
                    />
                     <input
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={invoiceData.email}
                        onChange={handleChange}
                        required
                    />
            <input
                        type="date"
                        name="invoiceDate"
                        placeholder="Invoice Date"
                        value={invoiceData.invoiceDate}
                        onChange={handleChange}
                        required
                    />
           
           <input
                        type="text"
                        name="callerName"
                        placeholder="Caller Name"
                        value={invoiceData.callerName}
                        onChange={handleChange}
                        required
                    />
              <input
                        type="text"
                        name="ssn"
                        placeholder="SSN"
                        value={invoiceData.ssn}
                        onChange={handleChange}
                        required
                    />
          </div>

          {/* Service selection and display section */}
          <div className="service-section">
            <select
             name="services" 
             value={invoiceData.services}
             onChange={handleChange}
            >
              <option value="">Select Service</option>
              <option value="Federal - 1040">Federal - 1040</option>
              <option value="State Return">State Return</option>
              <option value="ITIN Filing">ITIN Filing</option>
              <option value="ITIN Filing - Non Resident">ITIN Filing - Non Resident</option>
              <option value="Schedule C,E & 1099MISC">Schedule C,E & 1099MISC</option>
              <option value="FBAR Filing/FATCA Filing">FBAR Filing/FATCA Filing</option>
              <option value="State Rental Credit & OSTC Credit Planning">State Rental Credit & OSTC Credit Planning</option>
              <option value="City Return & County Taxes">City Return & County Taxes</option>
              <option value="Stock Transaction (Per Page)">Stock Transaction (Per Page)</option>
              <option value="Amended Return Charges">Amended Return Charges</option>
              <option value="Optimized Tax Planning Charges">Optimized Tax Planning Charges</option>
              <option value="Unlimited Tax Representation For 8 Successive Years">Unlimited Tax Representation For 8 Successive Years</option>
            </select>

            <input
                        type="text"
                        name="price"
                        placeholder="Price"
                        value={invoiceData.price}
                        onChange={handleChange}
                        required
                    />

            {service === 'State Return' && (
              <input
                type="text"
                placeholder="State (e.g., CA)"
                value={state}
                onChange={(e) => setState(e.target.value.toUpperCase())}
                maxLength="2"
              />
            )}

            <button onClick={addService}>Add Service</button>
          </div>

          {services.length > 0 && (
            <div className="service-list">
              <h3>Services Added</h3>
              <ul>
                {services.map((s, index) => (
                  <li key={index}>
                    {s.services} - ${s.price} {s.state && `(${s.state})`}
                    <button onClick={() => deleteService(index)}>Delete</button>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Calculation section */}
          <div className="calculation-section">
  <input
    type="text"
    name="discount"
    placeholder="Discount"
    value={discount}
    onChange={(e) => setDiscount(e.target.value)} // Update discount
  />
  <input
    type="text"
    name="referral"
    placeholder="Referral Bonus"
    value={referralBonus}
    onChange={(e) => setReferralBonus(e.target.value)} // Update referral bonus
  />
  <p>Total Amount: ${totalAmount}</p>
  <p>Total Bill: ${totalBill}</p>
</div>


          {/* Preview and Submit section */}
          <div className="action-buttons">
            <button onClick={handlePreview}>Preview</button>
            {isPreview && (
              <>
                <button onClick={handleSubmit}>Submit</button>
                <div className="invoice-preview">
                  <h3>Invoice Preview</h3>
                  <p><strong>Invoice Date:</strong> {format(invoiceDate, 'MM/dd/yyyy')}</p>
                  <p><strong>Client Name:</strong> {clientName}</p>
                  <p><strong>Mobile Number:</strong> {phoneNumber}</p>
                  <p><strong>Email:</strong> {email}</p>
                  <p><strong>Caller Name:</strong> {callerName || 'N/A'}</p>
                  <p><strong>SSN:</strong> {ssn || 'N/A'}</p>
                  <h4>Services:</h4>
                  <ul>
                    {services.map((s, index) => (
                      <li key={index}>
                        {s.services} - ${s.price} {s.state && `(${s.state})`}
                      </li>
                    ))}
                  </ul>
                  <p><strong>Total Amount:</strong> ${totalAmount}</p>
                  <p><strong>Discount:</strong> ${discount || '0'}</p>
                  <p><strong>Referral Bonus:</strong> ${referralBonus || '0'}</p>
                  <p><strong>Total Bill:</strong> ${totalBill}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
