// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
 
 
// Components
import Loginnn from './components/Loginnn';
import EditDoc from './components/EditDoc';
import Forgetpassword from './components/forgetpassword';
import Homepage from './landing/pages/Homepage';
import Nav from './landing/components/Nav';
import Chatbot from './components/Chatbot';
import WhatsAppChatButton from './components/Whatsapp';
 
// Landing Pages

import Aboutus from './landing/pages/Aboutus';
import { Refundstatus } from './landing/pages/Refundstatus';

import { Price } from './landing/pages/price';
import { Contactusform } from './landing/pages/contactusform';
import { Clientreview } from './landing/pages/clientreview';
import { Fedrel } from './landing/pages/fedrel';
import { Amendment } from './landing/pages/amendment';
import { Itinpreparation } from './landing/pages/itinpreparation';
import { Fbar } from './landing/pages/fbar';
import { W4assistance } from './landing/pages/w4assistance';
import { Taxextension } from './landing/pages/taxextension';
import { Estimatetax } from './landing/pages/estimatetax';
import { Taxplanning } from './landing/pages/taxplanning';
import { Filingoptions } from './landing/pages/filingoptions';
import { Indian } from './landing/pages/indian';
import { Indianagstreturns } from './landing/pages/indianagstreturns';
import { Free } from './landing/pages/free';
import { Business } from './landing/pages/business';
import { Businessreturns } from './landing/pages/businessreturns';
import { Accounting } from './landing/pages/accounting';
import { Payroll } from './landing/pages/payroll';
import { Staterefundstatus } from './landing/pages/staterefundstatus';
 
// Admin Pages
import Adminanalytics from './admin/pages/adminanalytics';
import Admindashboard from './admin/pages/Admindashboard';
import Adminsidebar from './admin/components/Adminsidebar';
import Adminprofile from './admin/pages/adminprofile';
import Callers from './admin/pages/callers';
import Clients from './admin/pages/clients';
import Documentspopup from './admin/pages/Documentspopup';
import Documentstimeline from './admin/pages/Documentstimeline';
import Employeinformation from './admin/pages/Employeinformation';
import Empactivity from './admin/pages/empactivity';
import { Invoice } from './admin/pages/invoice';
import Leads from './admin/pages/Leads';
 
// Client Pages
import Clientdashboard from './client/pages/Clientdashboard';
import Clientinvoice from './client/pages/Clientinvoice';
import { Documents } from './client/pages/documents';
import Helpcenter from './client/pages/helpcenter';
import Referandearn from './client/pages/Referandearn';
import { Settings } from './client/pages/settings';
import { Taxorganizer } from './client/pages/taxorganizer';
import Clientprofile from './client/pages/Clientprofile';
 
// Employee Pages
import Employeedashboard from './employee/pages/employeedashboard';
import { EmpShifts } from './employee/pages/empShifts';
import EmpProfile from './employee/pages/empprofile';
import EmpLeads from './employee/pages/empleads';
import EmpClients from './employee/pages/empclients';
import EmpDocuments  from './employee/pages/empdocuments';
 
 
 
// Authentication
import { Signup } from './landing/components/signup';
import { Loginn } from './landing/components/loginn';
import { Forgotpassword } from './client/pages/forgetpassword';
 
// Admin and Superadmin
import { Superadmin } from './superadmin/superadmin';
import { Masteradmin } from './masteradmin/masteradmin';
import { Dashboard } from './masteradmin/dashboard';
 
import Cosection from './landing/components/Cosections';
import { Uppercase } from './landing/components/Uppercase';
import Testimonial from './landing/pages/Testimonials';
import Planningservices from './landing/pages/Planningservices';
import New from './landing/pages/NEW';
import Se from './landing/pages/Se';
import Se1 from './landing/pages/Se1';
 
 
 
 
 
// Custom GA listener component to track page views
const GAListener = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
  return children;
};
 
const App = () => {
  return (
    <BrowserRouter>
 
            <GAListener>
              <Routes>
                {/* Public Pages */}
                 
<Route path="/" element={<Homepage />} />
<Route path="/nav" element={<Nav />} />
<Route path="/aboutus" element={<Aboutus />} />
<Route path="/refundstatus" element={<Refundstatus />} />

<Route path="/price" element={<Price />} />

<Route path="/clientreview" element={<Clientreview />} />
<Route path="/fedrel" element={<Fedrel />} />
<Route path="/amendment" element={<Amendment />} />
<Route path="/itinpreparation" element={<Itinpreparation />} />
<Route path="/fbar" element={<Fbar />} />
<Route path="/w4assistance" element={<W4assistance />} />
<Route path="/taxextension" element={<Taxextension />} />
<Route path="/estimatetax" element={<Estimatetax />} />
<Route path="/taxplanning" element={<Taxplanning />} />
<Route path="/filingoptions" element={<Filingoptions />} />
<Route path="/indian" element={<Indian />} />
<Route path="/se" element={<Se />} />
<Route path="/indianagstreturns" element={<Indianagstreturns />} />
<Route path="/free" element={<Free />} />
<Route path="/business" element={<Business />} />
<Route path="/uppercase" element={<Uppercase />} />
<Route path="/new" element={<New />} />
<Route path="/se1" element={<Se1 />} />
<Route path="/cosection" element={<Cosection />} />
<Route path="/testimonials" element={<Testimonial />} />
<Route path="/planningservices" element={<Planningservices />} />
<Route path="/businessreturns" element={<Businessreturns />} />
<Route path="/accounting" element={<Accounting />} />
<Route path="/staterefundstatus" element={<Staterefundstatus />} />
<Route path="/payroll" element={<Payroll />} />
<Route path="/contactusform" element={<Contactusform/>} />
 
 
 
 
                {/* Authentication */}
                <Route path="/login" element={<Loginn />} />
                <Route path="/signup" element={<Signup />} />
                <Route path="/Loginnn" element={<Loginnn />} />
                <Route path="/forgotpassword" element={<Forgotpassword />} />
                <Route path="/forgetpassword" element={<Forgetpassword />} />
 
                {/* Superadmin, Masteradmin, and Dashboard */}
                <Route path="/Superadmin" element={<Superadmin />} />
                <Route path="/masteradmin" element={<Masteradmin />} />
                <Route path="/Dashboard" element={<Dashboard />} />
 
                {/* Client Pages */}
                <Route path="/client" element={<Clientdashboard />} />
                <Route path="/clientinvoice" element={<Clientinvoice />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/referandearn" element={<Referandearn />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/taxorganizer" element={<Taxorganizer />} />
                <Route path="/helpcenter" element={<Helpcenter />} />
                <Route path="/clientprofile" element={<Clientprofile />} />
 
                {/* Admin Pages */}
                <Route path="/admin" element={<Admindashboard />} />
                <Route path="/adminanalytics" element={<Adminanalytics />} />
                <Route path="/adminsidebar" element={<Adminsidebar />} />
                <Route path="/adminprofile" element={<Adminprofile />} />
                <Route path="/callers" element={<Callers />} />
                <Route path="/clients" element={<Clients />} />
                <Route path="/documentspopup" element={<Documentspopup />} />
                <Route path="/documentstimeline" element={<Documentstimeline />} />
                <Route path="/empactivity" element={<Empactivity />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/leads" element={<Leads />} />
 
                {/* Employee Pages */}
                <Route path="/employee" element={<Employeedashboard />} />
             
                <Route path="/empshifts" element={<EmpShifts />} />
                <Route path="/empProfile" element={<EmpProfile />} />
                <Route path="/employeinformation" element={<Employeinformation />} />
                <Route path="/empLeads" element={<EmpLeads />} />
                <Route path="/empClients" element={<EmpClients />} />
                <Route path="/employeinformation" element={<Employeinformation />} />
                <Route path="/empdocuments" element={<EmpDocuments />} />
 
 
                {/* Other Components */}
                <Route path="/editdoc" element={<EditDoc />} />
                <Route path="/chatbot" element={<Chatbot />} />
                <Route path="/whatsapp" element={<WhatsAppChatButton />} />
               
               
              </Routes>
            </GAListener>
         
    </BrowserRouter>
  );
};
 
export default App;