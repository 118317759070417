import React, { useState } from 'react';
import './settings.css'
import  Clientsidebar  from '../components/Clientsidebar';
import { useNavigate } from 'react-router-dom';


export const Settings = () => {
  const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
  
    const handleChangePassword = async (e) => {
      e.preventDefault();
  
      // Validate if newPassword and confirmPassword match
      if (newPassword !== confirmPassword) {
        alert('New password and confirm password do not match.');
        return;
      }
  
      // Make a request to the backend to update the password
      try {
        const response = await fetch('https://api.clasptax.com/updatePassword', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
          body: JSON.stringify({
            username, 
            oldPassword,
            newPassword,
          }),
        });
  
        const data = await response.json();
  
        if (response.ok) {
          alert('Password updated successfully.');
          navigate('/login');
        } else {
          alert(data.message || 'Failed to update password.');
        }
      } catch (error) {
        console.error('Error updating password:', error);
        alert('An error occurred while updating the password.');
      }
    };

  return (
    <div>

      <Clientsidebar/>
    <div class="containerrrrrr row gx-5  dashboardalignn">
   
  <form class="custom-form col-md-6" onSubmit={handleChangePassword}>
    <p class="custom-form-title">Change Password</p><hr/>
    <div class="custom-input-container">
      <label class="input_label" for="email_field">Username</label>
      <input
          type="text"
          className="custom-username-input"
          placeholder="Enter Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />      <span></span>
    </div>
    <div class="custom-input-container">
      <label class="input_label" for="old_password_field">Old Password</label>
      <input
          type="password"
          className="custom-password-input"
          placeholder="Old Enter password"
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
        />    </div>
    <div class="custom-input-container">
      <label class="input_label" for="new_password_field">New Password</label>
      <input
          type="password"
          className="custom-password-input"
          placeholder="New password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />    </div>
    <div class="custom-input-container">
      <label class="input_label" for="reenter_password_field">Re-Enter Password</label>
      <input
          type="password"
          className="custom-password-input"
          placeholder="Re-Enter password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />    </div>
    <button type="submit" class="custom-submit-button">
      Change Password
    </button>
  </form>

  <div class="cardds col-md-6">
    <h2 class="cardds-heading">Guidelines for Strong Passwrod</h2><hr/>
    <ul class="guidelines-list">
      <li>At least 8 characters - the more characters, the better.</li>
      <li>A mixture of both uppercase and lowercase letters.</li>
      <li>A mixture of letters and numbers.</li>
      <li>Avoid using common passwords like password, 12345, or qwerty.</li>
      <li>Inclusion of at least one special character like ! @#$%^&*()</li>
    </ul><hr/>
    <h1 class="cardds-heading">Strong password examples are:</h1><hr/>
    <ul class="example-list">
      <li>iLovemy$og!</li>
      <li>Asdfg@?dfgFd</li>
    </ul>
  </div>
</div>

</div>

  )
}