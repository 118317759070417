import React, { useState, useEffect } from 'react';
import './Uppercase.css';
 
export const Uppercase = () => {
  const [timeZones, setTimeZones] = useState({
    CST: '',
    EST: '',
    PST: '',
    MST: '',
  });
  const [selectedState, setSelectedState] = useState('');
  const [stateTime, setStateTime] = useState('');
  const [isMobileView, setIsMobileView] = useState(false);
  const [showContent, setShowContent] = useState(false);
 
  const statesTimeZone = {
    'Alabama (AL)': 'CST',
    'Alaska (AK)': 'PST',
    'Arizona (AZ)': 'MST',
    'Arkansas (AR)': 'CST',
    'California (CA)': 'PST',
    'Colorado (CO)': 'MST',
    'Connecticut (CT)': 'EST',
    'Delaware (DE)': 'EST',
    'Florida (FL)': 'EST',
    'Georgia (GA)': 'EST',
    'Hawaii (HI)': 'PST',
    'Idaho (ID)': 'MST',
    'Illinois (IL)': 'CST',
    'Indiana (IN)': 'EST',
    'Iowa (IA)': 'CST',
    'Kansas (KS)': 'CST',
    'Kentucky (KY)': 'EST',
    'Louisiana (LA)': 'CST',
    'Maine (ME)': 'EST',
    'Maryland (MD)': 'EST',
    'Massachusetts (MA)': 'EST',
    'Michigan (MI)': 'EST',
    'Minnesota (MN)': 'CST',
    'Mississippi (MS)': 'CST',
    'Missouri (MO)': 'CST',
    'Montana (MT)': 'MST',
    'Nebraska (NE)': 'CST',
    'Nevada (NV)': 'PST',
    'New Hampshire (NH)': 'EST',
    'New Jersey (NJ)': 'EST',
    'New Mexico (NM)': 'MST',
    'New York (NY)': 'EST',
    'North Carolina (NC)': 'EST',
    'North Dakota (ND)': 'CST',
    'Ohio (OH)': 'EST',
    'Oklahoma (OK)': 'CST',
    'Oregon (OR)': 'PST',
    'Pennsylvania (PA)': 'EST',
    'Rhode Island (RI)': 'EST',
    'South Carolina (SC)': 'EST',
    'South Dakota (SD)': 'CST',
    'Tennessee (TN)': 'CST',
    'Texas (TX)': 'CST',
    'Utah (UT)': 'MST',
    'Vermont (VT)': 'EST',
    'Virginia (VA)': 'EST',
    'Washington (WA)': 'PST',
    'West Virginia (WV)': 'EST',
    'Wisconsin (WI)': 'CST',
    'Wyoming (WY)': 'MST',
  };
 
  useEffect(() => {
    const updateTimes = () => {
      const date = new Date();
      setTimeZones({
        CST: new Date(date.toLocaleString('en-US', { timeZone: 'America/Chicago' })).toLocaleTimeString('en-US', { hour12: true }).toUpperCase(),
        EST: new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' })).toLocaleTimeString('en-US', { hour12: true }).toUpperCase(),
        PST: new Date(date.toLocaleString('en-US', { timeZone: 'America/Los_Angeles' })).toLocaleTimeString('en-US', { hour12: true }).toUpperCase(),
        MST: new Date(date.toLocaleString('en-US', { timeZone: 'America/Denver' })).toLocaleTimeString('en-US', { hour12: true }).toUpperCase(),
      });
     
      if (selectedState) {
        const stateTimeZone = statesTimeZone[selectedState].split(' ')[0];
        const selectedStateTime = new Date(
          date.toLocaleString('en-US', {
            timeZone: stateTimeZone === 'CST' ? 'America/Chicago' :
                      stateTimeZone === 'EST' ? 'America/New_York' :
                      stateTimeZone === 'PST' ? 'America/Los_Angeles' :
                      'America/Denver'
          })
        ).toLocaleTimeString('en-US', { hour12: true }).toUpperCase();
        setStateTime(selectedStateTime);
      }
    };
 
    const interval = setInterval(updateTimes, 1000);
    return () => clearInterval(interval);
  }, [selectedState]);
 
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
        setShowContent(true);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
 
  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };
 
  const toggleContent = () => {
    setShowContent(!showContent);
  };
 
  return (
    <div className='prefix-upperbox'>
      {isMobileView && (
        <button className="prefix-toggle-button" onClick={toggleContent}>
          {showContent ? 'Hide Timezones' : 'Show Timezones'}
        </button>
      )}
     
      {(showContent || !isMobileView) && (
        <div className='prefix-content-row'>
          <div className='prefix-clocks'>
            <p>EST: {timeZones.EST}</p>
            <p>CST: {timeZones.CST}</p>
            <p>MST: {timeZones.MST}</p>
            <p>PST: {timeZones.PST}</p>
          </div>
          <div className='prefix-state-selector'>
            <select value={selectedState} onChange={handleStateChange}>
              <option value=''>Select State</option>
              {Object.keys(statesTimeZone).map((state) => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>
            {selectedState && (
              <div>
                <p>{selectedState} Time: {stateTime}</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
 