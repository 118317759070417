import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import{jwtDecode} from "jwt-decode";
import axios from "axios";
import './clientdashboard.css'
import  Clientsidebar  from '../components/Clientsidebar';

 const Clientdashboard = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [role, setRole] = useState('');
   
    useEffect(() => {
      const role = localStorage.getItem('role');
      if (role !== 'client') {
        navigate('/login');
      }
    }, [navigate]);
   
    useEffect(() => {
      const token = Cookies.get('token');
      if (!token) {
        navigate('/login');
      } else {
        const decoded = jwtDecode(token);
        setUsername(decoded.email || decoded.phoneNumber);
        setRole(decoded.role);
        if (decoded.role !== 'client') {
          navigate('/login');
        }
      }
    }, [navigate]);
   

    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
        const fetchUserData = async () => {
          try {
            const token = Cookies.get("token");
            const response = await axios.get("https://api.clasptax.com/client", {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            console.log(response.data);
          } catch (error) {
            console.error("Error:", error);
            if (error.response && error.response.status === 401) {
              // navigate("/");
            }
          }
        };
    
        fetchUserData();
      }
      }, [navigate]);
    
  return (
    
    <div>

    <Clientsidebar/>
    <div className='dashboardalign'>
            <div class="onboarding-steps mx-auto bg-white rounded ">
                <h1 class="h5 mb-3 mb-md-4">
                    Get Your Tax Done with Clasp Tax Filing in Easy Steps.
                </h1>

                <ul class="steps list-group">
                    <li class="step border-0 px-0 d-flex list-group-item">
                        <div class="mr-3">
                            <div class="step-icon step-1">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
                            </div>
                        </div>

                        <div>
                            <h2 class="h6 mt-0">
                                1 - Register &amp; Upload Documents
                            </h2>

                            <p>
                                Sign up as a new client and download the Tax Organizer. When complete upload it in your client portal along with W2 and other tax related documents. We will review your completed questionnaire and possibly ask additional questions to ensure we have all the necessary information.
                            </p>
                        </div>
                    </li>

                    <li class="step border-0 px-0 d-flex list-group-item">
                        <div class="mr-3">
                            <div class="step-icon step-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-book"><path d="M4 19.5A2.5 2.5 0 0 1 6.5 17H20"></path><path d="M6.5 2H20v20H6.5A2.5 2.5 0 0 1 4 19.5v-15A2.5 2.5 0 0 1 6.5 2z"></path></svg>
                            </div>
                        </div>

                        <div>
                            <h2 class="h6 mt-0">
                                2 - Tax Preparation
                            </h2>

                            <p>
                                Immediately after the tax information and tax documents are received, skilled tax professionals at Clasp Tax Filing center will scrutinize the entire information and work on the tax return preparation.
                            </p>
                        </div>
                    </li>

                    <li class="step border-0 px-0 d-flex list-group-item">
                        <div class="mr-3">
                            <div class="step-icon step-3">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                            </div>
                        </div>

                        <div>
                            <h2 class="h6 mt-0">
                                3 - Download &amp; Review Tax documents
                            </h2>

                            <p>
                                We will upload the finalized return to your client portal. Once you have the return, your can review and ask for any modifications related to information on return as necessary and return back to us.
                            </p>
                        </div>
                    </li>

                    <li class="step border-0 px-0 d-flex list-group-item">
                        <div class="mr-3">
                            <div class="step-icon step-4">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-credit-card"><rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect><line x1="1" y1="10" x2="23" y2="10"></line></svg>
                            </div>
                        </div>

                        <div>
                            <h2 class="h6 mt-0">
                                4 - Make Payment
                            </h2>

                            <p>
                                The payment is due at this time. Make the payment for your tax return ready for filing.
                            </p>
                        </div>
                    </li>

                    <li class="step border-0 px-0 d-flex list-group-item">
                        <div class="mr-3">
                            <div class="step-icon step-5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check-circle"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
                            </div>
                        </div>

                        <div>
                            <h2 class="h6 mt-0">
                                5 - Approval for E-file/paper files tax returns
                            </h2>

                            <p>
                                We will upload the finalized return to your client portal. Once you have the return, your can review and ask for any modifications related to information on return as necessary and return back to us. Later with your updated information we will be filing your tax returns.In most cases your return can be electronically filed. If your return is required to be mailed, we will provide you with instructions on how to mail your return.
                            </p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
  </div>
        
  )
}
export default Clientdashboard;