import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { NavLink, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Nav.css';
import logo from "../images/img123.png";
import { Upperbox } from './Upperbox';
import Dropdown from 'react-bootstrap/Dropdown';
const closeMenu = () => {
  const chkElement = document.getElementById('chk1');
  if (chkElement) {
    chkElement.checked = false; // Only set the checked property if the element exists
  }
};

function MyNavbar() {
  return (
    <div>
      <Navbar className="navbar-custom-bg" expand="lg">
        <Container>
          <Navbar.Brand href="/" className="navbar-title">
            <img
              src={logo}
              alt="A2Zee Logo"
              width="40"
              height="40"
              className="navbar-logo"
            />
                <p >Our Strength Your Numbers</p>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto text-center">
              <Link exact to="/" className="navbar-link" activeClassName="active">HOME</Link>
              <Link to="/aboutus" className="navbar-link">ABOUT US</Link>
             
              <Link to="#" className="navbar-link">
  <div className="dropdown">
    <span
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      SERVICES
    </span>
    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <Link to="/Se" className="dropdown-item" onClick={() => { closeMenu(); }}>
        Individual Services
      </Link>
      <hr />
      <Link to="/Se1" className="dropdown-item" onClick={() => { closeMenu(); }}>
        Business Services
      </Link>
    </div>
  </div>
</Link>

          {/* <Dropdown>
<Dropdown.Toggle variant="success" id="dropdown-basic">
        Dropdown Button
</Dropdown.Toggle>
 
      <Dropdown.Menu>
<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
</Dropdown.Menu>
</Dropdown> */}
          
              <Link to="/price" className="navbar-link" activeClassName="active">PRICE</Link>
              <Link to="/refundstatus" className="navbar-link" activeClassName="active">REFUND STATUS</Link>
              <Link to="/contactusform" className="navbar-link" activeClassName="active">CONTACT US</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default MyNavbar;
