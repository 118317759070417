import React, { useState } from 'react';
import Axios from 'axios';
import './signup.css';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 
export const Signup = () => {
  const [email, setEmail] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [message, setMessage] = useState('');
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [timer, setTimer] = useState(120);
  const [otpDisabled, setOtpDisabled] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isLoadingOtp, setIsLoadingOtp] = useState(false);
  const [isLoadingSignup, setIsLoadingSignup] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');
  const navigate = useNavigate();
 
  Axios.defaults.withCredentials = true;
 
  function getPasswordStrength(password) {
    if (!password) {
      return '';
    }
 
    const hasNumbers = /\d/.test(password);
    const hasAlphabets = /[a-zA-Z]/.test(password);
    const hasSpecialCharacters = /[!@#$%^&*(),.?":{}|<>]/.test(password);
 
    if (hasNumbers && hasAlphabets && hasSpecialCharacters) {
      return 'strong';
    } else if (hasNumbers && hasAlphabets) {
      return 'moderate';
    } else {
      return 'weak';
    }
  }
 
  function isEmptyOrSpaces(str) {
    return str === null || str.match(/^ *$/) !== null;
  }
 
  function validateForm() {
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let usernameRegex = /^[a-zA-Z0-9]{5,}$/;
 
    if (
      isEmptyOrSpaces(username) ||
      isEmptyOrSpaces(email) ||
      isEmptyOrSpaces(phonenumber) ||
      isEmptyOrSpaces(password)
    ) {
      alert('All fields are required.');
      return false;
    }
 
    if (!emailRegex.test(email)) {
      alert('Enter a valid email.');
      return false;
    }
    if (!usernameRegex.test(username)) {
      alert('Username must be at least 5 characters long and alphanumeric.');
      return false;
    }
    if (phonenumber.length < 10) {
      alert('Enter a valid phone number.');
      return false;
    }
 
    return true;
  }
 
  //   const sendOTP = () => {
  //     if (!validateForm()) return;
  //     setIsLoadingOtp(true);
 
  //     // First, check if the user is already registered
  //     Axios.post('https://api.clasptax.com/checkUser', { email, phonenumber })
  //       .then((response) => {
  //         if (response.data.exists) {
  //           // User is already registered
  //           toast.error('You are already registered. Please log in.');
  //           setIsLoadingOtp(false);
  //           return;
  //         }
 
  //         // If user is not registered, proceed to send OTP
  //         return Axios.post(
  //           'https://api.clasptax.com/sendOTP',
  //           { email },
  //           { withCredentials: true }
  //         );
  //       })
  //       .then((response) => {
  //         if (response && response.data.success) {
  //           toast.success(response.data.message);
  //           setIsOtpSent(true);
  //           setMessage('');
  //           setOtpDisabled(true);
  //         } else if (response) {
  //           toast.error(response.data.message);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error('Error sending OTP:', error);
  //         toast.error('Failed to send OTP. Please try again.');
  //       })
  //       .finally(() => {
  //         setIsLoadingOtp(false);
  //       });
  //   };
 
  // const sendOTP = () => {
  //   if (!validateForm()) return;
  //   setIsLoadingOtp(true);
 
  //   Axios.post('https://api.clasptax.com/checkUser', { email, phonenumber })
  //     .then((response) => {
  //       if (response.data.exists) {
  //         let errorMessage = '';
  //         if (response.data.emailExists && response.data.phoneExists) {
  //           errorMessage +=
  //             'Both email and phone number are already registered. Please Login with your existing creditionals';
  //         } else if (response.data.emailExists) {
  //           errorMessage += 'Email is already registered.';
  //         } else if (response.data.phoneExists) {
  //           errorMessage += 'Phone number is already registered.';
  //         }
 
  //         toast.info(errorMessage);
  //         setIsLoadingOtp(false);
  //         return;
  //       }
 
  //       return Axios.post(
  //         'https://api.clasptax.com/sendOTP',
  //         { email },
  //         { withCredentials: true }
  //       );
  //     })
  //     .then((response) => {
  //       if (response && response.data.success) {
  //         toast.success(response.data.message);
  //         setIsOtpSent(true);
  //         setMessage('');
  //         setOtpDisabled(true);
  //       } else if (response) {
  //         toast.error(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('Error sending OTP:', error);
  //       toast.error('Failed to send OTP. Please try again.');
  //     })
  //     .finally(() => {
  //       setIsLoadingOtp(false);
  //     });
  // };
 
  const sendOTP = () => {
    if (!validateForm()) return;
   
    if (password !== confirmPassword) {
      toast.error('Passwords do not match. Please ensure both passwords match.');
      return;
    }
 
    setIsLoadingOtp(true);
 
    Axios.post('https://api.clasptax.com/checkUser', { email, phonenumber })
      .then((response) => {
        if (response.data.exists) {
          let errorMessage = '';
          if (response.data.emailExists && response.data.phoneExists) {
            errorMessage +=
              'Both email and phone number are already registered. Please Login with your existing credentials.';
          } else if (response.data.emailExists) {
            errorMessage += 'Email is already registered.';
          } else if (response.data.phoneExists) {
            errorMessage += 'Phone number is already registered.';
          }
 
          toast.info(errorMessage);
          setIsLoadingOtp(false);
          return;
        }
 
        return Axios.post(
          'https://api.clasptax.com/sendOTP',
          { email },
          { withCredentials: true }
        );
      })
      .then((response) => {
        if (response && response.data.success) {
          toast.success(response.data.message);
          setIsOtpSent(true);
          setMessage('');
          setOtpDisabled(true);
        } else if (response) {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error('Error sending OTP:', error);
        toast.error('Failed to send OTP. Please try again.');
      })
      .finally(() => {
        setIsLoadingOtp(false);
      });
  };
 
 
  const verifyOTP = () => {
    setIsLoadingOtp(true);
    Axios.post('https://api.clasptax.com/verifyOTP', { email, otp })
      .then((response) => {
        if (response.data.success) {
          setMessage('OTP verified successfully.');
          setIsOtpVerified(true);
          toast.success('OTP verified successfully!');
          setTimer(0);
        } else {
          setMessage(response.data.message);
          toast.error('Please enter correct OTP.');
        }
      })
      .catch((error) => {
        console.error('Error verifying OTP:', error);
        setMessage('Error verifying OTP.');
        toast.error('Entered OTP is wrong. Please try again.');
      })
      .finally(() => {
        setIsLoadingOtp(false);
      });
  };
 
  const handleRegistration = (event) => {
    event.preventDefault();
    if (!validateForm()) return;
 
    if (password === confirmPassword) {
      if (isOtpVerified) {
        setIsLoadingSignup(true);
        Axios.post('https://api.clasptax.com/registration', {
          username: username,
          password: password,
          email: email,
          phonenumber: phonenumber,
          isTermsAccepted: isTermsAccepted,
        })
          .then((response) => {
            if (response.status === 200) {
              toast.success('Registration successful');
              navigate('/login');
            } else {
              alert(`Registration failed: ${response.data.message}`);
            }
          })
          .catch((error) => {
            console.error('Error during registration:', error);
            alert('Registration failed.');
          })
          .finally(() => {
            setIsLoadingSignup(false);
          });
      } else {
        alert('Please verify OTP before proceeding.');
      }
    } else {
      alert('Passwords do not match.');
    }
  };
 
  return (
    <div className="signup-containerr">
      <div className="signup-card signup-image-card">
        <div className="services-content">
          <h2 className="services-heading">
            Stress-Free Tax Filing Starts Here
          </h2>
          <ul className="si-services-list">
            <li>Federal - 1040,1040NR,1065,1120-S,1120</li>
            <li>State Returns</li>
            <li>Business Incorporations</li>
            <li>Bookkeeping</li>
            <li>Payroll Tax Returns</li>
            <li>Power Of Attorney (2848)</li>
            <li>Third Party Designee Services</li>
            <li>ITIN Filing</li>
            <li>ITIN Filing - Non-Resident</li>
            <li>FBAR Filing/FATCA Filing</li>
            <li>State Rental Credit & OSTC Credit Planning</li>
            <li>City Return & County Taxes</li>
            <li>Amended Return Filings</li>
            <li>
              Unlimited Tax Representation with IRS For 8 Successive Years
            </li>
          </ul>
        </div>
      </div>
      <div className="signup-card signup-form-card">
        <div className="signup-back-button">
          <Link to="/">
            <i className="material-symbols-outlined">keyboard_backspace</i>
          </Link>
        </div>
        <h1 className="signup-heading">Register</h1>
        <form onSubmit={handleRegistration} className="signup-form">
          <div className="signup-txt-field">
            <input
              type="text"
              required
              onChange={(e) => setUsername(e.target.value)}
            />
            <label>Username</label>
          </div>
          <div className="signup-txt-field">
            <input
              type="email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <label>Email</label>
          </div>
          <div className="signup-txt-field">
            <input
              type="text"
              required
              onChange={(e) => setPhonenumber(e.target.value)}
            />
            <label>Phone Number</label>
          </div>
          <div className="signup-txt-field">
            <input
              type="password"
              required
              onChange={(e) => {
                setPassword(e.target.value);
                setPasswordStrength(getPasswordStrength(e.target.value));
              }}
            />
            <div className="password-strength">
              {passwordStrength === 'strong' && (
                <p style={{ color: 'green' }}>Password strength: Strong</p>
              )}
              {passwordStrength === 'moderate' && (
                <p style={{ color: 'orange' }}>Password strength: Moderate</p>
              )}
              {passwordStrength === 'weak' && (
                <p style={{ color: 'red' }}>Password strength: Weak</p>
              )}
            </div>
 
            <label>Password</label>
          </div>
          <div className="signup-txt-field">
            <input
              type="password"
              required
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <label>Confirm Password</label>
          </div>
 
          {!isOtpSent ? (
            <button
              type="button"
              className="signup-otp-button"
              onClick={sendOTP}
              disabled={otpDisabled || isLoadingOtp}
            >
              {isLoadingOtp ? 'Sending OTP...' : 'Verify with OTP via Email'}
            </button>
          ) : (
            <div className="signup-txt-field">
              <input
                type="text"
                required
                onChange={(e) => setOtp(e.target.value)}
              />
              <label>Enter OTP</label>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button
                  type="button"
                  className="signup-otp-button"
                  onClick={verifyOTP}
                >
                  {isLoadingOtp ? 'Verifying...' : 'Verify OTP'}
                </button>
                <button
                  type="button"
                  className="signup-otp-button"
                  onClick={sendOTP}
                  disabled={isLoadingOtp}
                >
                  {isLoadingOtp ? 'Resending...' : 'Resend OTP'}
                </button>
              </div>
            </div>
          )}
 
          <div className="terms-conditions">
            <input
              type="checkbox"
              id="terms"
              checked={isTermsAccepted}
              onChange={() => setIsTermsAccepted(!isTermsAccepted)}
            />
            <label htmlFor="terms">
              I agree to the{' '}
              <a href="/terms" target="_blank" rel="noopener noreferrer">
                Terms and Conditions
              </a>
            </label>
          </div>
 
          <button
            type="submit"
            value="SignUp"
            className={`signup-submit-button ${
              !isOtpVerified || !isTermsAccepted ? 'disabled' : ''
            }`}
            disabled={isLoadingSignup || !isOtpVerified || !isTermsAccepted}
          >
            {isLoadingSignup ? 'Signing Up...' : 'Sign Up'}
          </button>
 
          <div className="signup-link">
            Already have an account? <Link to="/login">Login</Link>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};