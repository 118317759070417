import React, { useState,useRef,useEffect } from 'react';
import Nav from "../components/Nav";
import "./Homepage.css";
import "./contactusform";
import LocationModel from "../../components/LocationModel";
import Scrolltop from '../../components/Scrolltop';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import img123 from "../images/img123.png";
import { FaClock, FaStar, FaSmile } from 'react-icons/fa';
import bannerss from "../images/bannerss.png";
import { GrServices } from "react-icons/gr";
import Testimonials from './Testimonials';
import Planningservices from '../pages/Planningservices';
import { Footer } from '../components/footer';
import Chatbot from '../../components/Chatbot';
import WhatsAppChatButton from '../../components/Whatsapp';
import { Upperbox} from '../components/Upperbox';
import Cosection from '../components/Cosections';
import { FaUser, FaBriefcase, FaDollarSign, FaFileAlt, FaChartLine, FaHandshake } from 'react-icons/fa';
import { Contactusform } from './contactusform';
 // Define interval after which popup should show again (in days)

const Homepage = () => {
    const [activeCard, setActiveCard] = useState(null);
    const [showPopup, setShowPopup] = useState(false);

    const PARENT_POPUP_KEY = 'popupClosedTime'; // Key to store popup state in localStorage
    const POPUP_INTERVAL_DAYS = 1;

    const navigate = useNavigate();

      // Check if the popup should be shown based on the last close time
  useEffect(() => {
    const lastPopupTime = localStorage.getItem(PARENT_POPUP_KEY);
    const now = new Date().getTime();

    // If the popup was never shown or enough time (like a day) has passed, show the popup
    if (!lastPopupTime || now - lastPopupTime > POPUP_INTERVAL_DAYS * 24 * 60 * 60 * 1000) {
      setShowPopup(true);
    }
  }, []);

    const [formData, setFormData] = useState({
      name: '',
      number: '',
      email: '',
      subject: '',
      message: '',
    });
  
    const handleChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      // Send the form data to the backend API
      axios.post('https://api.clasptax.com/submitContactForm', formData)
        .then((response) => {
          // Handle the success response, show a pop-up, etc.
          alert(response.data.message);
          // Redirect to /login after successful form submission
          navigate('/Signup');
        })
        .catch((error) => {
          // Handle the error response, display an error message, etc.
          console.error('Error submitting contact form:', error);
          alert('Error submitting contact form. Please try again.');
        });
    };





    const handleMouseEnter = (cardId) => {
        setActiveCard(cardId);
    };

    const handleMouseLeave = () => {
        setActiveCard(null);
    };

     const cardContainerRef = useRef(null);

  const scrollLeft = () => {
    cardContainerRef.current.scrollBy({ left: -300, behavior: "smooth" }); // Adjust scroll distance
  };

  const scrollRight = () => {
    cardContainerRef.current.scrollBy({ left: 300, behavior: "smooth" }); // Adjust scroll distance
  };

    const services = {
        individual: [
            { title: 'Personal Tax Filing', icon: <FaUser />, description: 'Hassle-free tax filing for individuals.' },
            { title: 'Income Analysis', icon: <FaDollarSign />, description: 'Comprehensive income analysis and suggestions.' },
            { title: 'Document Assistance', icon: <FaFileAlt />, description: 'Assistance with all required tax documents.' },
            { title: 'Tax Saving Tips', icon: <FaChartLine />, description: 'Maximize your savings with expert tips.' },
            { title: 'Refund Tracking', icon: <FaDollarSign />, description: 'Monitor your tax refunds in real-time.' },
            { title: 'Health Insurance Filing', icon: <FaUser />, description: 'We assist in accurate health insurance filings.' },
            { title: 'Retirement Plans', icon: <FaHandshake />, description: 'Personalized retirement savings plans.' },
            { title: 'Deductions & Credits', icon: <FaChartLine />, description: 'Maximize deductions for your tax credits.' },
            { title: 'Student Tax Benefits', icon: <FaUser />, description: 'File with specific tax benefits for students.' },
            { title: 'Investment Reporting', icon: <FaFileAlt />, description: 'Report your investments accurately for tax.' },

        ],
        business: [
            { title: 'Corporate Tax Filing', icon: <FaBriefcase />, description: 'Professional tax filing services for businesses.' },
            { title: 'Expense Analysis', icon: <FaDollarSign />, description: 'Expert analysis to streamline business expenses.' },
            { title: 'Audit Support', icon: <FaFileAlt />, description: 'Full support in case of tax audits.' },
            { title: 'Financial Forecasting', icon: <FaChartLine />, description: 'Make informed decisions with expert forecasting.' },
            { title: 'Partnership Filing', icon: <FaHandshake />, description: 'File taxes seamlessly for business partnerships.' },
        ],
    };

    const ServiceCard = ({ title, description, icon }) => {
        return (
            <div className="service-cardd-d">
                <div className="card-content">
                    <div className="service-icon">{icon}</div>
                    <h2 className='card-icon-text'>{title}</h2>
                    <p>{description}</p>
                </div>
            </div>
        );
    };




   // Handle popup close
   const handleClosePopup = () => {
    console.log("Popup closed");
    setShowPopup(false);

    // Store the current timestamp when the popup is closed
    localStorage.setItem(PARENT_POPUP_KEY, new Date().getTime());
  };


  // // Show popup every time the user visits the page
  // useEffect(() => {
  //   setShowPopup(true); // Reset the popup state on every page load
  // }, []);



    

    return (
        <div>
            <Upperbox/>
            <Nav/>
            <div>
            <LocationModel show={showPopup} handleClose={handleClosePopup} />
            </div>
            <div className='home-containerr'>
                <section className="hero-section">
                    <div className="hero-container">
                        <div className="hero-image">
                            <img
                                src={bannerss} // Replace with the correct image path
                                alt="Main Illustration"
                            />
                            <div>

                            </div>
                        </div>
                        <div className="hero-content">
                            <h1>
                                Usa's Trusted <span>Tax Filing Partner</span>
                            </h1>
                            <p className="hero-description">
    Clasp Tax Inc. simplifies the tax filing process in the USA by offering a seamless, user-friendly platform, 
    with expert assistance. we provide tailored solutions to meet your unique needs.
</p>
                            <div className="hero-buttons">
                                <button className="hero-btn">Belated Return</button>
                                <button className="hero-btn">Revised Return</button>
                                <button className="hero-btn hero-btn-primary">Notice Management</button>
                            </div>
                        </div>

                    </div>
                </section>


            </div>
           

            {/* <div>
<div className="cardd">
      <p className="card-title">Product Name</p>
      <p className="small-desc">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quaerat
        veritatis nobis saepe itaque rerum nostrum aliquid obcaecati odio
        officia deleniti. Expedita iste et illum, quaerat pariatur consequatur
        eum nihil itaque!
      </p>
      <div className="go-corner">
        <div className="go-arrow">→</div>
      </div>
    </div>
</div> */}

<div className='taxclub'>
    <h1 className='mytaxclub'>We'll take care of your Tax Returns with 4 easy and effective steps</h1>

    <div className='new-card-f'>
        {[
            {
                title: "Register and Upload",
                description: "Register and upload your tax documents"
            },
            {
                title: "Free Tax Estimation",
                description: "Get your Free Tax Estimation"
            },
            {
                title: "Review & Payment",
                description: "Review your tax Estimate & Complete the Payment"
            },
            {
                title: "Same-day IRS Filing",
                description: "Tax returns will be filed with IRS on the same day"
            }
        ].map((item, index) => (
            <div className='card-card' key={index}>
                <div className='icon-background'>
                    <GrServices />
                </div>
                <div className='card-width'>
                    <h2 className='heading-violet'>{item.title}</h2>
                    <p className='heading-violet-p'>{item.description}</p>
                </div>
            </div>
        ))}
    </div>
</div>






            <div className="per-newmaincard container">
    <div>
      <div className="per-newheading">
        <h4 className="per-h4">Why Tax With</h4>
        <h4 className="per-trueh4">US?</h4>
      </div>
      <div>
        <div className="per-newmincards row d-flex flex-row justify-content-center row-cols-md-3 row-cols-sm-2 row-cols-xs-1">
          <div className="per-newcard cols mt-5">
            <h1 className='per-nn'>Expert Guidance</h1>
            <div className="per-newpara">
              <p className="per-p">Benefit from our seasoned tax professionals' expertise, ensuring accurate filings and maximum returns.</p>
            </div>
          </div>
          <div className="per-newcard cols mt-5">
            <h1 className='per-nn'>Effortless Process</h1>
            <div className="per-newpara">
              <p className="per-p">Experience a hassle-free tax return process with our streamlined and efficient services.</p>
            </div>
          </div>
          <div className="per-newcard cols mt-5">
            <h1 className='per-nn'>Timely Compliance</h1>
            <div className="per-newpara">
              <p className="per-p">Avoid penalties and ensure on-time tax compliance under the guidance of our experienced team.</p>
            </div>
          </div>
          <div className="per-newcard cols mt-5">
            <h1 className='per-nn'>Personalized Solutions</h1>
            <div className="per-newpara">
              <p className="per-p">Receive tailored tax solutions that align with your unique financial situation and goals.</p>
            </div>
          </div>
          <div className="per-newcard cols mt-5">
            <h1 className='per-nn'>Transparent Communication</h1>
            <div className="per-newpara">
              <p className="per-p">Stay informed with transparent and clear communication throughout the tax filing journey.</p>
            </div>
          </div>
          <div className="per-newcard cols mt-5">
            <h1 className='per-nn'>Trustworthy Partner</h1>
            <div className="per-newpara">
              <p className="per-p">Rely on us as your trustworthy tax partner for meticulous filings, extensions, and compliance assistance.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>






            <div className="tax-services-container">
            <h4 className="per-h4">Our services</h4>
                <h2 className="section-title">Individual Services</h2>
                <div className="service-list">
                    {services.individual.map((service, index) => (
                        <ServiceCard key={index} title={service.title} description={service.description} icon={service.icon} />
                    ))}
                </div>

                <h2 className="section-titlee">Business Services</h2>
                <div className="service-list">
                    {services.business.map((service, index) => (
                        <ServiceCard key={index} title={service.title} description={service.description} icon={service.icon} />
                    ))}
                </div>
            </div>

           


         <div>
         <div>
 
</div>
<Testimonials/>
<Planningservices/>
<Chatbot/>
<Scrolltop/>
<WhatsAppChatButton/>

<Cosection/>

<Footer/>
         </div>


  

            


        </div>
    )
}

export default Homepage
