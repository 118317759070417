import React, { useEffect } from 'react';
import './new.css';
import { Link } from 'react-router-dom';
import Nav from "../components/Nav";
import { Footer } from '../components/footer';
import Chatbot from '../../components/Chatbot';
import WhatsAppChatButton from '../../components/Whatsapp';
import { Upperbox} from '../components/Upperbox';
export const Indian = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
 
  return (
    <div>
    <Upperbox/>
    <Nav/>
    <div className='tax-page'>
      <div className="tax-notification-container">
        <div className="tax-notification">
          <div className="tax-notification-glow"></div>
          <div className="tax-notification-border-glow"></div>
          <h1 className="tax-notification-title">Indian Income Tax Filing Services</h1>
          <Link to="/se" className="tax-notification-body">Go Back</Link>
        </div>
      </div>
 
      <div className='tax-content-container'>
        <h1 className='tax-section-title'>
          Precise federal/state tax filings and comprehensive tax services, including planning, resolution, audit representation, and business solutions.
        </h1>
        <p className='tax-section-description'>
          Your Trusted Compass in the Tax Landscape. Navigating the ever-changing world of taxes can feel like trekking through a dense forest – full of winding paths, hidden obstacles, and the occasional hungry bear (aka the IRS). But fear not, weary traveler! Clasp Tax is your trusty guide, ready to lead you to a smooth and efficient tax filing journey.
        </p>
        <h2 className='tax-subheading'>Clasp Tax: More Than Just an ERO</h2>
        <p className='tax-section-description'>
          While we are a registered Electronic Return Originator (ERO) authorized to prepare and file federal and state tax returns with the IRS, we're so much more than that. Our team of seasoned tax professionals boasts a proven track record of accuracy and efficiency, and we're passionate about providing our clients with the highest level of service.
        </p>
        <p className='tax-section-description'>
          Beyond the Basics: A Wealth of Tax Expertise. Sure, we handle the essentials like federal and state tax return preparation, but Clasp Tax offers a comprehensive suite of services to address all your tax needs:
          <ul>
            <li>Tax Planning and Consulting: Let our experts chart a tax-optimized course for your future, identifying deductions, credits, and strategies to minimize your tax burden.</li>
            <li>Tax Resolution: Facing an IRS issue? We'll be your fearless advocates, navigating the complexities of tax resolution and ensuring the best possible outcome.</li>
            <li>Audit Representation: Don't go it alone! We'll stand by your side during an audit, providing expert guidance and representation to protect your interests.</li>
            <li>International Tax Services: Venturing beyond borders? We'll help you navigate the intricate world of international tax regulations, ensuring compliance and maximizing your global tax efficiency.</li>
            <li>Business Tax Services: Whether you're a solopreneur or a Fortune 500, our team is equipped to handle all your business tax needs, from bookkeeping and payroll to entity selection and tax planning.</li>
          </ul>
        </p>
        <h2 className='tax-subheading'>Clasp Tax: Your One-Stop Tax Haven</h2>
        <p className='tax-section-description'>
          We believe in a holistic approach to your tax health. That's why we go beyond just filing forms. We're your trusted advisors, here to answer your questions, ease your anxieties, and empower you to make informed financial decisions.
        </p>
        <p className='tax-section-description'>
          Ready to Embrace Tax Clarity? Contact Clasp Tax today and let us guide you towards a stress-free tax journey. We'll handle the complexities, so you can focus on what truly matters – achieving your financial goals. Don't let taxes be the bear in your tax filing maze. Choose Clasp Tax as your guide and navigate the path to financial freedom with confidence!
        </p>
      </div>
    </div>
    <Chatbot/>
    <WhatsAppChatButton/>
    <Footer/>
    </div>
  );
};
 
 
 