import React, { useState, useEffect,  useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import  Adminsidebar from '../components/Adminsidebar';
import './callers.css';
import { saveAs } from 'file-saver';

// import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { FaEdit, FaTrashAlt, FaDownload } from 'react-icons/fa';
import { Modal, Button, Form } from 'react-bootstrap';

const Callers = () => {
    const navigate = useNavigate();
    

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [selectedComment, setSelectedComment] = useState('');
    const [employeeDocuments, setEmployeeDocuments] = useState([]);
    const [filtersPage2, setFiltersPage2] = useState({
        dateFrom: '',
        dateTo: '',
        clientName: '',
        status: ''
    });
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [currentTablePage, setCurrentTablePage] = useState(1);
    const [selectedRowsPage2, setSelectedRowsPage2] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const fileInputRef = useRef(null);

    useEffect(() => {
        const dummyData = {
            employees: [
                { id: 1, lead_id: 'L001', client_id: 'C001', client_name: 'Alice Brown', assigned_date: '2024-08-02T12:00:00Z', status: 'pending', comment: 'Initial contact' },
                { id: 2, lead_id: 'L002', client_id: 'C002', client_name: 'Michael Black', assigned_date: '2024-08-04T12:00:00Z', status: 'completed', comment: 'Follow-up complete' },
                { id: 3, lead_id: 'L003', client_id: 'C003', client_name: 'Olivia White', assigned_date: '2024-08-05T12:00:00Z', status: 'pending', comment: 'Contacted, awaiting response' }
            ]
        };
        setEmployeeDocuments(dummyData.employees);
    }, []);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleDocumentTypeChange = (e) => {
        setSelectedDocumentType(e.target.value);
    };

    const handleCommentChange = (e) => {
        setSelectedComment(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Handle form submission
    };

    const handleCloseModal = () => {
        setSelectedFile(null);
        setSelectedDocumentType('');
        setSelectedComment('');
        setShowModal(false);
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const formatDateTime = (dateTime) => {
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        return new Date(dateTime).toLocaleString('en-US', options);
    };

    const filterDocumentsPage2 = () => {
        return employeeDocuments.filter((document) => {
            const { dateFrom, dateTo, clientName, status } = filtersPage2;
            const documentDate = new Date(document.assigned_date);
            const fromDate = dateFrom ? new Date(dateFrom) : new Date(0);
            const toDate = dateTo ? new Date(dateTo) : new Date();
            return (
                (dateFrom === '' || documentDate >= fromDate) &&
                (dateTo === '' || documentDate <= toDate) &&
                (clientName === '' || document.client_name.toLowerCase().includes(clientName.toLowerCase())) &&
                (status === '' || document.status === status)
            );
        });
    };

    const handleSelectRowPage2 = (id) => {
        setSelectedRowsPage2((prevSelectedRows) => {
            if (prevSelectedRows.includes(id)) {
                return prevSelectedRows.filter(rowId => rowId !== id);
            } else {
                return [...prevSelectedRows, id];
            }
        });
    };

    const handleSelectAllPage2 = (event) => {
        if (event.target.checked) {
            const allIds = filterDocumentsPage2().map((doc) => doc.id);
            setSelectedRowsPage2(allIds);
        } else {
            setSelectedRowsPage2([]);
        }
    };

    const handleAssignTo = () => {
        // Implement the functionality for assigning documents
        console.log('Assigning documents to employee');
    };

    const handleEdit = (id) => {
        // Implement the edit functionality
        console.log('Edit document with ID:', id);
    };

    const handleDelete = (id) => {
        // Implement the delete functionality
        console.log('Delete document with ID:', id);
    };

    // const downloadTableAsPDFPage2 = () => {
    //     const doc = new jsPDF();
    //     doc.autoTable({
    //         head: [
    //             ['Lead Id', 'Client Id', 'Client Name', 'Assigned Date', 'Status', 'Comment']
    //         ],
    //         body: filterDocumentsPage2().map(doc => [
    //             doc.lead_id,
    //             doc.client_id,
    //             doc.client_name,
    //             formatDateTime(doc.assigned_date),
    //             doc.status,
    //             doc.comment
    //         ]),
    //     });
    //     doc.save('employees.pdf');
    // };

    const downloadTableAsExcelPage2 = () => {
        const ws = XLSX.utils.json_to_sheet(filterDocumentsPage2().map(doc => ({
            'Lead Id': doc.lead_id,
            'Client Id': doc.client_id,
            'Client Name': doc.client_name,
            'Assigned Date': formatDateTime(doc.assigned_date),
            'Status': doc.status,
            'Comment': doc.comment
        })));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Employees');
        XLSX.writeFile(wb, 'employees.xlsx');
    };

    const indexOfLastItem = currentTablePage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filterDocumentsPage2().slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filterDocumentsPage2().length / itemsPerPage);

    const handleNextPage = () => {
        if (currentTablePage < totalPages) {
            setCurrentTablePage(currentTablePage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentTablePage > 1) {
            setCurrentTablePage(currentTablePage - 1);
        }
    };

    return (
        <div>
            <Adminsidebar />
            <div className="container">
                <div className="main-content">
                    <div className="dashboardalignn">
                        <div className="mt-4">
                            <div className="row mb-3">
                                <div className="col-sm-3">
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="dateFrom"
                                        value={filtersPage2.dateFrom}
                                        onChange={(e) => setFiltersPage2({ ...filtersPage2, dateFrom: e.target.value })}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="dateTo"
                                        value={filtersPage2.dateTo}
                                        onChange={(e) => setFiltersPage2({ ...filtersPage2, dateTo: e.target.value })}
                                    />
                                </div>
                                <div className="col-sm-3">
                                <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Employee Name"
                                        name="employeeName"
                                        value={filtersPage2.employeeName}
                                        onChange={(e) => setFiltersPage2({ ...filtersPage2, employeeName: e.target.value })}
                                    />
                                </div>
                                <div className="col-sm-3">
                                    <select
                                        className="form-select form-select-sm"
                                        name="status"
                                        value={filtersPage2.status}
                                        onChange={(e) => setFiltersPage2({ ...filtersPage2, status: e.target.value })}
                                    >
                                        <option value="">By Status</option>
                                        <option value="pending">Pending</option>
                                        <option value="completed">Completed</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mb-3">
                                <button className="btn btn-sm btn-primary" onClick={handleShowModal}>
                                    Upload Document
                                </button>
                                <button
                                    className="btn btn-sm btn-secondary ms-2"
                                    onClick={handleAssignTo}
                                    disabled={selectedRowsPage2.length === 0}
                                >
                                    Assign to Employee
                                </button>
                            </div>
                            <Modal show={showModal} onHide={handleCloseModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Upload Document</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formFile">
                                            <Form.Label>File</Form.Label>
                                            <Form.Control
                                                type="file"
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formDocumentType">
                                            <Form.Label>Document Type</Form.Label>
                                            <Form.Control
                                                type="text"
                                                value={selectedDocumentType}
                                                onChange={handleDocumentTypeChange}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="formComment">
                                            <Form.Label>Comment</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                value={selectedComment}
                                                onChange={handleCommentChange}
                                            />
                                        </Form.Group>
                                        <Button variant="secondary" onClick={handleCloseModal}>
                                            Close
                                        </Button>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>
                            <div className="table-responsive">
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input
                                                    type="checkbox"
                                                    onChange={handleSelectAllPage2}
                                                />
                                            </th>
                                            <th>Lead Id</th>
                                            <th>Client Id</th>
                                            <th>Client Name</th>
                                            <th>Assigned Date</th>
                                            <th>Status</th>
                                            <th>Comment</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {currentItems.map((document) => (
                                            <tr key={document.id}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedRowsPage2.includes(document.id)}
                                                        onChange={() => handleSelectRowPage2(document.id)}
                                                    />
                                                </td>
                                                <td>{document.lead_id}</td>
                                                <td>{document.client_id}</td>
                                                <td>{document.client_name}</td>
                                                <td>{formatDateTime(document.assigned_date)}</td>
                                                <td>{document.status}</td>
                                                <td>{document.comment}</td>
                                                <td>
                                                    <button className="btn btn-sm btn-warning" onClick={() => handleEdit(document.id)}>
                                                        <FaEdit />
                                                    </button>
                                                    <button className="btn btn-sm btn-danger ms-2" onClick={() => handleDelete(document.id)}>
                                                        <FaTrashAlt />
                                                    </button>
                                                    <button className="btn btn-sm btn-info ms-2" onClick={() => console.log('Download')}>
                                                        <FaDownload />
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <div className="pagination">
                                <button 
                                    className="btn btn-sm btn-secondary me-2"
                                    onClick={handlePrevPage}
                                    disabled={currentTablePage === 1}
                                >
                                    Prev
                                </button>
                                <span>Page {currentTablePage} of {totalPages}</span>
                                <button 
                                    className="btn btn-sm btn-secondary ms-2"
                                    onClick={handleNextPage}
                                    disabled={currentTablePage === totalPages}
                                >
                                    Next
                                </button>
                            </div>
                            <div className="mt-3">
                                {/* <button onClick={downloadTableAsPDFPage2} className="btn btn-sm btn-danger me-2">
                                    Export PDF
                                </button> */}
                                <button onClick={downloadTableAsExcelPage2} className="btn btn-sm btn-success">
                                    Export Excel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Callers;
