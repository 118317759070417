import React, { useState, useEffect } from 'react';
import Empsidebar from '../components/empsidebar';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import './empShifts.css';

export const EmpShifts = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [empId, setEmpId] = useState(''); // Store empId, not username!
  const [loginTime, setLoginTime] = useState(null);
  const [logoutTime, setLogoutTime] = useState(null);
  const [timeCounter, setTimeCounter] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const [remarks, setRemarks] = useState('');
  const [attendanceDate, setAttendanceDate] = useState(new Date().toLocaleDateString());

  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/login');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.email);
      setRole(decoded.role);

      if (decoded.role !== 'employee') {
        navigate('/login');
      }
    }
  }, []);

  // Fetch empId using the username (email)
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`https://api.clasptax.com/api/userdetails/${username}`);
        setEmpId(response.data.empId);  // Set empId from the response
      } catch (error) {
        console.error("Error fetching employee data", error);
      }
    };

    if (username) {
      fetchUserData();
    }
  }, [username]);

  const startTimer = () => {
    if (timerInterval) return; // Prevent starting multiple timers
    const loginTime = new Date().toLocaleTimeString();
    setLoginTime(loginTime);
    setTimerInterval(setInterval(() => {
      setTimeCounter(prevCounter => prevCounter + 1);
    }, 1000));
  };

  const stopTimer = async () => {
    if (!loginTime) {
      alert("Please log in before logging out.");
      return;
    }

    if (!empId) {  // Check if empId is present
      console.error('EmpId is missing.');
      alert('Employee ID is missing. Unable to log attendance.');
      return;
    }

    clearInterval(timerInterval);
    setTimerInterval(null);
    const logoutTime = new Date().toLocaleTimeString();
    setLogoutTime(logoutTime);

    // Calculate total working hours
    const workingHours = calculateWorkingHours(loginTime, logoutTime);

    // Send attendance data to backend
    try {
      const response = await axios.post('https://api.clasptax.com/api/empattendance', {
        empId,  // Use the correct empId here!
        loginTime,
        logoutTime,
        date: attendanceDate,
        workingHours: workingHours.toFixed(2),
        remarks,
      });
      console.log('Response:', response.data);
      alert('Attendance recorded successfully');
    } catch (error) {
      console.error('Error logging attendance:', error);
      alert('Error saving attendance. Please try again.');
    }
  };

  const calculateWorkingHours = (login, logout) => {
    const loginDateTime = new Date();
    const logoutDateTime = new Date();
    // Parse login and logout time
    const [loginHours, loginMinutes, loginSeconds] = login.split(':');
    loginDateTime.setHours(parseInt(loginHours));
    loginDateTime.setMinutes(parseInt(loginMinutes));
    loginDateTime.setSeconds(parseInt(loginSeconds));

    const [logoutHours, logoutMinutes, logoutSeconds] = logout.split(':');
    logoutDateTime.setHours(parseInt(logoutHours));
    logoutDateTime.setMinutes(parseInt(logoutMinutes));
    logoutDateTime.setSeconds(parseInt(logoutSeconds));

    return Math.abs(logoutDateTime - loginDateTime) / 36e5; // Convert ms to hours
  };

  return (
    <div>
      <Empsidebar />
      <div className="emp-container">
        <div className="emp-content-left">
          <div className="emp-info-card">
            <h5>Employee Time Tracker</h5>
            <p>Current Date: {attendanceDate}</p>
            <div className="emp-buttons">
              <button className="btn btn-success" onClick={startTimer}>Login</button>
              <button className="btn btn-danger" onClick={stopTimer}>Logout</button>
            </div>
            <div className="emp-time-counter">
              <h6>Time Counter</h6>
              <div className="counter-display">
                {`${Math.floor(timeCounter / 3600)}:${Math.floor((timeCounter % 3600) / 60)}:${timeCounter % 60}`}
              </div>
            </div>
            <div className="login-logout-times">
              <p><strong>Login Time:</strong> {loginTime || 'N/A'}</p>
              <p><strong>Logout Time:</strong> {logoutTime || 'N/A'}</p>
            </div>
            <div className="remarks-section">
              <label htmlFor="remarks">Remarks:</label>
              <input
                type="text"
                id="remarks"
                className="form-control"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                placeholder="Optional"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
