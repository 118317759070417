import React, { useState } from 'react';
import  Adminsidebar  from '../components/Adminsidebar';
import './documentspopup.css';

 const Documentspopup = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedYear, setSelectedYear] = useState('2024');
    const [documents, setDocuments] = useState({
        2024: [
            { type: 'W2 Form', uploadedOn: 'February 1, 2024', status: 'Reviewed', comment: 'W2 received from employer.' },
            { type: '1099 Form', uploadedOn: 'February 15, 2024', status: 'Pending Review', comment: 'Freelance income form.' },
            { type: 'Bank Statements', uploadedOn: 'March 10, 2024', status: 'Reviewed', comment: 'Bank statement for tax year.' },
        ],
        2023: [
            { type: 'W2 Form', uploadedOn: 'February 5, 2023', status: 'Reviewed', comment: 'W2 received from employer.' },
            { type: 'Investment Statements', uploadedOn: 'February 25, 2023', status: 'Reviewed', comment: 'Statements for investment income.' },
            { type: 'Bank Statements', uploadedOn: 'March 15, 2023', status: 'Pending Review', comment: 'Bank statement for tax year.' },
        ],
        2022: [
            { type: 'W2 Form', uploadedOn: 'February 10, 2022', status: 'Reviewed', comment: 'W2 received from employer.' },
            { type: '1099 Form', uploadedOn: 'February 20, 2022', status: 'Reviewed', comment: 'Freelance income form.' },
            { type: 'Bank Statements', uploadedOn: 'March 5, 2022', status: 'Reviewed', comment: 'Bank statement for tax year.' },
        ],
    });

 

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    return (
        <div>
            <Adminsidebar/>
            <div className="tax-organizer-dashboard">
                <div className="tax-organizer-header">
                    <h1>Documents</h1>
                
                </div>

                <div className="tax-organizer-year-selector">
                    <label htmlFor="year">Select Year: </label>
                    <select id="year" value={selectedYear} onChange={handleYearChange} className="tax-organizer-year-dropdown">
                        <option value="2024">2024</option>
                        <option value="2023">2023</option>
                        <option value="2022">2022</option>
                    </select>
                </div>

                <ul className="nav nav-tabs tax-organizer-nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item tax-organizer-nav-item" role="presentation">
                        <a className="nav-link tax-organizer-nav-link active" id="client-docs-tab" data-toggle="tab" href="#client-docs" role="tab" aria-controls="client-docs" aria-selected="true">
                            Client Uploaded Documents
                        </a>
                    </li>
                    <li className="nav-item tax-organizer-nav-item" role="presentation">
                        <a className="nav-link tax-organizer-nav-link" id="tax-experts-tab" data-toggle="tab" href="#tax-experts" role="tab" aria-controls="tax-experts" aria-selected="false">
                            Tax Experts Uploaded Documents
                        </a>
                    </li>
                </ul>

                <div className="tab-content tax-organizer-tab-content" id="myTabContent">
                    <div className="tab-pane fade show active tax-organizer-tab-pane" id="client-docs" role="tabpanel" aria-labelledby="client-docs-tab">
                        <h4>Documents for Year: {selectedYear}</h4>
                        <table className="table tax-organizer-table">
                            <thead>
                                <tr>
                                    <th>Document Type</th>
                                    <th>Uploaded On</th>
                                    <th>Status</th>
                                    <th>Comments</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {documents[selectedYear].map((doc, index) => (
                                    <tr key={index}>
                                        <td>{doc.type}</td>
                                        <td>{doc.uploadedOn}</td>
                                        <td>{doc.status}</td>
                                        <td>{doc.comment}</td>
                                        <td>
                                            <button className="btn btn-secondary tax-organizer-download-button">Download</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="tab-pane fade tax-organizer-tab-pane" id="tax-experts" role="tabpanel" aria-labelledby="tax-experts-tab">
                        <h4 className="mt-3 ml-3">No Records Found!</h4>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Documentspopup;