import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './Editdoc.css';

const EditDoc = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { clientid } = location.state || {}; // Default to empty object if state is undefined

  const [doc, setDoc] = useState({
    clientName: '',
    email: '',
    contact: '',
    status: '',
    assignedTo: '',
    addComment: ''
  });

  useEffect(() => {
    if (!clientid) {
      console.error('Client ID is missing from location state.');
      return;
    }

    const fetchDocument = async () => {
      try {
        const response = await axios.get(`https://api.clasptax.com/leads/${clientid}`);
        if (response.data) {
          console.log('Fetched Document:', response.data); // Debugging line
          setDoc({
            clientName: response.data.clientname || '',
            email: response.data.email || '',
            contact: response.data.contact || '',
            status: response.data.status || '',
            assignedTo: response.data.assigned_to || '',
            addComment: response.data.comments || ''
          });
        } else {
          console.error('No data received from server');
        }
      } catch (error) {
        console.error('Error fetching document details:', error);
      }
    };

    fetchDocument();
  }, [clientid]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDoc({ ...doc, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://api.clasptax.com/leads/${clientid}`, doc);
      alert('Document updated successfully');
      navigate('/leads'); // Navigate back to leads page
    } catch (error) {
      console.error('Error updating document:', error);
      alert('Failed to update document. Please check the console for details.');
    }
  };

  return (
    <div className="edit-doc-container">
      <h2>Edit Document</h2>
      <form className="edit-doc-form" onSubmit={handleSubmit}>
        <div>
          <label>Client Name:</label>
          <input
            type="text"
            name="clientName"
            value={doc.clientName}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            name="email"
            value={doc.email}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Contact:</label>
          <input
            type="text"
            name="contact"
            value={doc.contact}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Status:</label>
          <input
            type="text"
            name="status"
            value={doc.status}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Assigned To:</label>
          <input
            type="text"
            name="assignedTo"
            value={doc.assignedTo}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Comments:</label>
          <textarea
            name="addComment"
            value={doc.addComment}
            onChange={handleChange}
          />
        </div>
        <button type="submit">Save Changes</button>
      </form>
    </div>
  );
};

export default EditDoc;
