import React, { useState } from 'react';
import './footer.css';
import { Link } from 'react-router-dom';
import logoimg from '../images/img123.png';
import foterlogo from '../images/irs.png'


export const Footer = () => {
  const [email, setEmail] = useState('');
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState('');
  const [emaill, setEmaill] = useState('info@clasptax.com');

  const handleEmailClickk = () => {
    window.location.href = `mailto:${emaill}`;
  };



  const handleSubscribe = async () => {
    try {
      const response = await fetch('http://localhost:3002/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        setIsSubscribed(true);
      } else {
        const data = await response.json();
        setSubscriptionError(data.message);
      }
    } catch (error) {
      console.error('Error subscribing:', error);
    }
  };

  return (
    <div >
      <footer>
        <div class=" primary">
          <div class="column about getintouch">
          <div  className='logoggggo'>
          <img src={logoimg} alt='' className='lli-l'/>
          
          <p >Our Strength Your Numbers</p>

          </div>
          
           
          </div>
          <div class="column links">
            <h8>Quick Links</h8>
            <ul className='ul'>
            <li>
                    <Link to="/" >Home</Link>
                    </li>
                    <li>
                    <Link to="/aboutus">About us</Link>
                    </li>
                    <li>
                    <Link to="/se">Service</Link>
                    </li>
                    <li>
                    <Link to="/price">Price</Link>
                    </li>
                    
                    <li>
                    <Link to="/refundstatus">Refund Status</Link>
                    </li>
            </ul>
          </div>

          {/* <div class="column subscribe">
            <h8 className="pt-4 pb-3">Subscribe</h8>
            {isSubscribed ? (
              <p>Subscribed!</p>
            ) : (
              <div>
                <input
                  type="email"
                  placeholder="Your email id here"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  
                />
                <button className='button1' >
                  Subscribe
                </button>
              </div>
            )}
            {subscriptionError && (
              <p style={{ color: 'red' }}>{subscriptionError}</p>
            )}
            
          </div> */}

          
          <div class="column getintouch">
          <h8>Get in Touch</h8>
          <br/>
            <h5  className='phonenumbbb'>Phone Number : +1(972)-220-9783</h5>
           
            <p onClick={handleEmailClickk}>Email: {emaill}</p>
            <img src={foterlogo} alt='' className='fotterlogo'/>
          </div>
        </div>
        <div class="row copyright justify-content-center">
          <p>Copyright &copy; 2024 Clasp Tax | All Rights Reserved</p>
        </div>
      </footer>
     

    </div>
  );
};
