import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import Empsidebar from '../components/empsidebar';
import './empclients.css';
// import { YearContext } from '../context/usecontext';
// import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { FaEdit, FaTrashAlt, FaDownload, FaEye } from 'react-icons/fa';
import axios from "axios";
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
 
 
const EmpClients = () => {
    const navigate = useNavigate();
    // const { selectedyear } = useContext(YearContext);

    
 
    const [clientDocuments, setClientDocuments] = useState([]);
    const [filters, setFilters] = useState({
        dateFrom: '',
        dateTo: '',
        clientName: '',
        status: ''
    });
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
 
    const [clientData,setClientData] = useState([])
    const[leadData,setLeadData] = useState([]);
    const [role, setRole] = useState('');
  const [username, setUsername] = useState('');
useEffect(() => {
  const token = Cookies.get('token');
  
  if (!token) {
    navigate('/login');
  } else {
    const decoded = jwtDecode(token);
    setUsername(decoded.email);
    setRole(decoded.role);
  }
}, []);

    const fetchClientData = async () => {
        try {
            console.log(username)
            const response = await axios.get(`https://api.clasptax.com/empclients/${username}`)
            if (response.data.success) {
                
                setClientData(response.data.clientsResult);
                console.log(response.data.clientsResult)
               
            }
        } catch (error) {
            console.log(error)
        }
    }
 
    useEffect(() => {
        
        fetchClientData();
 
    }, [username]);
 
    const getLeadInfo = (email) => {
        const lead = leadData.find(lead => lead.email === email);
        if (lead) {
            return {
                inLeads: "YES",
                assignedTo: lead.assigned_to,
                assignedAt:lead.assigned_date,
 
            };
        }
        return {
            inLeads: "NO",
            assignedTo: "Unassigned",
            assignedAt:"Unassigned date",
        };
    };
 
    
 
    
 
 
    
    const handleonExport = () => {
        // Create a new worksheet from the client data
        const worksheetData = clientData.map((doc) => {
            const leadInfo = getLeadInfo(doc.email);
            return {
                "Client ID": doc.userID,
                "Client Name": doc.userName,
                "Email": doc.email,
                "Contact": doc.phoneNumber,
                "Alternate Contact": doc.alternateContact,
                "In Leads": leadInfo.inLeads,
                "Assigned To": leadInfo.assignedTo,
                "Assigned At": leadInfo.assignedAt || "-",
                "Registered At": doc.registeredAt,
                "Status": doc.status,
                "Comments": doc.comments
            };
        });
        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Client Data");
        XLSX.writeFile(workbook, "ClientData.xlsx");
    };
 
      
 
    // Assign selected employees to selected clients
    
 
 
 
 
 
 
 
 
    const formatDateTime = (dateTime) => new Date(dateTime).toLocaleString();
 
    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };
 
    const filterDocuments = () => {
        return clientDocuments.filter((doc) => {
            const { dateFrom, dateTo, clientName, status } = filters;
            const documentDate = new Date(doc.registered_at);
            const fromDate = dateFrom ? new Date(dateFrom) : new Date(0);
            const toDate = dateTo ? new Date(dateTo) : new Date();
            return (
                (dateFrom === '' || documentDate >= fromDate) &&
                (dateTo === '' || documentDate <= toDate) &&
                (clientName === '' || doc.client_name.toLowerCase().includes(clientName.toLowerCase())) &&
                (status === '' || doc.status === status)
            );
        });
    };
 
    
    // const downloadTableAsPDF = () => {
    //     const doc = new jsPDF();
    //     doc.autoTable({
    //         head: [['Client ID', 'Client Name', 'Email', 'Contact', 'Status', 'Comments']],
    //         body: filterDocuments().map(doc => [
    //             doc.client_id, doc.client_name, doc.email, doc.contact, doc.status, doc.add_comment
    //         ]),
    //     });
    //     doc.save('documents.pdf');
    // };
 
    
    const handleView = (id) => {
        navigate('/taxorganizer', { state: { clientId: id } });
    };
 
    const currentData = filterDocuments().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(filterDocuments().length / itemsPerPage);
 
    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) =>
            direction === 'next' ? Math.min(prevPage + 1, totalPages) : Math.max(prevPage - 1, 1)
        );
    };
 
    return (
        <div>
             <Empsidebar />
            <div className="container" style={{ marginLeft: "300px", width: "700px" }}>
                <div className="main-content">
                    <div className="mt-4">
                        <div className="row mb-3">
                            <div className="col-sm-3">
                                <input type="date" className="form-control form-control-sm" name="dateFrom" value={filters.dateFrom} onChange={handleFilterChange} />
                            </div>
                            <div className="col-sm-3">
                                <input type="date" className="form-control form-control-sm" name="dateTo" value={filters.dateTo} onChange={handleFilterChange} />
                            </div>
                            <div className="col-sm-3">
                                <input type="text" className="form-control form-control-sm" placeholder="Client Name" name="clientName" value={filters.clientName} onChange={handleFilterChange} />
                            </div>
                            <div className="col-sm-3">
                                <select className="form-control form-control-sm" name="status" value={filters.status} onChange={handleFilterChange}>
                                    <option value="">All Statuses</option>
                                    <option value="completed">Completed</option>
                                    <option value="pending">Pending</option>
                                    <option value="declined">Declined</option>
                                </select>
                            </div>
                        </div>
                        <div className='my-3 d-flex gap-2'>
                            
                            <button className="btn btn-secondary" onClick={handleonExport}>Export Excel</button>
                        </div>
                        <div className="table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                    
                                        <th>Client ID</th>
                                        <th>Client Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Alternate Contact</th>
                                     
                                       
                                       
                                        <th>Registered At</th>
                                        <th>Status</th>
                                        <th>Comments</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {clientData.map((doc) => {
                                        
                                        return(
                                        <tr key={doc.id}>
                                            
                                            <td>{doc.client_id}</td>
                                            <td>{doc.client_name}</td>
                                            <td>{doc.email}</td>
                                            <td>{doc.contact}</td>
                                            <td>{doc.alternateContact}</td>
                                        
                                        
                                        <td>{doc.registered_at}</td>
                                            <td>{doc.status}</td>
                                          <td>{doc.comments}</td>
                                           
                                            <td>
                                                <button className="btn btn-info btn-sm" onClick={() => handleView(doc.id)}><FaEye /></button>
                                                <button className="btn btn-warning btn-sm"><FaEdit /></button>
                                                <button className="btn btn-danger btn-sm"><FaTrashAlt /></button>
                                                <button className="btn btn-secondary btn-sm"><FaDownload /></button>
                                            </td>
                                        </tr>
                                   ) })}
                                </tbody>
                            </table>
                        </div>
                        <div className="pagination">
                            <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>Previous</button>
                            <span>Page {currentPage} of {totalPages}</span>
                            <button onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
 
export default EmpClients;
