import React, { useState, useEffect } from 'react';
import Adminsidebar from '../components/Adminsidebar';
import './employeesinfo.css';
import { FaEdit, FaTrashAlt, FaDownload, FaEye, FaPlus } from 'react-icons/fa';
import * as XLSX from 'xlsx';
import axios from 'axios';

const Employeinformation = () => {
    const [employeeData, setEmployeeData] = useState([]);
    const [loading, setLoading] = useState(false); // For loading state
    const [filters, setFilters] = useState({
        dateFrom: '',
        dateTo: '',
        employeeName: '',
        status: ''
    });
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [showAddEmployee, setShowAddEmployee] = useState(false);
    const [newEmployee, setNewEmployee] = useState({
        username: '',
        email: '',
        workEmail: '',
        contact: '',
        role: '',
        department: '',
        password : '',
        targetAssigned: '',
        dateHired: '',
        status: 'Active'
    });
    
    // Fetch employee data from the backend API
    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                const response = await axios.get('https://api.clasptax.com/api/getemployees');
                setEmployeeData(response.data); // Set the employee data into state
            } catch (error) {
                console.error('Error fetching employee data:', error);
            }
        };
        fetchEmployeeData();
    }, []);
    
    const formatDateTime = (dateTime) => new Date(dateTime).toLocaleDateString();

    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };

    const filterData = () => {
        return employeeData.filter((employee) => {
            const { dateFrom, dateTo, employeeName, status } = filters;
            const hireDate = new Date(employee.dateHired);
            const fromDate = dateFrom ? new Date(dateFrom) : new Date(0);
            const toDate = dateTo ? new Date(dateTo) : new Date();
            return (
                (dateFrom === '' || hireDate >= fromDate) &&
                (dateTo === '' || hireDate <= toDate) &&
                (employeeName === '' || employee.username.toLowerCase().includes(employeeName.toLowerCase())) &&
                (status === '' || employee.status === status)
            );
        });
    };

    const handleAddEmployee = async (e) => {
        e.preventDefault();
        
        try {
            const response = await fetch('https://api.clasptax.com/api/employees', { // Update URL as needed
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(newEmployee)
            });
            const data = await response.json();
            
            if (response.ok) {
                alert('Employee added successfully!');
                // Reset form fields
                setNewEmployee({
                    username: '',
                    email: '',
                    workEmail: '',
                    contact: '',
                    role: '',
                    password: '',
                    department: '',
                    targetAssigned: '',
                    dateHired: '',
                    status: 'Active'
                });
                setShowAddEmployee(false); // Hide form after adding
            } else {
                alert(data.message);
            }
        } catch (error) {
            console.error('Error adding employee:', error);
        }
    };

    const downloadTableAsExcel = () => {
        const ws = XLSX.utils.json_to_sheet(filterData().map(employee => ({
            'ID': employee.id,
            'Name': employee.username,
            'Email': employee.email,
            'Work Email': employee.workEmail,
            'Contact': employee.contact,
            'Role': employee.role,
            'Department': employee.department,
            'Target Assigned': employee.targetAssigned,
            'Date Hired': formatDateTime(employee.dateHired),
            'Status': employee.status,
        })));
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Employees');
        XLSX.writeFile(wb, 'employees.xlsx');
    };

    const currentData = filterData().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(filterData().length / itemsPerPage);

    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) =>
            direction === 'next' ? Math.min(prevPage + 1, totalPages) : Math.max(prevPage - 1, 1)
        );
    };

    return (
        <div className="refer-and-earn">
            <Adminsidebar />
            <div className="container" style={{ marginLeft: "300px", width: "700px" }}>
                <div className="main-content">
                    <button className="btn btn-primary mb-3" onClick={() => setShowAddEmployee(!showAddEmployee)}>
                        <FaPlus /> Add Employee
                    </button>

                    {showAddEmployee && (
                        <div className="add-employee-form mb-3">
                            <h3>Add New Employee</h3>
                            <form onSubmit={handleAddEmployee}>
                                <div className="form-group">
                                    <label>Username</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newEmployee.username}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, username: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        value={newEmployee.email}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, email: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Work Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        value={newEmployee.workEmail}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, workEmail: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
    <label>Password</label>
    <input
        type="password"
        className="form-control"
        value={newEmployee.password}
        onChange={(e) => setNewEmployee({ ...newEmployee, password: e.target.value })}
        required
    />
</div>

                                <div className="form-group">
                                    <label>Contact</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newEmployee.contact}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, contact: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Role</label>
                                    <select
                                        className="form-control"
                                        value={newEmployee.role}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, role: e.target.value })}
                                    >
                                        <option value="">Select Role</option>
                                        <option value="Client Relationship Executive">Client Relationship Executive</option>
                                        <option value="Senior Client Relationship Executive">Senior Client Relationship Executive</option>
                                        <option value="Preparer">Preparer</option>
                                        <option value="Reviewer">Reviewer</option>
                                        <option value="Team Lead">Team Lead</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Department</label>
                                    <select
                                        className="form-control"
                                        value={newEmployee.department}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, department: e.target.value })}
                                    >
                                        <option value="">Select Department</option>
                                        <option value="Marketing">Marketing</option>
                                        <option value="Sales">Sales</option>
                                        <option value="HR">HR</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Target Assigned</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={newEmployee.targetAssigned}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, targetAssigned: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Date Hired</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        value={newEmployee.dateHired}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, dateHired: e.target.value })}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Status</label>
                                    <select
                                        className="form-control"
                                        value={newEmployee.status}
                                        onChange={(e) => setNewEmployee({ ...newEmployee, status: e.target.value })}
                                    >
                                        <option value="Active">Active</option>
                                        <option value="Inactive">Inactive</option>
                                    </select>
                                </div>
                                <button type="submit" className="btn btn-primary">
                                    Add Employee
                                </button>
                            </form>
                        </div>
                    )}

                    <div className="filter-section mb-3">
                        <h3>Filter Employees</h3>
                        <div className="form-group">
                            <label>From Date</label>
                            <input
                                type="date"
                                className="form-control"
                                name="dateFrom"
                                value={filters.dateFrom}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>To Date</label>
                            <input
                                type="date"
                                className="form-control"
                                name="dateTo"
                                value={filters.dateTo}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Employee Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="employeeName"
                                value={filters.employeeName}
                                onChange={handleFilterChange}
                            />
                        </div>
                        <div className="form-group">
                            <label>Status</label>
                            <select
                                className="form-control"
                                name="status"
                                value={filters.status}
                                onChange={handleFilterChange}
                            >
                                <option value="">All</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                            </select>
                        </div>
                    </div>
                    <div className="container" style={{ marginLeft: "300px", width: "700px" }}>
                <div className="main-content">
                    <h3>Employee List</h3>
                    {loading ? (
                        <p>Loading employees...</p>
                    ) : (
                        <div className="employee-table">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Work Email</th>
                                        <th>Contact</th>
                                        <th>Role</th>
                                        <th>Department</th>
                                        <th>Target</th>
                                        <th>Date Hired</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeData.length > 0 ? (
                                        employeeData.map((employee) => (
                                            <tr key={employee.id}>
                                                <td>{employee.id}</td>
                                                <td>{employee.username}</td>
                                                <td>{employee.email}</td>
                                                <td>{employee.workEmail}</td>
                                                <td>{employee.phoneNumber}</td>
                                                <td>{employee.role}</td>
                                                <td>{employee.department}</td>
                                                <td>{employee.targetAssigned}</td>
                                                <td>{formatDateTime(employee.dateHired)}</td>
                                                <td>{employee.status}</td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="10">No employees found.</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
                    <div className="pagination">
                        <button onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>
                            Previous
                        </button>
                        <span>{`Page ${currentPage} of ${totalPages}`}</span>
                        <button onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>
                            Next
                        </button>
                    </div>

                    <div className="download-section">
                        <button className="btn btn-success" onClick={downloadTableAsExcel}>
                            <FaDownload /> Download as Excel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Employeinformation;
