


import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Empsidebar from '../components/empsidebar';
// import './leads.css';
import 'jspdf-autotable';
import * as xlsx from "xlsx";
import { FaEdit, FaTrashAlt, FaDownload, FaEye, FaUpload } from 'react-icons/fa';
import axios from 'axios';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
 
const Empleads = () => {
const navigate = useNavigate();
const [role, setRole] = useState('');
  const [username, setUsername] = useState('');
useEffect(() => {
  const token = Cookies.get('token');
  
  if (!token) {
    navigate('/login');
  } else {
    const decoded = jwtDecode(token);
    setUsername(decoded.email);
    setRole(decoded.role);
  }
}, []);
 
    const [documents, setDocuments] = useState([]);
    const [filters, setFilters] = useState({
        dateFrom: '',
        dateTo: '',
        clientName: '',
        status: ''
    });
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedRows, setSelectedRows] = useState([]);
    const [employeeData, setEmployeeData] = useState([]);
 
    const [selectedClients, setSelectedClients] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState("");
    useEffect(() => {
        fetchDocuments(); // Fetch documents when the component mounts
    }, [username]);
 
    const fetchDocuments = async () => {
        try {
    const response = await axios.get(`https://api.clasptax.com/empleads/${username}`);
     if(response.data.success){
      setDocuments(response.data.leadsResult);
     }   
        } catch (error) {
            console.error('Error fetching documents:', error);
        }
    };
 
 
    const formatDateTime = (dateTime) => new Date(dateTime).toLocaleString();
 
    const handleFilterChange = (e) => {
        setFilters({ ...filters, [e.target.name]: e.target.value });
    };
 
    const filterDocuments = () => {
        return documents.filter((doc) => {
            const { dateFrom, dateTo, clientName, status } = filters;
            const documentDate = new Date(doc.created_at);
            const fromDate = dateFrom ? new Date(dateFrom) : new Date(0);
            const toDate = dateTo ? new Date(dateTo) : new Date();
            return (
                (dateFrom === '' || documentDate >= fromDate) &&
                (dateTo === '' || documentDate <= toDate) &&
                (clientName === '' || doc.clientname.toLowerCase().includes(clientName.toLowerCase())) &&
                (status === '' || doc.status === status)
            );
        });
    };
 
 

 
    const handleonExport = () => {
        var wb = xlsx.utils.book_new(),
          ws = xlsx.utils.json_to_sheet(documents);
     
        xlsx.utils.book_append_sheet(wb, ws, "MySheet1");
        xlsx.writeFile(wb, "MyExcel.xlsx");
      };
 

    const handleDeleteDoc = async (clientid) => {
        if (window.confirm('Are you sure you want to delete this document?')) {
            try {
                await axios.delete(`https://api.clasptax.com/leads/${clientid}`);
                alert('Document deleted successfully');
                fetchDocuments(); // Reload the table after deletion
            } catch (error) {
                console.error('Error deleting document:', error);
            }
        }
    };
 
 
 
    const handleViewDoc = (clientid) => {
        navigate('/viewdoc', { state: { docId: clientid } });
    };
 
    const handleEditDoc = (clientid) => {
        navigate('/editdoc', { state: { docId: clientid } });
    };
 

    const currentData = filterDocuments().slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const totalPages = Math.ceil(filterDocuments().length / itemsPerPage);
 
    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) =>
            direction === 'next' ? Math.min(prevPage + 1, totalPages) : Math.max(prevPage - 1, 1)
        );
    };
 
    return (
        <div>
            <Empsidebar />
            <div className="container" style={{ marginLeft: "300px", width: "700px" }}>
                <div className="main-content">
                    <div className="mt-4">
                   
                        {/* <button onClick={handleUploadItems} type="button" className='btn btn-success'><FaUpload />Upload</button> */}
 
 
                        <div className="row mb-3">
                            <div className="col-sm-3">
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    name="dateFrom"
                                    value={filters.dateFrom}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="col-sm-3">
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    name="dateTo"
                                    value={filters.dateTo}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="col-sm-3">
                                <input
                                    type="text"
                                    className="form-control form-control-sm"
                                    name="clientName"
                                    placeholder="Client Name"
                                    value={filters.clientName}
                                    onChange={handleFilterChange}
                                />
                            </div>
                            <div className="col-sm-3">
                                <select
                                    className="form-control form-control-sm"
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">All Statuses</option>
                                    <option value="completed">Completed</option>
                                    <option value="pending">Pending</option>
                                    <option value="declined">Declined</option>
                                </select>
                            </div>
                        </div>
                        <div className='my-3 d-flex gap-2'>
                            {/* <div className="dropdown">
                            <button
                                className="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"
                                disabled={selectedRows1.length === 0}
                            >
                                Assign To ({selectedRows1.length})
                            </button>
                         
                           
                            <ul className='dropdown-menu'>
                                {employeeData.map((employee, index) => (
                                    <li key={index} className='dropdown-item'>
                                      <div className='d-flex gap-2'>
                                      <div>
                                      <input
                                                type="radio"
                                                value={employee.username}
                                                checked={selectedEmployees === (employee.id)}
                                                onChange={() => handleEmployeeChange(employee)}
                                            />
                                      </div>
                                       <div> {employee.username}</div>
                                      </div>
                                         
                                    </li>
                                ))}
                                   <button onClick={handleOkClick} className="btn btn-primary btn-sm"> Okay</button>
 
                            </ul>
                            </div> */}
                            <button className="btn btn-secondary" onClick={handleonExport}>Export Excel</button>
                        </div>
 
 
                        <div className="table-container">
                            <table className="table">
                                <thead>
                                    <tr>
                                        {/* <th>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={checkAll}
                                                onChange={handleCheckAll}
                                            />
                                            Check All
                                        </th> */}
                                        <th>Lead Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                        <th>Status</th>
                                        {/* <th>Assigned To</th> */}
                                        <th>Created At</th>
                                        <th>Assigned Date</th>
                                        <th>Comments</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {currentData.map((doc) => (
                                        <tr key={doc.id}>
                                            {/* <td>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={selectedRows1.includes(doc.id)}
                                                    onChange={() => handleCheckboxChangeid(doc.id)}
                                                />
                                            </td> */}
                                            <td>{doc.client_name}</td>
                                            <td>{doc.email}</td>
                                            <td>{doc.contact}</td>
                                            <td>{doc.status}</td>
                                            {/* <td>
                                            {doc.assigned_to ? doc.assigned_to : "Unassigned"}
                                             
                                                </td> */}
                                            <td>{formatDateTime(doc.created_at)}</td>
                                            <td>{doc.assigned_date}</td>
                                            <td>{doc.comment}</td>
                                            <td>
                                                <button className="btn btn-info btn-sm" onClick={() => handleViewDoc(doc.clientid)}><FaEye /></button>
                                                <button className="btn btn-warning btn-sm mx-1" onClick={() => handleEditDoc(doc.clientid)}><FaEdit /></button>
                                                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteDoc(doc.clientid)}><FaTrashAlt /></button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
 
                        <div className="pagination mt-3">
                            <button className="btn btn-secondary" onClick={() => handlePageChange('prev')} disabled={currentPage === 1}>Previous</button>
                            <span className="mx-2">Page {currentPage} of {totalPages}</span>
                            <button className="btn btn-secondary" onClick={() => handlePageChange('next')} disabled={currentPage === totalPages}>Next</button>
                        </div>
                    </div>
                </div>
            </div>
 
       
 
        </div>
    );
};
 
export default Empleads;

