import React, { useEffect } from 'react';
import './staterefundstatus.css';
import { Link } from 'react-router-dom';
 
export const Staterefundstatus = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div >
        <div className='pricetable'>
        <div className='backgroundImageStylee'>
        <p>State Refund Status</p>
      </div>
        <table class="table table-striped text-center">
            <thead>
                <tr>
                    <th scope="col">States</th>
                    <th scope="col">Link </th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr><td>Alabama (AL)</td><Link to="https://myalabamataxes.alabama.gov/_/" target=' '>Click Here</Link></tr>
                        <tr><td>Alaska (AK)</td><td>None</td></tr>
                        <tr><td>Arizona (AZ)</td><Link to="https://www.aztaxes.gov/Home/Login?aspxerrorpath=/Individual/CheckRefund.aspx" target=' '>Click Here</Link></tr>
                        <tr><td>Arkansas (AR)</td><Link to="https://atap.arkansas.gov/_/#1" target=' '>Click Here</Link></tr>
                        <tr><td>California (CA)</td><Link to="https://www.ftb.ca.gov/refund/index.asp?WT.mc_id=akRefund" target=' '>Click Here</Link></tr>
                        <tr><td>Colorado (CO)</td><Link to="https://www.colorado.gov/revenueonline/_/#1" target=' '>Click Here</Link></tr>
                        <tr><td>Connecticut (CT)</td><Link to="https://drsindtax.ct.gov/AUT/welcomeindividual.aspx" target=' '>Click Here</Link></tr>
                        <tr><td>Delaware (DE)</td><Link to="https://dorweb.revenue.delaware.gov/scripts/refinq/refinq.dll" target=' '>Click Here</Link></tr>
                        <tr><td>District of Columbia (DC)</td><Link to="https://mytax.dc.gov/_/#1" target=' '>Click Here</Link></tr>
                        <tr><td>Florida (FL)</td><td>None</td></tr>
                        <tr><td>Georgia (GA)</td><Link to="https://gtc.dor.ga.gov/_/#2" target=' '>Click Here</Link></tr>
                        <tr><td>Hawaii (HI)</td><Link to="https://hitax.hawaii.gov/_/" target=' '>Click Here</Link></tr>
                        <tr><td>Idaho (ID)</td><Link to="https://tax.idaho.gov/taxes/income-tax/individual-income/refund/?qp=y" target=' '>Click Here</Link></tr>
                        <tr><td>Illinois (IL)</td><Link to="https://mytax.illinois.gov/_/" target=' '>Click Here</Link></tr>
                        <tr><td>Indiana (IN)</td><Link to="https://www.in.gov/dor/individual-income-taxes/check-the-status-of-your-refund/" target=' '>Click Here</Link></tr>
                        <tr><td>Iowa (IA)</td><Link to="https://apps.idr.iowa.gov/wheresmyrefund/" target=' '>Click Here</Link></tr>
                        <tr><td>Kansas (KS)</td><Link to="https://www.kdor.ks.gov/Apps/kcsc/increfundstatus.aspx" target=' '>Click Here</Link></tr>
                        <tr><td>Kentucky (KY)</td><Link to="https://iitrefundstatus.ky.gov/TRFWeb/index.jsp" target=' '>Click Here</Link></tr>
                        <tr><td>Louisiana (LA)</td><Link to="https://latap.revenue.louisiana.gov/_/" target=' '>Click Here</Link></tr>
                        <tr><td>Maine (ME)</td><Link to="https://portal.maine.gov/refundstatus/refund" target=' '>Click Here</Link></tr>
                        <tr><td>Maryland (MD)</td><Link to="https://interactive.marylandtaxes.com/INDIV/refundstatus/home.aspx" target=' '>Click Here</Link></tr>
                        <tr><td>Massachusetts (MA)</td><Link to="https://www.mass.gov/how-to/check-the-status-of-your-ma-income-tax-refund" target=' '>Click Here</Link></tr>
                        <tr><td>Michigan (MI)</td><Link to="https://www.michigan.gov/taxes/iit/refund" target=' '>Click Here</Link></tr>
                        <tr><td>Minnesota (MN)</td><Link to="https://victorious-forest-083799e10.3.azurestaticapps.net/?_event_transid=c5bdc648b0e7a58142e575f673a8e2a61524a640cabc3bff019fc66df5f6d936#1" target=' '>Click Here</Link></tr>
                        <tr><td>Mississippi (MS)</td><Link to="https://tap.dor.ms.gov/_/#1" target=' '>Click Here</Link></tr>
                        <tr><td>Missouri (MO)</td><Link to="https://dors.mo.gov/tax/taxinq/welcome.jsp" target=' '>Click Here</Link></tr>
                        <tr><td>Montana (MT)</td><Link to="https://tap.dor.mt.gov/_/#1" target=' '>Click Here</Link></tr>
                        <tr><td>Nebraska (NE)</td><Link to="https://ndr-refundstatus.ne.gov/refundstatus/public/search.faces" target=' '>Click Here</Link></tr>
                        <tr><td>Nevada (NV)</td><Link to="https://tax.nv.gov/" target=' '>Click Here</Link></tr>
                        <tr><td>New Hampshire (NH) </td><td>None</td></tr>
                        <tr><td>New Jersey (NJ)</td><Link to="https://www.nj.gov/treasury/taxation/emailfaqs.shtml#ITRates" target=' '>Click Here</Link></tr>
                        <tr><td>New Mexico (NM)</td><Link to="https://www.tax.newmexico.gov/individuals/online-services-overview/where-is-my-refund/" target=' '>Click Here</Link></tr>
                        <tr><td>New York (NY)</td><Link to="https://www.tax.ny.gov/pit/file/refund.htm" target=' '>Click Here</Link></tr>
                        <tr><td>North Carolina (NC)</td><Link to="https://eservices.dor.nc.gov/wheresmyrefund/SelectionServlet" target=' '>Click Here</Link></tr>
                        <tr><td>North Dakota (ND)</td><Link to="https://www.tax.nd.gov/user/individuals/overview/where-s-my-refund" target=' '>Click Here</Link></tr>
                        <tr><td>Ohio (OH)</td><Link to="https://www.tax.state.oh.us/IFILE/WheresMyRefundWeb/wheresmyrefund.jsp" target=' '>Click Here</Link></tr>
                        <tr><td>Oklahoma (OK)</td><Link to="https://www.ok.gov/tax/Individuals/Income_Tax/Filing_Information/How_to_Check_on_a_Refund/" target=' '>Click Here</Link></tr>
                        <tr><td>Oregon (OR)</td><Link to="https://revenueonline.dor.oregon.gov/tap/_/" target=' '>Click Here</Link></tr>
                        <tr><td>Pennsylvania (PA)</td><Link to="https://www.doreservices.state.pa.us/PITServices/WheresMy/Refund" target=' '>Click Here</Link></tr>
                        <tr><td>Rhode Island (RI)</td><Link to="https://www.ri.gov/taxation/refund/" target=' '>Click Here</Link></tr>
                        <tr><td>South Carolina (SC)</td><Link to="https://refundstatus.dor.sc.gov/refund/refund.aspx" target=' '>Click Here</Link></tr>
                        <tr><td>South Dakota (SD) </td><td>None</td></tr>
                        <tr><td>Texas (TX) </td><td>None</td></tr>
                        <tr><td>Tennessee (TN) </td><td>None</td></tr>
                        <tr><td>Utah (UT)</td><Link to="https://tap.tax.utah.gov/TaxExpress/_/#1" target=' '>Click Here</Link></tr>
                        <tr><td>Vermont (VT)</td><Link to="https://myvtax.vermont.gov/_/" target=' '>Click Here</Link></tr>
                        <tr><td>Virginia (VA)</td><Link to="https://www.tax.virginia.gov/wheres-my-refund" target=' '>Click Here</Link></tr>
                        <tr><td>Washington (WA)</td><td>None</td></tr>
                        <tr><td>West Virginia (WV)</td><Link to="https://mytaxes.wvtax.gov/_/#1" target=' '>Click Here</Link></tr>
                        <tr><td>Wisconsin (WI)</td><Link to="https://tap.revenue.wi.gov/RefundStatus/_/" target=' '>Click Here</Link></tr>
                        <tr><td>Wyoming (WY)</td><Link to="https://revenue.wyo.gov/" target=' '>Click Here</Link></tr>
 
                    </tbody>
                    </table>
        </div>
    </div>
  );
};